import { EnterpriseApi } from './enterprise.api';
import { TitleApi } from './title.api';
import { PositionApi } from './position.api';
import { UnitApi } from './unit.api';
import { GroupApi } from './group.api';
import { CommonCategoryApi } from './common-category.api';
import { AreaApi } from './area.api';
import { OrganizationStructureApi } from './organization-structure.api';
import { CountryApi } from './country.api';
import { ProvinceApi } from './province.api';
import { DistrictApi } from './district.api';
import { WardApi } from './ward.api';
import { BankApi } from './bank.api';
import { OrgChartApi } from './org-chart.api';
import { JobDescriptionApi } from './job-description.api';
import { DependentUnitApi } from './dependent-unit.api';
import { CopyDesjobApi } from './copy-desjob.api';
import { MajorApi } from './major.api';
import { CommonApi } from './common.api';
import { CompetenceGroupApi } from './competence-group.api';
import { CompetenceDictionaryApi } from './competence-dictionary.api';
import { LegalRepresentativeApi } from './LegalRepresentative.api';
import { WorklocationApi } from './worklocation.api';
import { WorkPlaceApi } from './work-place.api';
import { VersionApi } from './version.api';
import { OrgMappingApi } from './org-mapping.api';
import { EmployeeApi } from './employee.api';
import { JobApi } from './job.api';
import { JobDraftApi } from './job-draft.api';

export { EnterpriseApi } from './enterprise.api';
export { TitleApi } from './title.api';
export { PositionApi } from './position.api';
export { UnitApi } from './unit.api';
export { GroupApi } from './group.api';
export { CommonCategoryApi } from './common-category.api';
export { AreaApi } from './area.api';
export { OrganizationStructureApi } from './organization-structure.api';
export { CountryApi } from './country.api';
export { ProvinceApi } from './province.api';
export { DistrictApi } from './district.api';
export { WardApi } from './ward.api';
export { BankApi } from './bank.api';
export { OrgChartApi } from './org-chart.api';
export { JobDescriptionApi } from './job-description.api';
export { DependentUnitApi } from './dependent-unit.api';
export { CopyDesjobApi } from './copy-desjob.api';
export { MajorApi } from './major.api';
export { CommonApi } from './common.api';
export { CompetenceGroupApi } from './competence-group.api';
export { CompetenceDictionaryApi } from './competence-dictionary.api';
export { LegalRepresentativeApi } from './LegalRepresentative.api';
export { WorklocationApi } from './worklocation.api';
export { WorkPlaceApi } from './work-place.api';
export { VersionApi } from './version.api';
export { OrgMappingApi } from './org-mapping.api';
export { EmployeeApi } from './employee.api';
export { JobApi } from './job.api';
export { JobDraftApi } from './job-draft.api';

export const APIS = [
  TitleApi,
  PositionApi,
  EnterpriseApi,
  UnitApi,
  GroupApi,
  CommonCategoryApi,
  AreaApi,
  OrganizationStructureApi,
  CountryApi,
  ProvinceApi,
  DistrictApi,
  WardApi,
  BankApi,
  OrgChartApi,
  JobDescriptionApi,
  DependentUnitApi,
  CopyDesjobApi,
  MajorApi,
  CommonApi,
  CompetenceGroupApi,
  CompetenceDictionaryApi,
  LegalRepresentativeApi,
  WorklocationApi,
  WorkPlaceApi,
  VersionApi,
  OrgMappingApi,
  EmployeeApi,
  JobApi,
  JobDraftApi
];
