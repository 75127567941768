import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';

import { ToastrService } from './toastr.service';
import { SeoService } from './seo.service';
import { StorageService } from './storage.service';
import { DownloadService } from './download.service';
import { ModalService } from './modal.service';
import { DrawerService } from './drawer.service';
import { LoadingService } from './loading.service';
import { OnboardingProfileService } from './onboarding-profile.service';
import { EmployeeService } from './employee.service';
import { OnboardingValidateFormService } from './onboarding-validate-form.service';
import { SelfServiceService, SystemMaintainServiceService, TitleService } from '@services';

@NgModule({
  imports: [CommonModule, MatSnackBarModule, MatDialogModule, HttpClientModule],
  exports: [HttpClientModule],
  declarations: [],
})
export class ServicesModule {
  static forRoot(): ModuleWithProviders<ServicesModule> {
    return {
      ngModule: ServicesModule,
      providers: [
        ToastrService,
        SeoService,
        StorageService,
        DownloadService,
        ModalService,
        DrawerService,
        LoadingService,
        OnboardingProfileService,
        EmployeeService,
        OnboardingValidateFormService,
        TitleService,
        SelfServiceService,
        SystemMaintainServiceService
      ],
    };
  }
}
