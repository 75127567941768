import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { HttpWrapper } from '@cores/http-wrapper';
import { environment } from '@env';

@Injectable({ providedIn: 'root' })
export class CommonCategoryApi extends HttpWrapper {
  constructor(http: HttpClient) {
    super(http, `${environment.base_url_master_data}/common-category`);
  }

  getByParentCode(code: string) {
    return this.http.get(`${this.baseURL}/get-children/${code}`).toPromise();
  }

  getDropListByParentCode(code: string) {
    return this.http.get(`${this.baseURL}/drop-list/${code}`).toPromise();
  }
}
