import { D } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'mc-input-number',
  templateUrl: './mc-input-number.component.html',
  styleUrls: ['./mc-input-number.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => McInputNumberComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => McInputNumberComponent),
      multi: true,
    },
  ],
})
export class McInputNumberComponent implements ControlValueAccessor, Validator {
  constructor(private el: ElementRef<HTMLElement>) {
    this.control.valueChanges.subscribe((value) => {
      if (this.onChange) {
        this.onChange(value);
      }
    });
  }
  absControl: AbstractControl;
  control = new FormControl(null);
  @Input() className: string;
  @Input() label: string;
  @Input() showLabel: boolean = true;
  @Input() placeholder: string = '';
  @Input() formControlName: string;
  @Input() pattern: string;
  @Input() required: boolean | string;
  @Input() mode = 'decimal';
  @Input() prefix: string;
  @Input() suffix: string;
  @Input() currency: string;
  @Input() useGrouping = true;
  @Input() minFractionDigits: number;
  @Input() maxFractionDigits: number;
  @Input() maxlength: number;
  @Input() inputStyleClass: string;
  @Input() styleClass: string;
  @Input() format = true;
  @Input() currencyDisplay: string = 'symbol';
  @Input() min: number;
  @Input() max: number;
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() border: boolean = false;
  @Input() name: string;
  @Input() locale: string = 'en-US';
  @Input() pristine: boolean = false;
  ariaRequired: boolean = false;
  @Output() onFocus = new EventEmitter();
  @Output() onBlur = new EventEmitter();
  @Output() input = new EventEmitter();
  onChange: (_: any) => void;
  onTouched: () => void;

  writeValue(value: string): void {
    this.control.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.absControl = control;
    const value = control.value;

    if (Number.isFinite(value) && Number.isFinite(this.max) && value > this.max) {
      return { max: true };
    }
    if (Number.isFinite(value) && Number.isFinite(this.min) && value < this.min) {
      return { min: true };
    }
    return null;
  }

  setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable({ emitEvent: false });
    }
  }

  get errors() {
    return (
      (this.el.nativeElement.closest('.ng-submitted') ||
        this.absControl?.touched ||
        this.absControl?.dirty ||
        (this.pristine && this.absControl?.pristine)) &&
      this.absControl?.errors
    );
  }

  get checkRequired() {
    return this.absControl?.hasValidator(Validators.required) || this.required;
  }

  eventBlur(event: any) {
    this.onBlur.emit(event);
  }

  checkAriaRequired() {
    if (this.absControl?.hasValidator(Validators.required) || this.required) {
      return true;
    } else {
      return false;
    }
  }

  getErrorMessage(error: any) {
    switch (error.key) {
      case 'required':
        return `'${this.label}' là trường bắt buộc.`;
      case 'max':
        return `'${this.label}' phải nhỏ hơn hoặc bằng ${this.max}.`;
      case 'min':
        return `'${this.label}' phải lớn hơn ${Number.isInteger(this.minFractionDigits) ? '' : 'hoặc bằng'} ${
          Number.isInteger(this.minFractionDigits) ? Math.floor(this.min) : this.min
        }.`;
      case 'pattern':
        return `'${this.label}' sai định dạng.`;
      case 'maxlength':
        return `'${this.label}' không được vượt quá ${error.value.requiredLength} ký tự.`;
      case 'minlength':
        return `'${this.label}' không được ngắn hơn ${error.value.requiredLength} ký tự.`;

      default:
        return null;
    }
  }

  eventFocus(event: any) {
    this.onFocus.emit(event);
  }

  onInputChange(event: any) {
    this.input.emit(event);
  }
  /**
   * chặn nhập chữ ký tự chỉ định từ bàn phím
   *
   * @param event
   * @returns
   */
  onlyNumberKeyPress(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode === D) {
      return false;
    }

    return true;
  }
}
