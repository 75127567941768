import {
  Component,
  forwardRef,
  ChangeDetectorRef,
  Input,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
  Injector,
  OnChanges,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MCreditComponent } from '../mc.component';
import * as _ from 'lodash';
import { MatSelect } from '@angular/material/select';
import Fuse from 'fuse.js';

@Component({
  selector: '[mc-select]',
  templateUrl: './mc-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MCreditSelectComponent),
      multi: true,
    },
  ],
})
export class MCreditSelectComponent extends MCreditComponent<any> implements AfterViewInit, OnChanges {
  @ViewChild(MatSelect) control: MatSelect;

  @Input() name: string;
  @Input() placeholder: string;
  @Input() title: string;
  @Input() color: string;

  @Input('label.search') label_search: string;
  @Input('label.empty') label_empty: string;
  @Input('label.none') label_none: string;

  @Input() search: boolean = false;
  @Input() searching: boolean = false;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() bindValue: string = 'code';
  @Input() bindName: string = 'name';
  @Input() multiple: boolean = false;

  @Input() isServerSideSearch: boolean = false;
  @Input() serverSideSearchAllowNullText: boolean = false;
  @Input() searchApi: any;

  @Output() itemSelected = new EventEmitter();

  private _models: Array<any>;
  @Input()
  get models(): Array<any> {
    return this._models;
  }
  set models(value: Array<any>) {
    this._models = value;
    this.fuse = new Fuse(this.models, {
      keys: ['name'],
    });
  }

  elements: Array<any>;

  private fuse: Fuse<any>;

  selectedValue: any;

  constructor(private ref: ChangeDetectorRef, private injector: Injector) {
    super();
  }

  get errors() {
    return _.get(this.control, 'ngControl.errors');
  }

  ngAfterViewInit() {
    this.elements = this.models;
    this.fuse = new Fuse(this.models, {
      keys: ['name'],
    });
    this.filter();
    this.ref.detectChanges();
  }

  ngOnChanges() {
    this.elements = this.models;
  }

  filter($event: any = undefined) {
    if (_.isEmpty($event)) {
      this.elements = this.models;
      return;
    }
    let results = this.fuse.search($event);

    let filteredItems: Array<any> = [];
    this.elements = results.filter(({ item }) => {
      filteredItems.push(item);
    });

    this.elements = filteredItems;
  }

  public objectComparisonFunction = function (option: any, value: any): boolean {
    return _.isEqual(_.get(option, 'id'), _.get(value, 'id'));
  };

  change($event: any) {
    this.setValue($event);
  }

  indentify(index: any, _: any) {
    return index;
  }

  onSelected(element: any) {
    this.itemSelected.emit(element);
  }
}
