import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA, Sanitizer } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServicesModule } from '@services/services.module';
import { AppComponent, COMPONENTS } from './components';
import { routes } from './app.routing';
import { CommonModule, registerLocaleData } from '@angular/common';
import { NgxSummernoteModule } from '@plugins/ngx-summernote';
import { SharedModule } from '@modules/shared/shared.module';
import vi from '@angular/common/locales/vi';
import { NgxMaskModule } from 'ngx-mask';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { CustomHttpInterceptor } from '@cores/interceptor/custom-http.interceptor';
import { NgDompurifyModule, NgDompurifySanitizer, SANITIZE_STYLE } from '@tinkoff/ng-dompurify';
import { sanitizeStyle } from '@providers/santize-style';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ModuleHttpLoaderFactory } from '@cores/app.init';
import { AccordionModule } from 'primeng/accordion';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { environment } from '@env';

export function initializer(keycloak: KeycloakService): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        await keycloak.init({
          config: {
            url: environment.auth.issuer,
            realm: environment.auth.realm,
            clientId: environment.auth.client,
          },
          loadUserProfileAtStartUp: true,
          initOptions: {
            onLoad: 'login-required',
            checkLoginIframe: false,
            //checkLoginIframeInterval: 5,
          },
          bearerPrefix: 'Bearer',
        });
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  };
}

registerLocaleData(vi);

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule.forRoot(),
    ServicesModule.forRoot(),
    NgxSummernoteModule.forRoot(),
    KeycloakAngularModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled' || 'top',
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'vi',
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: ModuleHttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxMaskModule.forRoot({ showMaskTyped: true }),
    HttpClientModule,
    NgDompurifyModule,
    AccordionModule,
  ],
  exports: [RouterModule],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: CustomHttpInterceptor,
    //   multi: true,
    // },
    {
      provide: Sanitizer,
      useClass: NgDompurifySanitizer,
    },
    {
      provide: SANITIZE_STYLE,
      useValue: sanitizeStyle,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      deps: [KeycloakService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}
