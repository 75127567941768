import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionKey } from '@cores/constants';
import { environment } from '@env';
import { DrawerService, SeoService, LoadingService, SessionService, SystemMaintainServiceService } from '@services';
import _ from 'lodash';
import { catchError, of } from 'rxjs';

@Component({
  selector: 'app-system-mantainenace',
  templateUrl: './system-mantainenace.component.html',
  styleUrls: ['./system-mantainenace.component.scss'],
})
export class SystemMantainenaceComponent implements OnInit {
  fullName: string;
  isCheckBtn: boolean = false;

  constructor(
    private drawer: DrawerService,
    private router: Router,
    private loadingService: LoadingService,
    private sessionService: SessionService,
    private systemMaintainService: SystemMaintainServiceService,
    private http: HttpClient,
    private seo: SeoService
  ) {
    seo.set('Bảo trì hệ thống');
  }

  ngOnInit(): void {
    this.checkListDataError();
    const detail = '{"fullName":"Không có"}';
    let detailUser = JSON.parse(localStorage.getItem(SessionKey.USER_INFO) || detail);
    this.fullName = _.get(detailUser, 'fullName');
    this.goToDashBoard();
  }

  toggle() {
    this.drawer.toggle();
  }

  checkListDataError() {
    this.http
      .get(`${environment.base_url}/employee-mapping/error-page/check-button`, { responseType: 'text' })
      .subscribe({
        next: (res) => {
          if (res == 'true') {
            this.isCheckBtn = true;
          }
        },
      });
  }

  logout() {
    this.loadingService.start();
    this.http
      .post(`${environment.auth.issuer}/logout`, { accessToken: localStorage.getItem(SessionKey.TOKEN) })
      .pipe(catchError(() => of(true)))
      .subscribe(() => {
        localStorage.removeItem(SessionKey.TOKEN);
        localStorage.removeItem(SessionKey.USER_INFO);
        localStorage.removeItem(SessionKey.EXP_TOKEN);
        localStorage.removeItem(SessionKey.CONTRACT_NUMBER);
        localStorage.setItem(SessionKey.CONFIRMED_DECREE, 'CONFIRMED_DECREE');
        localStorage.removeItem(SessionKey.VIEW_ASSESSMENT);
        this.sessionService.clearSession();
        this.loadingService.complete();
        this.router.navigateByUrl('/login');
      });
  }

  goToDashBoard() {
    this.systemMaintainService.getMaintainStatus().subscribe({
      next: (res: string) => {},
    });
  }
  viewErrorList() {
    this.router.navigateByUrl('/error-list');
  }
}
