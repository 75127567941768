<div #elDiv></div>
<div class="p-ng">
  <div [ngClass]="{ 'p-input-filled': !border, field: true, 'p-fluid': true }">
    <label>{{ showLabel ? label : '' }}<span *ngIf="checkRequired" class="p-error">*</span></label>
    <p-multiSelect
      (onChange)="onChangeValue()"
      (onClear)="onClear()"
      (onPanelHide)="onHide()"
      (onPanelShow)="onDropdownShow($event)"
      *ngIf="multiple"
      [appendTo]="appendTag"
      [autoZIndex]="true"
      [baseZIndex]="10000"
      [disabled]="disabled"
      [emptyFilterMessage]="emptyFilterMessage"
      [emptyMessage]="emptyMessage"
      [filter]="filter"
      [formControl]="control"
      [ngClass]="{ 'ng-invalid ng-dirty': errors }"
      [optionDisabled]="optionDisabled"
      [optionLabel]="optionLabel"
      [optionValue]="optionValue"
      [options]="options"
      [placeholder]="placeholder"
      [readonly]="readonly"
      [scrollHeight]="scrollHeight"
      [selectionLimit]="selectionLimit"
      [showClear]="showClear"
      [showHeader]="options.length > 0"
      [styleClass]="styleClass"
      [virtualScroll]="virtualScroll"
      id="{{ idTag }}"
    ></p-multiSelect>
    <p-dropdown
      (onChange)="onChangeValue()"
      (onClear)="onChangeValue()"
      (onHide)="onHide()"
      (onShow)="onDropdownShow($event)"
      (onFilter)="onFilter()"
      *ngIf="!multiple"
      [appendTo]="appendTag"
      [autoDisplayFirst]="false"
      [autoZIndex]="true"
      [baseZIndex]="10000"
      [emptyFilterMessage]="emptyFilterMessage"
      [emptyMessage]="emptyMessage"
      [filter]="options.length === 0 ? false : filter"
      [formControl]="control"
      [ngClass]="{ 'ng-invalid ng-dirty': errors }"
      [optionDisabled]="optionDisabled"
      [optionLabel]="optionLabel"
      [optionValue]="optionValue"
      [options]="options"
      [panelStyleClass]="idTag"
      [placeholder]="placeholder"
      [readonly]="readonly"
      [scrollHeight]="scrollHeight"
      [showClear]="showClear"
      [styleClass]="styleClass"
      [virtualScroll]="virtualScroll"
      [autofocusFilter]="autofocusFilter"
      [autofocus]="autofocus"
      #myDropDown
      id="{{ idTag }}"
    ></p-dropdown>
    <div class="relative b-errors">
      <small *ngIf="errors && (absControl?.errors)!['required']" class="p-error block fadeinup animation-duration-300">
        <span class="block">'{{ label }}' là trường bắt buộc.</span>
      </small>
      <small
        *ngIf="errors && (absControl?.errors)!['maxSelectedIndex']"
        class="p-error block fadeinup animation-duration-300"
      >
        <span class="block"
          >'{{ checkStringNotEmpty(hashLabel) ? hashLabel : label }}' không được chọn quá {{ maxSelectedIndex }} lựa
          chọn.</span
        >
      </small>
    </div>
  </div>
</div>
