<mat-form-field fxFlex [class.mat-form-field-with-icon]="icon">
  <mat-label>{{ title ? title : placeholder }}</mat-label>
  <input
    matInput
    type="{{ type }}"
    [attr.name]="name"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    [minlength]="minlength"
    [maxlength]="maxlength"
    [disabled]="disabled"
    (ngModelChange)="setValue($event)"
    [required]="required"
    [ngModelOptions]="{ updateOn: 'blur' }"
    (blur)="blur()"
    [pattern]="pattern"
    autofocus
  />
  <img *ngIf="icon" src="{{ iconUrl }}" class="icon" alt="" />
  <mat-error *ngIf="errors && errors.required"> '{{ title ? title : placeholder }}' là bắt buộc </mat-error>
  <mat-error *ngIf="errors && errors.minlength">
    '{{ title ? title : placeholder }}' không được ngắn hơn {{ minlength }} ký tự
  </mat-error>
  <mat-error *ngIf="errors && errors.pattern"> '{{ title ? title : placeholder }}' không đúng định dạng </mat-error>
</mat-form-field>
