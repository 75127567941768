import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { ToastrService, ModalService } from '@services';
import { HttpWrapper } from './http-wrapper';
import * as _ from 'lodash';
import { Utils } from '@cores/utils';
import { ScreenType } from './constants';
import { cleanData } from './functions';

import { ConfirmationComponent } from '@modules/shared/dialogs/confirmation/confirmation.component';

export class LPBaseDialogComponent {
  title: string;
  model: any;
  disabled: boolean;
  loading: boolean;
  success: string;
  error: string;
  resolve: any;
  isLoading: boolean = false;
  column: string = 'code';
  preModel: any;
  url: string = '';
  screenType: ScreenType;

  constructor(
    public ref: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public bindings: any,
    public toastr: ToastrService,
    public api: HttpWrapper,
    public modal?: ModalService
  ) {
    this.preModel = _.get(bindings, 'data');
    this.model = _.clone(this.preModel);
    this.title = _.get(bindings, 'title');
    this.disabled = _.get(bindings, 'disabled');
    this.success = _.get(bindings, 'message.success');
    this.error = _.get(bindings, 'message.error');
    this.resolve = _.get(bindings, 'resolve');
    this.column = _.get(bindings, 'column') || 'code';
    this.screenType = _.get(bindings, 'screenType');
  }

  accept(form: NgForm) {
    if (form.invalid) {
      return;
    }
    if (!this.disabled) {
      this.prepareDataForSubmit(this.model);
      if (Utils.isStringEmpty(_.get(this.model, 'id'))) {
        this.save();
        return;
      }
      this.update();
      return;
    }
    this.cancel();
  }

  save() {
    if (this.loading) {
      return;
    }
    if (!this.validate()) {
      return;
    }
    this.loading = true;
    this.isLoading = true;
    cleanData(this.model);
    this.api
      .create(this.url, this.model)
      .then(
        () => {
          this.toastr.success(this.success);
          this.isLoading = false;
          this.ref.close({ status: true });
        },
        async (error: any) => {
          const message = Utils.popError(error) || this.error;
          this.toastr.error(message);
          this.isLoading = false;
        }
      )
      .then(() => {
        this.loading = false;
      });
  }

  update() {
    if (this.loading) {
      return;
    }
    if (!this.validate()) {
      return;
    }
    this.loading = true;
    this.isLoading = true;
    this.api
      .edit(`${_.get(this.model, this.column)}`, this.model)
      .then(
        () => {
          this.toastr.success(this.success);
          this.isLoading = false;
          this.ref.close(true);
        },
        (errorUpdate: any) => {
          const message = Utils.popError(errorUpdate) || this.error;
          this.toastr.error(message);
          this.isLoading = false;
        }
      )
      .then(() => {
        this.loading = false;
      });
  }

  cancel() {
    this.ref.close(false);
  }

  async close(f: NgForm) {
    if (this.modal) {
      if (f.dirty) {
        const dialogRef = await this.modal.show(
          ConfirmationComponent,
          {
            autoFocus: false,
            panelClass: 'mc-dialog',
            data: {
              title: 'Xác nhận',
              content: 'Bạn có chắc chắn muốn hủy không?',
            },
          },
          { width: '400px' }
        );

        dialogRef.subscribe((state: any) => {
          if (state) {
            this.cancel();
          }
        });
      } else {
        this.cancel();
      }
    }
  }

  validate() {
    return true;
  }

  prepareDataForSubmit(model: any) {
    return model;
  }
}
