import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';
import { noop } from 'rxjs';
import { MCreditComponent } from '../mc.component';

@Component({
  selector: 'mc-select-single-tree',
  templateUrl: './mc-select-single-tree.component.html',
  styleUrls: ['./mc-select-single-tree.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => McSelectSingleTreeComponent),
      multi: true,
    },
  ],
})
export class McSelectSingleTreeComponent extends MCreditComponent<Array<any>> implements OnInit, OnChanges {
  @Input() control: any;
  @Input() title: string;
  @Input() model: any;
  @Input() disabled: boolean = false;
  @Input() display: string = 'name';
  @Output() modelChange = new EventEmitter();
  @Input() items: Array<any> = [];
  @Input() placeholder: string = 'Chọn đơn vị';
  @Input() displayValue: string;
  @Input() multiple: boolean;
  @Input() required: boolean = false;
  @Input() selected: any;
  @Input() className: string;
  @Input() name: string = 'select-tree';

  override onChange: (_: any) => void = noop;
  override onTouched: () => void = noop;

  options: Array<any>;
  original_options: Array<any>;
  ngControl: AbstractControl;
  clickInSide: boolean;
  clickOutSide: boolean;

  constructor(private ref: ChangeDetectorRef, private el: ElementRef) {
    super();
  }

  @HostListener('click', ['$event']) onClick(_event: any) {
    this.clickInSide = true;
  }

  @HostListener('document:click', ['$event']) onClickDoc(event: any) {
    if (!this.el.nativeElement.contains(event.target) && this.clickInSide) {
      this.clickOutSide = true;
    }
  }

  ngOnChanges(change: SimpleChanges) {
    if (_.get(change['items'], 'currentValue')) {
      this.convertDataTree();
    }
  }

  ngOnInit(): void {
    this.convertDataTree();
    this.model = this.getNodes(this.original_options, this.selected);
  }

  getNodes(listOptions: any, valueModels: any): any {
    if (valueModels) {
      for (const element of listOptions) {
        if (element.value.startsWith(valueModels)) {
          return element;
        }
        if (element.children) {
          if (this.getNodes(element.children, valueModels)) {
            return this.getNodes(element.children, valueModels);
          }
        }
      }
    }
    return null;
  }

  convertDataTree() {
    let values = _.map(this.items, (item) => {
      return {
        name: _.get(item, this.displayValue),
        value: _.get(item, 'code'),
        children: this.constructTreeData(_.get(item, 'children')),
      };
    });
    this.original_options = values;
    this.options = values;
  }

  constructTreeData(data: Array<any>) {
    return _.map(data, (item: any) => {
      let value: any = {
        name: _.get(item, this.displayValue),
        value: _.get(item, 'code'),
        children: _.size(_.get(item, 'children')) > 0 ? this.constructTreeData(_.get(item, 'children')) : [],
      };
      return value;
    });
  }

  filter(array: any, text: string) {
    text = text.toLowerCase();
    const getNodes = (result: any, object: any) => {
      if (object.name.toLowerCase().startsWith(text)) {
        result.push(object);
        return result;
      }
      if (Array.isArray(object.children)) {
        const children = object.children.reduce(getNodes, []);
        if (children.length) result.push({ ...object, children });
      }
      return result;
    };

    this.options = array.reduce(getNodes, []);
  }

  override registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  override registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  override writeValue(value: any): void {
    this.model = this.getNodes(this.original_options, value);
    this.value = value;
  }

  onSelectionChanged() {
    this.value = this.model?.value;
    this.onChange(this.value);
    this.modelChange.emit(this.model);
  }

  get errors() {
    const el = this.el.nativeElement as HTMLElement;
    return (
      (el.closest('form')?.className?.includes('ng-submitted') || (this.clickOutSide && this.clickInSide)) &&
      this.required &&
      !this.model
    );
  }
}
