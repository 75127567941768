import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SessionKey } from '@cores/constants';
import { Utils } from '@cores/utils';
import { environment } from '@env';
import { RoleAccountApi } from '@modules/main/apis';
import { DashboardService } from '@modules/main/modules/dashboards/services/dashboard.service';
import { LoadingService, SessionService, SystemMaintainServiceService, ToastrService } from '@services';
import moment from 'moment';
import { catchError, forkJoin, of } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { AuthService } from '@services/auth.service';

@Injectable({ providedIn: 'root' })
export class TokenGuard implements CanActivate {
  constructor(
    private router: Router,
    private sessionService: SessionService,
    private roleAccountApi: RoleAccountApi,
    private dashboardService: DashboardService,
    private systemMaintainService: SystemMaintainServiceService,
    private loadingService: LoadingService,
    private toastr: ToastrService,
    private http: HttpClient,
    private keyCloakService: KeycloakService,
    private authService: AuthService,
  ) {}

  canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean> {
    const promise: Promise<boolean> = new Promise((resolve) => {
      // this.authService.saveToken(res.data);
      // localStorage.setItem(SessionKey.CURRENT_USERNAME, this.form.username);
      // const token = localStorage.getItem(SessionKey.TOKEN);
      // const expToken = localStorage.getItem(SessionKey.EXP_TOKEN) || 0;
      // const today = moment().format('YYYY-MM-DD');
      // if (this.keyCloakService.isTokenExpired()) {
      //   this.router.navigateByUrl('/login');
      //   return resolve(false);
      // }
      // if (this.sessionService.getSessionData(SessionKey.USER_INFO)) {
      //   return resolve(true);
      // }
      // let roleAccountError: any = null;
      forkJoin([
        // this.dashboardService.getContractActive(today),
        this.roleAccountApi.getInfo(),
        // this.systemMaintainService.getMaintainStatus(),
      ]).subscribe({
        next: (res: any) => {
          // let data = res[0];
          let response = res[0];
          // let mainStatus = res[2];
          const username = localStorage.getItem(SessionKey.CURRENT_USERNAME);
          // this.setContractNumber(data);
          if (Utils.isNotNull(response)) {
            localStorage.setItem(SessionKey.USER_INFO, JSON.stringify(response));
            this.sessionService.setSessionData(SessionKey.USER_INFO, response);
            // if (mainStatus === 'true') {
            //   this.router.navigateByUrl('/maintained');
            // }
            // if (username !== response.userName) {
            //   this.router.navigateByUrl('/dashboard');
            // }
            resolve(true);
          } else {
            resolve(false);
          }
        },
        error: (error) => {
          // this.logout();
          this.toastr.error(Utils.popError(error));
          resolve(false);
        },
      });
    });
    return promise;
  }

  logout() {
    this.loadingService.start();
    this.http
      .post(`${environment.auth.issuer}/logout`, { accessToken: localStorage.getItem(SessionKey.TOKEN) })
      .pipe(catchError(() => of(true)))
      .subscribe(() => {
        localStorage.removeItem(SessionKey.TOKEN);
        localStorage.removeItem(SessionKey.USER_INFO);
        localStorage.removeItem(SessionKey.EXP_TOKEN);
        localStorage.removeItem(SessionKey.CONTRACT_NUMBER);
        localStorage.setItem(SessionKey.CONFIRMED_DECREE, 'CONFIRMED_DECREE');
        localStorage.removeItem(SessionKey.VIEW_ASSESSMENT);
        localStorage.removeItem(SessionKey.EXP_REFRESH_TOKEN);
        this.sessionService.clearSession();
        this.loadingService.complete();
        this.router.navigateByUrl('/login');
      });
  }

  setContractNumber(data: any) {
    if (data) {
      localStorage.setItem(SessionKey.CONTRACT_NUMBER, data.contractNumber);
      localStorage.setItem(SessionKey.VIEW_ASSESSMENT, data.viewAssessment);
    } else {
      localStorage.setItem(SessionKey.CONTRACT_NUMBER, '');
      localStorage.setItem(SessionKey.VIEW_ASSESSMENT, '');
    }
  }
}
