import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { Calendar } from 'primeng/calendar';
import { noop } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Utils } from '@cores/utils';

const FormatType = {
  dateTime: {
    displayFormat: 'DD/MM/YYYY HH:mm:ss',
    valueFormat: 'YYYY-MM-DD  HH:mm:ss',
    listIndex: [1, 2, 4, 5],
  },
  date: {
    displayFormat: 'DD/MM/YYYY',
    valueFormat: 'YYYY-MM-DD',
    listIndex: [1, 2, 4, 5],
  },
  month: {
    displayFormat: 'MM/YYYY',
    valueFormat: 'YYYY-MM-DD',
    listIndex: [1, 2],
  },
  year: {
    displayFormat: 'YYYY',
    valueFormat: 'YYYY-MM-DD',
  },
};

@Component({
  selector: 'mc-input-calendar',
  templateUrl: './mc-input-calendar.component.html',
  styleUrls: ['./mc-input-calendar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => McInputCalendarComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => McInputCalendarComponent),
      multi: true,
    },
  ],
})
export class McInputCalendarComponent implements ControlValueAccessor, Validator, OnChanges, AfterViewChecked {
  absControl: AbstractControl;
  control = new FormControl(null);
  valueDate: Date[] | Date | null;
  arrDateString: string[] = [];
  displayDateFormat: string = FormatType.date.displayFormat;
  valueDateFormat: string = FormatType.date.valueFormat;
  listIndex: number[] = FormatType.date.listIndex;
  @Input() name: string;
  @Input() className: string;
  @Input() label: string;
  @Input() showLabel: boolean = true;
  @Input() placeholder: string = this.displayDateFormat.toLowerCase();
  @Input() maxDate: Date;
  @Input() minDate: Date;
  @Input() disabledDays: Array<number>;
  @Input() disabledDates: Array<Date>;
  @Input() dateFormat: string = this.displayDateFormat.toLowerCase();
  @Input() readonlyInput: boolean;
  @Input() showTime: boolean;
  @Input() hourFormat: string = '24';
  @Input() selectionMode: string = 'single';
  @Input() border: boolean = false;
  @Input() view: string = 'date';
  @Input() granularity: any = 'millisecond';
  @Input() compareSingerDate: Date;
  @Input() required: boolean = false;
  @Input() timeOnly: boolean = false;
  @Input() pristine: boolean = false;
  @Output() blur = new EventEmitter();
  @Output() inputFocus = new EventEmitter();
  @Output() selectDate = new EventEmitter();
  @ViewChild(Calendar) calendar: Calendar;
  @ViewChild('inputCalendar') inputCalendar: ElementRef;
  @Input() checkPosition: boolean = false;
  onChange: (_: any) => void = noop;
  onTouched: () => void = noop;
  @Input() appendTag = 'body';
  @Input() panelStyleClass = '';
  @Input() isFixed = false;
  @Input() checkRequiredDateRange: boolean = false;
  @Input() checkHoliday: boolean = false;
  isWriteValue = false;

  constructor(private el: ElementRef) {
    this.control.valueChanges.subscribe((value) => {
      if (!this.onChange) {
        return;
      }
      const format = this.displayDateFormat.replace(/\//g, '');
      this.valueDate = null;
      if (this.selectionMode === 'single') {
        this.setSelectionSingle(value, format);
      } else if (this.selectionMode === 'range') {
        this.arrDateString = [];
        if (!value && !this.isWriteValue) {
          this.onChange(null);
          return;
        }
        const arrDateString: string[] = value ? value.split('-') : [' ', ' '];
        const arrDate: Date[] = [];
        arrDateString.forEach((_strDate: string | null) => {
          const newValue = moment(_strDate?.trim()?.replace(/\D+/g, ''), format, true).format(this.valueDateFormat);
          this.arrDateString.push(newValue);
          if (newValue !== 'Invalid date') {
            arrDate.push(moment(newValue, this.valueDateFormat, true).toDate());
          }
        });
        if (arrDate.length > 0) {
          this.valueDate = arrDate;
        }
        this.onChangeDate();
      }
      this.isWriteValue = false;
    });
  }

  setSelectionSingle(value: any, format: any) {
    value = value?.replace(/\D+/g, '');
    value = moment(moment(value, format, true).format(this.valueDateFormat)).isValid()
      ? moment(value, format, true).format(this.valueDateFormat)
      : null;
    this.valueDate = this.setDateSingle(value);
    if (!this.isWriteValue) {
      this.onChange(value);
    }
  }

  onChangeDate() {
    if (!this.isWriteValue) {
      this.onChange(this.arrDateString?.map((value) => (value !== 'Invalid date' ? value : null)));
    }
  }

  private setDateSingle(value: any) {
    if (value !== 'Invalid date' && !_.isEmpty(value)) {
      this.valueDate = moment(value, this.valueDateFormat, true).toDate();
    }
    return this.valueDate;
  }

  get errors() {
    return (
      (this.el.nativeElement.closest('.ng-submitted') ||
        this.absControl?.touched ||
        this.absControl?.dirty ||
        (this.pristine && this.absControl?.pristine)) &&
      this.absControl?.errors
    );
  }

  get checkRequired() {
    return this.absControl?.hasValidator(Validators.required) || this.required;
  }

  ngOnChanges(_changes: SimpleChanges): void {
    if (this.view === 'month') {
      this.dateFormat = FormatType.month.displayFormat.toLowerCase();
      this.placeholder = FormatType.month.displayFormat.toLowerCase();
      this.displayDateFormat = FormatType.month.displayFormat;
      this.valueDateFormat = FormatType.month.valueFormat;
      this.listIndex = FormatType.month.listIndex;
    }
    if (this.view === 'dateTime') {
      this.dateFormat = FormatType.dateTime.displayFormat.toLowerCase();
      this.placeholder = FormatType.dateTime.displayFormat.toLowerCase();
      this.displayDateFormat = FormatType.dateTime.displayFormat;
      this.valueDateFormat = FormatType.dateTime.valueFormat;
      this.listIndex = FormatType.dateTime.listIndex;
    }
    if (this.selectionMode === 'range') {
      this.placeholder = 'Từ ngày - Đến ngày';
    }
    if (this.selectionMode === 'range' && this.view === 'month') {
      this.placeholder = 'Từ tháng - Đến tháng';
    }
    if (this.selectionMode !== 'single' && this.selectionMode !== 'range') {
      this.selectionMode = 'single';
    }
  }

  writeValue(value: any): void {
    this.isWriteValue = true;
    if (this.selectionMode === 'single' && value !== 'Invalid date') {
      if (!_.isEmpty(value)) {
        this.control.setValue(moment(value).format(this.displayDateFormat));
      } else {
        this.control.setValue(null);
      }
    } else if (this.selectionMode === 'range') {
      const __ret = this.writeValueDate(value);
      let strValue = __ret.strValue;
      this.control.setValue(strValue);
    }
  }

  private writeValueDate(value: any) {
    let strValue: string | null;
    if (Utils.isStringNotEmpty(value)) {
      value = value.toString().split(',');
    }
    if (!Array.isArray(value) || _.isEmpty(value)) {
      strValue = null;
    } else {
      strValue = `${value[0] ? moment(value[0]).format(this.displayDateFormat) : ''} - ${
        value[1] ? moment(value[1]).format(this.displayDateFormat) : ''
      }`;
    }
    return { strValue, value };
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable({ emitEvent: false });
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.absControl = control;
    const valueInput = this.control.value;
    const format = this.displayDateFormat.replace(/\//g, '');
    if (!_.isEmpty(valueInput?.replace(/\D+/g, '')) && this.selectionMode === 'single') {
      return this.validateSingleDate(valueInput?.replace(/\D+/g, ''));
    } else if (!_.isEmpty(valueInput) && this.selectionMode === 'range') {
      const arrDateString = valueInput
        .split('-')
        ?.map((value: string) => value.replace(/\D+/g, ''))
        .filter((value: string) => !_.isEmpty(value));
      for (const strValue of arrDateString) {
        const error = _.isEmpty(strValue?.trim()) ? null : this.validateSingleDate(strValue?.trim());
        if (error) {
          return error;
        }
      }
      return this.validateRangeDate(arrDateString, format);
    }
    return null;
  }

  validateSingleDate(value: string) {
    const format = this.displayDateFormat.replace(/\//g, '');
    if (!moment(value, format, true).isValid()) {
      return { pattern: true };
    }
    if (this.maxDate && moment(this.maxDate).isBefore(moment(value, format, true), this.granularity)) {
      return {
        maxDate: {
          actualValue: value,
          maxValue: this.maxDate,
        },
      };
    }
    if (
      this.minDate &&
      moment(value, format, true).isBefore(moment(new Date(this.minDate).setHours(0, 0, 0, 0)), this.granularity)
    ) {
      return {
        minDate: {
          actualValue: value,
          maxValue: this.minDate,
        },
      };
    }

    if (this.compareSingerDate && moment(value, format, true).isBefore(moment(this.compareSingerDate))) {
      return { fromDateThanToDateSinger: true };
    }

    if (this.isDateDisabled(value) && this.checkHoliday) {
      return { checkHoliday: true };
    }

    if (this.isDateDisabled(value)) {
      return { disabledDate: true };
    }

    return null;
  }

  validateRangeDate(arrDateString: any, format: string) {
    if (
      (Utils.isStringEmpty(arrDateString[0]) || Utils.isStringEmpty(arrDateString[1])) &&
      this.checkRequiredDateRange
    ) {
      return { checkRequiredDateRange: true };
    }
    if (arrDateString.length === 2) {
      if (moment(arrDateString[0], format).isAfter(moment(arrDateString[1], format).valueOf())) {
        return { fromDateThanToDate: true };
      }
    }
    return null;
  }

  isDateDisabled(value: any): boolean {
    if (this.disabledDates) {
      const arrDateString = this.disabledDates.map((date) => moment(date).format(this.displayDateFormat));
      return (
        arrDateString.indexOf(
          moment(value, this.displayDateFormat.replace(/\//g, ''), true).format(this.displayDateFormat)
        ) !== -1
      );
    }
    if (this.disabledDays) {
      return (
        this.disabledDays.indexOf(moment(value, this.displayDateFormat.replace(/\//g, ''), true).toDate().getDay()) !==
        -1
      );
    }
    return false;
  }

  getErrorMessage(error: any) {
    switch (error.key) {
      case 'required':
        return `'${this.label ? this.label : ''}' là trường bắt buộc.`;
      case 'pattern':
        return `'${this.label ? this.label : ''}' sai định dạng.`;
      case 'minDate':
        return `'${this.label ? this.label : ''}' không được nhỏ hơn ${moment(this.minDate).format(
          this.displayDateFormat
        )}.`;
      case 'maxDate':
        return `'${this.label ? this.label : ''}' không được vượt quá ${moment(this.maxDate).format(
          this.displayDateFormat
        )}.`;
      case 'disabledDate':
        return `'${this.label ? this.label : ''}' không được chọn chủ nhật.`;

      case 'checkHoliday':
        return `'${this.label ? this.label : ''}' phải là ngày làm việc theo quy định.`;

      case 'fromDateThanToDate':
        return `Từ ngày không được lớn hơn Đến ngày.`;
      case 'fromDateThanToDateSinger':
        return `Ngày kết thúc không được nhỏ hơn Ngày bắt đầu.`;
      case 'checkRequiredDateRange':
        return `'${this.label ? this.label : ''}' là trường bắt buộc.`;
      default:
        return null;
    }
  }

  openCalendar() {
    if (this.control.disabled) {
      return;
    }
    this.calendar.toggle();
  }

  // select date modal
  onSelectDate(date: Date) {
    let formattedValue: string | null = null;
    let formattedValueChange: string | string[] | null = null;

    if (this.selectionMode === 'range') {
      formattedValueChange = [];
      this.checkInvalidDate(date);

      const startDate: Date = (this.valueDate as Date[])[0];
      const endDate: Date = (this.valueDate as Date[])[(<Date[]>this.valueDate).length - 1];

      formattedValue = moment(startDate).format(this.displayDateFormat) + ' - ';
      formattedValueChange.push(moment(startDate).format(this.valueDateFormat));
      if ((this.valueDate as Date[]).length > 1 && endDate) {
        formattedValue += moment(endDate).format(this.displayDateFormat);
        formattedValueChange.push(moment(endDate).format(this.valueDateFormat)!);
      }
    } else if (this.selectionMode === 'single') {
      formattedValue = moment(<Date>this.valueDate).format(this.displayDateFormat);
      formattedValueChange = moment(<Date>this.valueDate).format(this.valueDateFormat);
    }
    this.control.setValue(formattedValue, { emitEvent: false });
    if ((this.valueDate as Date[])[0] && (this.valueDate as Date[])[1]) {
      this.calendar.toggle();
    }
    this.onChange(formattedValueChange);
    this.selectDate.emit(formattedValueChange);
  }

  private checkInvalidDate(date: Date) {
    if (this.arrDateString[0] === 'Invalid date' && this.arrDateString[1] !== 'Invalid date') {
      if ((this.valueDate as Date[])[0].valueOf() >= date.valueOf()) {
        (this.valueDate as Date[]) = (this.valueDate as Date[]).reverse();
        this.arrDateString.unshift(moment(date).format(this.valueDateFormat));
      } else {
        (this.valueDate as Date[]) = [date];
        this.arrDateString = [moment(date).format(this.valueDateFormat), 'Invalid date'];
      }
    } else if (
      (this.arrDateString[0] === 'Invalid date' && this.arrDateString[1] === 'Invalid date') ||
      (this.arrDateString[0] !== 'Invalid date' && this.arrDateString[1] !== 'Invalid date')
    ) {
      this.arrDateString = [];
      this.arrDateString = [moment(date).format(this.valueDateFormat), 'Invalid date'];
    } else if (
      this.arrDateString[0] !== 'Invalid date' &&
      this.arrDateString[1] === 'Invalid date' &&
      !(this.valueDate as Date[])[1]
    ) {
      this.arrDateString = [moment(date).format(this.valueDateFormat), 'Invalid date'];
    } else {
      this.arrDateString[1] = moment(date).format(this.valueDateFormat);
    }
  }

  onInputFocus(event: any) {
    let selectionStart = event.target.selectionStart;
    let value = event.target.value;
    if (this.selectionMode === 'range' && !event.target.value.includes('-')) {
      value = `${value} - `;
      this.control.setValue(value);
      event.target.setSelectionRange(selectionStart, selectionStart);
    }
    this.inputFocus.emit(event);
  }

  onInputBlur(event: any) {
    this.onTouched();
    if (event.target.value === ' - ') {
      this.control.setValue('');
    }
    this.blur.emit(event);
  }

  onInputKeyDown(event: any) {
    if (this.readonlyInput || this.absControl?.disabled) {
      return;
    }

    if (event.shiftKey || event.altKey) {
      return;
    }

    if (event.altKey) {
      event.preventDefault();
    }
    let selectionStart = event.target.selectionStart;
    let selectionEnd = event.target.selectionEnd;
    let newValueStr = null;
    this.processSwitch(event, selectionStart, selectionEnd, newValueStr);
  }

  private processSwitch(event: any, selectionStart: any, selectionEnd: any, newValueStr: any) {
    switch (event.which) {
      case 8:
      case 46:
        event.preventDefault();
        const value: string = event.target.value;
        if (selectionStart === selectionEnd) {
          selectionStart = value.substring(0, selectionStart).endsWith('/') ? selectionStart - 1 : selectionStart;
          newValueStr = `${value.substring(0, selectionStart - 1)}${value.substring(selectionEnd)}`;
        } else {
          newValueStr = `${value.substring(0, selectionStart)}${value.substring(selectionEnd)}`;
        }
        if (this.selectionMode === 'single') {
          newValueStr = this.getValueFormatted(event, newValueStr, false);
        } else {
          const arrString = newValueStr.split('-');
          newValueStr = `${this.getValueFormatted(event, arrString[0], false)} - ${this.getValueFormatted(
            event,
            arrString[1],
            false
          )}`;
        }
        this.control.setValue(newValueStr);
        selectionStart = selectionStart > 1 ? selectionStart - 1 : 0;
        event.target.value = newValueStr;
        event.target.setSelectionRange(selectionStart, selectionStart);
        this.selectDate.emit(newValueStr);
        break;
      default:
        break;
    }
  }

  onInputKeyPress(event: any) {
    if (this.readonlyInput || this.absControl?.disabled) {
      return;
    }

    let code = event.which || event.keyCode;
    let selectionStart = event.target.selectionStart;
    let selectionEnd = event.target.selectionEnd;
    let newValueStr = null;
    const lastValue = event.target.value;

    event.preventDefault();
    if (48 <= code && code <= 57) {
      const value = event.target.value;

      if (this.selectionMode === 'single') {
        newValueStr = `${value.substring(0, selectionStart)}${event.key}${value.substring(selectionEnd)}`;
        newValueStr = this.getValueFormatted(event, newValueStr, true);
        if (this.listIndex.includes(selectionStart)) {
          selectionStart += 2;
        } else {
          selectionStart += 1;
        }
      } else if (this.selectionMode === 'range') {
        const __ret = this.processPart1(value, selectionStart, newValueStr, event, selectionEnd, lastValue);
        selectionStart = __ret.selectionStart;
        newValueStr = __ret.newValueStr;
      }

      this.control.setValue(newValueStr);
      event.target.value = newValueStr;
      event.target.setSelectionRange(selectionStart, selectionStart);
      this.selectDate.emit(newValueStr);
    }
  }

  private processPart1(
    value: any,
    selectionStart: any,
    _newValueStr: any,
    event: any,
    selectionEnd: any,
    lastValue: any
  ) {
    const index = value.indexOf(' - ');
    const arrIndex = _.cloneDeep(this.listIndex);
    if (index > -1) {
      [...this.listIndex].forEach((value) => {
        arrIndex.push(value + index + 3);
      });
    }
    const __ret = this.processDate(selectionStart, index, value, event, selectionEnd);
    selectionStart = __ret.selectionStart;
    let newValueStr = __ret.newValueStr;
    const dateString1: string = lastValue.split(' - ')[0];
    const arrString = newValueStr.split('-');
    if (selectionStart === selectionEnd && selectionStart === index && dateString1.length === 10) {
      newValueStr = lastValue;
    } else {
      newValueStr = `${this.getValueFormatted(event, arrString[0], true)} - ${this.getValueFormatted(
        event,
        arrString[1],
        true
      )}`;
      if (arrIndex.includes(selectionStart)) {
        selectionStart += 2;
      } else {
        selectionStart += 1;
      }
      const indexNew = newValueStr.indexOf(' - ');
      if (selectionStart > indexNew && selectionStart <= indexNew + 1) {
        selectionStart = indexNew;
      }
      if (selectionStart > indexNew + 2 && selectionStart <= indexNew + 3) {
        selectionStart = indexNew + 3;
      }
    }
    return { selectionStart, newValueStr };
  }

  private processDate(selectionStart: any, index: number, value: any, event: any, selectionEnd: any) {
    if (selectionStart > index && selectionStart <= index + 1) {
      selectionStart = index - 1 !== -1 ? index - 1 : 0;
    }
    if (selectionStart >= index + 2 && selectionStart <= index + 3) {
      selectionStart = index + 3;
    }
    let newValueStr = `${value.substring(0, selectionStart)}${event.key}${value.substring(selectionEnd)}`;
    if (newValueStr.indexOf(' - ') === -1) {
      newValueStr = `${newValueStr.substring(0, selectionStart + 1)} - ${newValueStr.substring(selectionStart + 1)}`;
    }
    return { selectionStart, newValueStr };
  }

  getValueFormatted(event: any, value: string, insert: boolean) {
    if (!value) {
      return '';
    }
    const selectionStart = event.target.selectionStart;
    const selectionEnd = event.target.selectionEnd;
    let maxLength: number;
    if (this.view === 'month') {
      maxLength = 7;
    } else {
      maxLength = 10;
    }
    value = value.replace(/\D+/g, '');
    if (insert) {
      if (value.length >= 2) {
        value = `${value.substring(0, 2)}/${value.substring(2)}`;
      }
      if (value.length >= 5 && this.view === 'date') {
        value = `${value.substring(0, 5)}/${value.substring(5)}`;
      }
    } else {
      value = this.processFormatValue(selectionEnd, selectionStart, value);
    }
    value = value.substring(0, maxLength);
    return value;
  }

  private processFormatValue(selectionEnd: any, selectionStart: any, value: string) {
    if (
      (selectionEnd === selectionStart && value.length >= 2) ||
      (selectionEnd !== selectionStart && value.length > 2)
    ) {
      value = `${value.substring(0, 2)}/${value.substring(2)}`;
    }
    if (
      this.view === 'date' &&
      ((selectionEnd === selectionStart && value.length >= 5) || (selectionEnd !== selectionStart && value.length > 5))
    ) {
      value = `${value.substring(0, 5)}/${value.substring(5)}`;
    }
    return value;
  }

  ngAfterViewChecked(): void {
    if (this.calendar && this.checkPosition && this.inputCalendar) {
      const positionTopInput = this.inputCalendar.nativeElement.getBoundingClientRect().top;
      const positionLeftInput = this.inputCalendar.nativeElement.getBoundingClientRect().left;
      if (this.isFixed) {
        this.calendar.el.nativeElement.style.position = 'fixed';
        this.calendar.el.nativeElement.style.left = `${positionLeftInput}px`;
        this.calendar.el.nativeElement.style.width = `380px`;
        this.calendar.el.nativeElement.style.zIndex = 1111111111111;
      } else {
        this.calendar.el.nativeElement.style.top = `${positionTopInput}px`;
      }
    }
  }
}
