import {
  Component,
  forwardRef,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  OnChanges,
  SimpleChanges,
  AfterViewChecked,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { Utils } from '@cores/utils';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'mc-input-tree-select',
  templateUrl: './mc-input-tree-select.component.html',
  styleUrls: ['./mc-input-tree-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => McInputTreeSelectComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => McInputTreeSelectComponent),
      multi: true,
    },
  ],
})
export class McInputTreeSelectComponent implements ControlValueAccessor, Validator, OnChanges, AfterViewChecked {
  @Input() placeholder: string;
  @Input() label: string;
  @Input() className: string;
  @Input() search: boolean = false;
  @Input() disabled: boolean = false;
  @Input() filter: boolean = false;
  @Input() showClear: boolean = false;
  @Input() showLabel: boolean = true;
  @Input() optionValue: string;
  @Input() optionLabel: string;
  @Input() multiple: boolean = false;
  @Input() border: boolean = false;
  @Input() propagateSelectionUp: boolean = true;
  @Input() propagateSelectionDown: boolean = true;
  @Input() options: Array<any>;
  @Input() scrollHeight = '250px';
  @Input() selectionMode: string = 'single';
  @Input() display: string = 'comma';
  @Input() emptyMessage: string = 'Không có kết quả nào được tìm thấy';
  @Input() keyNode: string;
  @Output() itemSelected = new EventEmitter();
  @Input() appendTag: any = 'body';
  @Input() idTag: string = '';
  @Input() checkPosition = false;
  absControl: AbstractControl;
  control = new FormControl(null);
  selected: any;
  nodes: TreeNode[] = [];

  onChange: (_: any) => void;
  onTouched: () => void;

  constructor(private el: ElementRef<HTMLElement>) {}

  ngOnChanges(_changes: SimpleChanges): void {
    if (_changes['options']) {
      this.nodes = this.mapTreeView(this.options);
    }
  }

  mapTreeView(items: any[]) {
    if (items) {
      const list: TreeNode[] = [];
      items.forEach((item) => {
        if (item.level >= 0) {
          list.push({
            label: item[this.optionLabel],
            key: item.code,
            expanded: true,
            data: {
              ...item,
            },
            children: [],
          });
        }
      });
      const root = list.filter((item: any) => item.data.level === 0);
      root.forEach((item: any) => {
        this.getChildrenNode(item, list);
      });
      return root;
    }
    return [];
  }

  getChildrenNode(currentItem: TreeNode, items: TreeNode[]) {
    items.forEach((item) => {
      if (item.data.parentCode === currentItem.data.code) {
        currentItem.children?.push(item);
        this.getChildrenNode(item, items);
      }
    });
    if (currentItem.children?.length === 0) {
      currentItem.expanded = false;
    }
  }

  getNodeByValue(tree: TreeNode[], value: any): any {
    let result = null;
    if (!tree || tree.length === 0) {
      return result;
    }
    for (const item of tree) {
      if (value === item.data[this.optionValue]) {
        return item;
      } else if (item.children) {
        result = this.getNodeByValue(item.children, value);
        if (result) {
          break;
        }
      }
    }
    return result;
  }

  writeValue(value: any): void {
    if (this.selectionMode === 'single') {
      value = this.getNodeByValue(this.nodes, value);
      this.control.setValue(value, { emitEvent: false });
    } else {
      let newValue: TreeNode[] = [];
      const lastValue = value || [];
      lastValue.forEach((value: any) => {
        newValue.push(this.getNodeByValue(this.nodes, value));
      });
      newValue = newValue.filter((item) => item !== null);
      this.control.setValue(newValue, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable({ emitEvent: false });
    }
  }

  get errors() {
    return (
      (this.el.nativeElement.closest('.ng-submitted') || this.absControl?.touched || this.absControl?.dirty) &&
      this.absControl?.errors
    );
  }

  get checkRequired() {
    return this.absControl?.hasValidator(Validators.required);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.absControl = control;
    return null;
  }

  onChangeValue() {
    if (this.selectionMode === 'single') {
      this.onChange(this.control.value?.data[this.optionValue] || null);
      this.itemSelected.emit(this.control.value?.data[this.optionValue] || null);
    } else {
      this.onChange(this.control.value?.map((item: TreeNode) => item?.data[this.optionValue]) || null);

      this.itemSelected.emit(this.control.value?.map((item: TreeNode) => item?.data[this.optionValue]) || null);
    }
  }

  onFilterInput(event: any) {
    this.nodes = [...this.nodes];
  }

  setPositionPanel() {
    if (Utils.isStringNotEmpty(this.idTag)) {
      const el = document.getElementsByClassName(this.idTag)!.item(0) as HTMLElement;
      el.style.display = 'none';
      const timer = setTimeout(() => {
        el.style.position = 'fixed';
        clearTimeout(timer);
      }, 5);
    }
  }

  onDropdownShow() {
    this.setPositionPanel();
  }

  ngAfterViewChecked(): void {
    let panel = document.getElementsByClassName('p-treeselect-panel');
    if (panel.length > 0 && this.checkPosition) {
      let dropDownPanel = panel[0] as HTMLElement;
      dropDownPanel.style.position = 'fixed';
      dropDownPanel.style.display = 'block';
      dropDownPanel.style.visibility = 'unset';
    }
  }
}
