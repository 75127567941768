<form #f="ngForm" autocomplete="off">
  <div mat-dialog-title class="mb-12">
    <h2>{{ title }}</h2>
    <mat-icon mat-dialog-close class="icon-close">close</mat-icon>
  </div>
  <mat-dialog-content class="mat-typography" [formGroup]="form">
    <mat-card-content>
      <div fxLayout="row" fxLayoutAlign="flex-start" class="mb-8">
        <mc-input-tree-select
          fxFlex
          class="col"
          [options]="stateData.chartList || []"
          optionLabel="vietnameseName"
          optionValue="code"
          formControlName="unitCode"
          placeholder="Chọn đơn vị"
          label="Đơn vị"
          [appendTag]="'body'"
          [filter]="true"
          [border]="false"
          keyNode="code"
          (itemSelected)="selectUnit($event)"
        >
        </mc-input-tree-select>
      </div>
      <div fxLayout="row" fxLayoutAlign="flex-start" class="mb-8">
        <mc-input-select
          class="mr-8"
          fxFlex="25"
          [options]="stateData.workingProcessConfig || []"
          formControlName="formatRotation"
          label="Hình thức"
          optionLabel="name"
          optionValue="code"
          [filter]="true"
          placeholder="Chọn hình thức"
          #formatRotation
          [appendTag]="formatRotation"
        ></mc-input-select>
        <mc-input-select
          class="mr-8"
          fxFlex="25"
          [options]="stateData.jobDraft || []"
          formControlName="titleCode"
          label="Chức danh"
          optionLabel="name"
          optionValue="code"
          [filter]="true"
          placeholder="Chọn chức danh"
          #titleCode
          [appendTag]="titleCode"
          (itemSelected)="selectTitle($event)"
        >
        </mc-input-select>
        <mc-input-number
          *ngIf="minimumDeadlineDisplay"
          fxFlex="25"
          class="mr-8"
          placeholder="Nhập thời hạn tối thiểu"
          formControlName="minExpiredDuration"
          label="Thời hạn tối thiểu(tháng)"
          [max]="99"
          [min]="1"
        >
        </mc-input-number>
        <mc-input-calendar
          *ngIf="minimumDeadlineDisplay"
          fxFlex="25"
          class="mr-8"
          placeholder="Nhập ngày hết hạn tối thiểu"
          formControlName="minExpired"
          label="Ngày hết hạn tối thiểu"
        >
        </mc-input-calendar>
      </div>
      <div fxLayout="row" fxLayoutAlign="flex-start" class="mb-8">
        <mc-input-number
          *ngIf="deadlineDisplay"
          fxFlex="12"
          class="mr-8"
          placeholder="Nhập thời hạn tối đa"
          formControlName="maxExpiredDuration"
          label="Thời hạn tối đa"
          [max]="99"
          [min]="1"
        >
        </mc-input-number>
        <mc-input-select
          *ngIf="deadlineDisplay"
          fxFlex="12"
          class="mr-8 noLable"
          placeholder="Chọn đơn vị"
          label="Tháng hoặc năm"
          [showLabel]="false"
          formControlName="maxExpiredTimeType"
          [options]="stateData.maxExpiredTimeTypeDropList || []"
          optionLabel="name"
          optionValue="code"
          #maxExpiredTimeType
          [appendTag]="maxExpiredTimeType"
        >
        </mc-input-select>
        <mc-input-calendar
          *ngIf="deadlineDisplay"
          fxFlex="25"
          class="mr-8"
          placeholder="Nhập ngày hết hạn"
          formControlName="maxExpired"
          label="Ngày hết hạn tối đa"
        >
        </mc-input-calendar>
        <mc-input-number
          *ngIf="challengeTimesDisplay"
          fxFlex="25"
          class="mr-8"
          placeholder="Nhập thời gian thử thách"
          formControlName="deadlineDuration"
          label="Thời gian thử thách(tháng)"
          [max]="99"
          [min]="1"
        >
        </mc-input-number>
        <mc-input-calendar
          *ngIf="challengeTimesDisplay"
          fxFlex="25"
          class="mr-8"
          placeholder="Nhập ngày đến hạn thử thách"
          formControlName="deadlineDate"
          label="Ngày đến hạn thử thách"
        >
        </mc-input-calendar>
      </div>
      <div fxLayout="row" fxLayoutAlign="flex-start" class="mb-8">
        <mc-input-text
          fxFlex
          class="mr-8"
          placeholder="Nhập Cost Center"
          formControlName="costCenter"
          label="Cost Center"
        >
        </mc-input-text>
        <mc-input-text
          fxFlex
          class="mr-8"
          placeholder="Nhập số quyết định"
          formControlName="decisionNumber"
          label="Số quyết định"
        >
        </mc-input-text>
        <mc-input-text
          fxFlex
          class="mr-8"
          placeholder="Nhập người ký"
          formControlName="signedPerson"
          [readonly]="true"
          label="Người ký"
        >
        </mc-input-text>
        <mc-input-calendar
          fxFlex
          class="mr-8"
          placeholder="Nhập ngày ký"
          formControlName="signedDate"
          [maxDate]="draftStartDate"
          label="Ngày ký"
        >
        </mc-input-calendar>
      </div>
      <div fxLayout="row" fxLayoutAlign="flex-start" class="mb-8">
        <mc-input-text
          fxFlex="24"
          class="mr-8"
          placeholder="Nhập mã mô hình tổ chức"
          formControlName="orgCode"
          label="Mã mô hình tổ chức"
        >
        </mc-input-text>
        <mc-input-checkbox
          fxFlex
          class="mr-8 top-checkbox"
          formControlName="selfservice"
          [option]="{ label: 'Hiển thị selfservice', value: false, readonly: true }"
        >
        </mc-input-checkbox>
      </div>
      <div fxLayout="row" fxLayoutAlign="flex-start" class="mb-8">
        <mc-input-text
          fxFlex
          class="mr-8"
          placeholder="Nhập ghi chú"
          formControlName="note"
          label="Ghi chú"
          [maxlength]="200"
        >
        </mc-input-text>
      </div>
    </mat-card-content>
  </mat-dialog-content>
  <mat-dialog-actions fxLayoutAlign="end center" class="mt-12">
    <button mat-flat-button type="button" class="w-120px" (click)="close(f)">Đóng</button>
    <button mat-flat-button color="primary" class="w-200px" *ngIf="!disabled" (click)="save()">Lưu</button>
  </mat-dialog-actions>
</form>
