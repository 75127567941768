<ng-container class="content-message-delete">
  <h3 mat-dialog-title>Thông báo</h3>
  <mat-dialog-content>
    <div fxFlex="100" fxFlex.lt-md="100" fxLayoutAlign="start center">
      <span
        ><em
          [ngClass]="{
            icon: true,
            las: true,
            'la-check': type === 1,
            'la-exclamation-triangle': type === 0,
            'la-exclamation': type === 2
          }"
        ></em
        >{{ content }}</span
      >
    </div>
  </mat-dialog-content>
  <hr />
  <mat-dialog-actions class="text-center mt-16" fxFlex="100">
    <span class="flex-space"></span>
    <button type="submit" mat-raised-button color="primary" (click)="accept()">Đóng</button>
  </mat-dialog-actions>
</ng-container>
