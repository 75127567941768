import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoadingService, ToastrService } from '@services';
import * as _ from 'lodash';
import { EmployeeApi, OrgChartApi } from '@modules/main/modules/organizations/apis';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { TitlesApi } from '@modules/main/apis';
import { Utils } from '@cores/utils';
import { CustomPaginator } from '@modules/main/modules/employees/dialogs/borrow-employee-files-dialog/custom-paginator';
import { ActivatedRoute } from '@angular/router';
import { cleanDataForm } from '@cores/functions';

@Component({
  selector: 'app-employee-search',
  templateUrl: './employee-search.component.html',
  styleUrls: ['./employee-search.component.scss'],
  providers: [
    EmployeeApi,
    TitlesApi,
    {
      provide: MatPaginatorIntl,
      useValue: CustomPaginator(),
    },
  ],
})
export class EmployeeSearchOnbComponent implements OnInit, AfterViewChecked, AfterViewInit {
  @Output() page: EventEmitter<PageEvent>;
  @ViewChild('f') f: NgForm;
  cols: any = [];
  searchParams: any = {};
  dataTable: Array<any> = [];
  pageTotal: number = 0;
  pageSize: number = 10;
  name: any;
  pageIndex: number = 0;
  listTitles: Array<any> = [];
  treeLevels: Array<any> = [];
  treeLevel: any;
  branchesActive: Array<any> = [];
  selection = new SelectionModel<any>(true, []);
  isIndeterminate = false;
  isChecked = false;
  prevDataChecked: Array<any> = [];
  listStatusWork: Array<any> = [];
  multiple: boolean = false;
  dataRange: boolean = true;
  radioEmployee: any;
  isLoaded: any;
  filterForm = new FormGroup({
    codeLike: new FormControl(''),
    fullName: new FormControl(''),
    titleCodes: new FormControl(''),
    unitCodes: new FormControl(''),
  });

  constructor(
    public dialogRef: MatDialogRef<EmployeeSearchOnbComponent>,
    @Inject(MAT_DIALOG_DATA) bindings: any,
    public route: ActivatedRoute,
    private employeeApi: EmployeeApi,
    private loadingService: LoadingService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private titlesApi: TitlesApi,
    private orgApi: OrgChartApi
  ) {
    this.pageTotal = _.get(bindings.data, 'count') || 0;
    this.treeLevel = _.get(bindings, 'treeLevel') || [];
    this.multiple = _.get(bindings, 'multiple');
    this.dataRange = _.get(bindings, 'dataRange');
    this.name = _.get(bindings, 'name');
  }

  ngOnInit(): void {
    this.cols = ['checkbox', 'id', 'code', 'oldCode', 'lastName', 'firstName'];
    this.isLoaded = false;
    Promise.all([this.orgApi.get('level')])
      .then((response) => {
        this.treeLevel = _.get(response[0], 'commons') || [];
        for (let i = 1; i <= _.size(this.treeLevel); i++) {
          this.treeLevels.push({ code: `unit-${i}`, value: i - 1 });
          this.cols.push(`unit-${i}`);
        }
        this.cols.push('titleName', 'startDate');
        this.isLoaded = true;
      })
      .catch((err) => {
        const message = Utils.popError(err);
        this.toastr.error(message);
      });

    this.dataTable.forEach((ele) => (ele.isChecked = false));
    this.cdr.detectChanges();
    this.pageIndex = 0;
    this.pageSize = 10;
    this.search();
  }

  closePopup(data?: any) {
    this.dialogRef.close(data);
  }

  addEmployee() {
    if (this.multiple) {
      let body = _.cloneDeep(this.prevDataChecked);
      // clear properties isChecked
      body.forEach((ele) => delete ele.isChecked);
      this.dialogRef.close(body);
    } else {
      this.dialogRef.close(this.radioEmployee);
    }
  }

  //Use for single select
  changeEmployee(row: any) {
    this.radioEmployee = row;
  }

  search() {
    const params = { ...cleanDataForm(this.filterForm) };
    for (const key in params) {
      if (_.get(params, key) === '') {
        delete params[key];
      }
    }

    this.isLoaded = false;
    this.loadingService.start();
    this.employeeApi
      .fetchData('get-signer', this.pageIndex, this.pageSize, params)
      .then((res: any) => {
        this.loadingService.complete();
        this.pageTotal = res.count || 0;
        this.dataTable = res.models || [];
        this.listTitles = res.titles;
        this.branchesActive = res.units;
        this.dataTable.forEach((ele) => (ele.isChecked = false));
        if (this.prevDataChecked.length > 0 && this.dataTable.length > 0) {
          this.dataTable.forEach((ele) => {
            this.prevDataChecked.forEach((item) => {
              if (item.code === ele.code) {
                ele.isChecked = item.isChecked;
              }
            });
          });
          this.autoCheckAll();
        } else {
          this.isChecked = false;
          this.isIndeterminate = false;
        }
        this.isLoaded = true;
      })
      .catch((e: any) => {
        this.loadingService.complete();
        const message = Utils.popError(e);
        this.toastr.error(message);
        this.isLoaded = true;
      });
  }

  changePage(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.search();
  }

  getUnit(element: any, level: number) {
    let data = _.chain(_.get(element, 'units'))
      .filter((x) => x.level === level)
      .first()
      .value();
    return _.get(data, 'unitName');
  }

  value(model: any, key: string) {
    return _.get(model, key);
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  reload() {
    this.branchesActive = [];
    this.radioEmployee = null;
    this.searchParams = {};
    this.pageIndex = 0;
    this.pageSize = 10;
    this.search();
  }

  /**
   * check one row
   * @param row : current row
   */
  rowSelect(row: any) {
    row.isChecked = !row.isChecked;
    this.dataTable.forEach((ele) => {
      if (ele.code === row.code) {
        ele.isChecked = row.isChecked;
      }
    });
    // store data to prevDataChecked
    if (row.isChecked) {
      // isChecked => store in revDataChecked
      this.prevDataChecked.push(row);
    } else {
      // !isChecked => clear this row in prevDataChecked
      this.prevDataChecked = this.prevDataChecked.filter((ele) => ele.code !== row.code);
    }
    this.autoCheckAll();
  }

  /**
   * check all checkbox
   */
  toggleAllRows() {
    let checkAllTable = this.dataTable.every((ele) => ele.isChecked);
    if (!checkAllTable) {
      this.dataTable.forEach((ele) => (ele.isChecked = true));
      this.isIndeterminate = false;
      this.isChecked = true;
    } else {
      this.dataTable.forEach((ele) => (ele.isChecked = false));
      this.isIndeterminate = true;
      this.isChecked = false;
    }
    this.prevDataChecked = [...this.dataTable];

    // filter all object.isChecked
    this.prevDataChecked = this.prevDataChecked.filter((ele) => ele.isChecked);

    // delete same object in array
    this.prevDataChecked = this.prevDataChecked.filter(
      (obj, index, array) => array.findIndex((t) => t.code == obj.code) === index
    );
  }

  autoCheckAll() {
    let everyIsChecked = this.dataTable.every((ele) => ele.isChecked);
    if (everyIsChecked) {
      this.isIndeterminate = false;
      this.isChecked = true;
    } else {
      this.isIndeterminate = true;
      this.isChecked = false;
    }
  }
}
