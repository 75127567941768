import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '@cores/utils';
import moment from 'moment';
@Pipe({
  name: 'convertDate'
})
export class ConvertDatePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if(Utils.isStringNotEmpty(value) && value){
      return moment(value).format('MM/yyyy')
    }
    return null;
  }

}
