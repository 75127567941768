import { AfterViewInit, Component, ElementRef, forwardRef, Input } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'mc-input-text',
  templateUrl: './mc-input-text.component.html',
  styleUrls: ['./mc-input-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => McInputTextComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => McInputTextComponent),
      multi: true,
    },
  ],
})
export class McInputTextComponent implements ControlValueAccessor, Validator, AfterViewInit {
  constructor(private el: ElementRef<HTMLElement>) {}
  absControl: AbstractControl;
  control = new FormControl(null);

  @Input() label: string;
  @Input() showLabel: boolean = true;
  @Input() placeholder: string = '';
  @Input() pattern: string;
  @Input() required: boolean = false;
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() border: boolean = false;
  @Input() autoFocus: boolean = false;
  @Input() pristine: boolean = false;
  @Input() type = 'text';
  @Input() onTrim: boolean = false;
  @Input() name: string;

  onChange: (_: any) => void;
  onTouched: () => void;

  ngAfterViewInit() {
    this.control.valueChanges.subscribe((value) => {
      if (this.onChange) {
        if (this.onTrim) {
          value = value.trim();
        }
        this.onChange(value);
      }
    });
  }

  writeValue(value: string): void {
    this.control.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable({ emitEvent: false });
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.absControl = control;

    return null;
  }

  get checkRequired() {
    return this.absControl?.hasValidator(Validators.required) || this.required;
  }

  get errors() {
    return (
      (this.el.nativeElement.closest('.ng-submitted') ||
        this.absControl?.touched ||
        this.absControl?.dirty ||
        (this.pristine && this.absControl?.pristine)) &&
      this.absControl?.errors
    );
  }

  getErrorMessage(error: any) {
    switch (error.key) {
      case 'required':
        return `'${this.label}' là trường bắt buộc.`;
      case 'pattern':
        return `'${this.label}' sai định dạng.`;
      case 'maxlength':
        return `'${this.label}' không được vượt quá ${error.value.requiredLength} ký tự.`;
      case 'minlength':
        return `'${this.label}' không được ngắn hơn ${error.value.requiredLength} ký tự.`;
      case 'pattern':
        return `'${this.label}' sai định dạng.`;
      case 'min':
        return `'${this.label}' không được nhỏ hơn ${error.value.min}.`;

      default:
        return null;
    }
  }

  onInputBlur() {
    if (this.onTouched) {
      this.onTouched();
    }
  }
}
