<div
  fxLayout="column"
  [ngClass]="{ 'column-select': true, 'mat-tree-select-invalid': errors, 'disabled-select-tree': disabled }"
>
  <div *ngIf="disabled" class="backdrop-tree"></div>
  <span *ngIf="title" class="input-label">{{ title }} <span *ngIf="required" class="required"></span></span>
  <ngx-mat-tree-select-input-ngmodel
    #selectTree
    (selectionChanged)="onSelectionChanged()"
    [(select)]="model"
    [options]="options"
    [placeholder]="placeholder"
    [multiple]="multiple"
    [canSelectParentNode]="!disabled"
    [required]="required"
    [class]="className"
    autofocus
    [name]="name"
  >
    <input
      class="searchInput"
      [disabled]="disabled"
      #searchInput
      placeholder="Tìm kiếm"
      (keydown)="$event.stopPropagation()"
      (keyup)="filter(original_options, searchInput.value)"
    />
  </ngx-mat-tree-select-input-ngmodel>
  <mat-error class="text-error" *ngIf="errors"> '{{ title }}' là bắt buộc </mat-error>
</div>
