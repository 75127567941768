import {
  Component,
  ViewEncapsulation,
  forwardRef,
  ChangeDetectorRef,
  Input,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MCreditComponent } from '../mc.component';

import * as _ from 'lodash';

@Component({
  selector: '[mc-phone]',
  templateUrl: './mc-phone.component.html',
  styleUrls: ['./mc-phone.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,

      useExisting: forwardRef(() => MCreditPhoneComponent),
      multi: true,
    },
  ],
})
export class MCreditPhoneComponent extends MCreditComponent<string> implements AfterViewInit {
  @ViewChild(MatInput) control: MatInput;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() title: string;

  @Input() disabled: boolean | string;

  @Input() required: boolean | string;
  @Input() pattern: string = '^(0)(3|5|7|8|9)([0-9]{8})$';
  @Input() minlength: number | string;
  @Input() maxlength: number | string;
  @Input() readonly: boolean;
  private _trim: boolean;
  @Input() icon: boolean = false;
  @Input() iconUrl: string = '';
  @Input() type: string = 'text';
  @Input()
  get trim(): boolean | string {
    return this._trim;
  }
  set trim(value: boolean | string) {
    this._trim = value != null && value !== false && `${value}` !== 'false';
  }

  constructor(private ref: ChangeDetectorRef) {
    super();
  }

  get errors() {
    return _.get(this.control, 'ngControl.errors');
  }

  ngAfterViewInit() {
    this.ref.detectChanges();
  }

  blur() {
    this.value = this.normalize(this.value);
  }

  override normalize(value: string) {
    return this._trim ? (value || '').trim() : value;
  }

  override setValue(value: any) {
    this.onChange(this.value);
    if (!_.isEmpty(value) && !value.match(this.pattern)) {
      this.control.ngControl.control?.setErrors({ pattern: true });
      this.control._parentForm?.form?.get(this.name)?.setErrors({ pattern: true });
    } else {
      this.control.ngControl.control?.setErrors(null);
      this.control._parentForm?.form?.get(this.name)?.setErrors(null);
    }
  }
}
