<mat-form-field fxFlex>
  <mat-label *ngIf="title || placeholder">{{ title ? title : placeholder }}</mat-label>
  <input
    [type]="type"
    [attr.name]="name"
    matInput
    [placeholder]="placeholder"
    [(ngModel)]="value"
    [min]="min"
    [max]="max"
    [maxlength]="maxlength"
    [disabled]="disabled"
    [readonly]="readonly"
    (ngModelChange)="setValue($event)"
    thousandSeparator=","
    [allowNegativeNumbers]="negative"
    [dropSpecialCharacters]="true"
    [required]="required"
    (blur)="blur()"
    (input)="setValueInput($event)"
    [class]="className"
    autofocus
  />
  <span *ngIf="unit" matSuffix>{{ unit }}</span>

  <mat-error *ngIf="errors && errors.required"> '{{ title ? title : placeholder }}' là bắt buộc </mat-error>
  <mat-error *ngIf="errors && errors.min"> '{{ title ? title : placeholder }}' phải lớn hơn {{ min - 1 }} </mat-error>
  <mat-error *ngIf="errors && errors.max">
    '{{ title ? title : placeholder }}' phải nhỏ hơn hoặc bằng {{ max }}
  </mat-error>
</mat-form-field>
