import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalService, ToastrService } from '@services';
import * as _ from 'lodash';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls :['./confirmation.component.scss']
})
export class ConfirmationComponent {
  title: string;
  data: any;
  beforeConfirmation: boolean = false;
  conditionsChange: boolean = false;
  contentBefore: any;
  code: any;
  content: any;
  buttonConfirmName: any;
  buttonConfirmClose: any;

  constructor(
    private ref: MatDialogRef<ConfirmationComponent>,
    public toastr: ToastrService,
    public modal: ModalService,
    @Inject(MAT_DIALOG_DATA) bindings: any
  ) {
    this.title = _.get(bindings, 'data.title');
    this.data = _.get(bindings, 'data.data');
    this.content = _.get(bindings, 'data.content');
    this.buttonConfirmName = _.get(bindings, 'data.buttonConfirmName');
    this.buttonConfirmClose = _.get(bindings, 'data.buttonConfirmClose');
  }

  async accept() {
    this.ref.close(true);
  }
  cancel() {
    this.ref.close(false);
  }
}
