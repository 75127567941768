<mat-form-field fxFlex [class.mat-form-field-can-float]="true">
  <mat-label>{{ showLabel ? (title ? title : placeholder) : '' }}</mat-label>
  <input
    matInput
    [matDatepicker]="picker"
    [attr.name]="name"
    #date
    [placeholder]="placeholder"
    [(ngModel)]="value"
    [disabled]="disabled"
    [readonly]="readonly"
    (ngModelChange)="setValue($event)"
    (blur)="onBlur($event)"
    [required]="required"
    class="text-required"
    [min]="minDate"
    [max]="maxDate"
    (dateChange)="handleChangeDate($event)"
    maxlength="10"
    [matDatepickerFilter]="myFilter"
    autofocus
    (keypress)="onlyNumberKeyPress($event)"
  />
  <mat-datepicker-toggle matSuffix [for]="picker" *ngIf="isShowIconDate">
    <mat-icon matDatepickerToggleIcon><em class="las la-calendar" style="color: #0a73f0"></em></mat-icon>
  </mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
  <mat-error *ngIf="errors && errors.matDatepickerMax"
    >'{{ timehandling() }}' không được sau ngày {{ maxDate | date: 'dd/MM/yyyy' }}</mat-error
  >

  <mat-error *ngIf="errors && errors.maxBirthDayMin">
    Yêu cầu '{{ timehandling() }}' phải đủ {{ handleCalculateyear() }} tuổi</mat-error
  >

  <mat-error *ngIf="errors && errors.matDatepickerMin"
    >'{{ timehandling() }}' không được trước ngày {{ minDate | date: 'dd/MM/yyyy' }}</mat-error
  >
  <mat-error *ngIf="errors && errors.required && !errorMatDatePicker">'{{ timehandling() }}' là bắt buộc</mat-error>

  <mat-error *ngIf="errors && (errors.pattern || errorMatDatePicker)"
    >'{{ timehandling() }}' không đúng định dạng</mat-error
  >

  <mat-error *ngIf="errors && errors.errSunday">'{{ timehandling() }} không được là ngày chủ nhật.</mat-error>

  <mat-error *ngIf="errors && errors.sameDay">'{{ timehandling() }}' không được trùng với '{{ fieldSame }}'.</mat-error>

  <mat-error *ngIf="errors && errors.datePickerHoliday">'{{ timehandling() }}' Không cho phép là ngày nghỉ.</mat-error>
</mat-form-field>
