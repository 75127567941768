<div class="filters filters-advance">
  <div class="filter-left" fxFlex>
    <div class="filter-col" fxFlex="100" fxLayout="column" fxLayoutGap="16px"></div>
  </div>
  <div class="filter-right" *ngIf="showActionOutTable">
    <button
      *ngIf="setTable.exportExcel"
      mat-flat-button
      color="secondary"
      matTooltip="Xuất excel"
      (click)="onExportExcel()"
    >
      <em class="las la-file-download"></em> Xuất dữ liệu
    </button>
    <button *ngIf="setTable.create" mat-flat-button color="primary" matTooltip="Thêm mới" (click)="onCreate()">
      <mat-icon aria-label="add">add</mat-icon>
      Thêm mới
    </button>
  </div>
</div>
<div style="overflow-x: scroll">
  <table aria-describedby="" mat-table [dataSource]="dataTable" class="mt-8 w-100">
    <ng-container matColumnDef="index">
      <th scope="col" mat-header-cell *matHeaderCellDef class="table-header-content">STT</th>
      <td mat-cell *matCellDef="let element; let i = index" style="text-align: center">
        {{ i + 1 }}
      </td>
    </ng-container>
    <tr *ngFor="let item of columnsTables">
      <ng-container matColumnDef="{{ item.key }}">
        <th scope="col" mat-header-cell *matHeaderCellDef class="table-header-content">{{ item.title }}</th>

        <td mat-cell *matCellDef="let element; let i = index">
          <div *ngIf="!item.type" [innerHtml]="formatContentHTML(element, i, item)"></div>
          <div *ngIf="item.type === 'checkbox'" class="text-center">
            <mat-checkbox [ngModel]="value(element, item.value)" [disabled]="true"></mat-checkbox>
          </div>
          <div *ngIf="item.type === 'date'">
            {{ value(element, item.value) | date: ' dd/MM/YYYY' }}
          </div>
          <div *ngIf="item.type === 'number'">
            {{ value(element, item.value) | number: '1.0-0' }}
          </div>

          <div *ngIf="item.type === 'link'" class="text-center">
            <a (click)="downloadFile(element)">{{ value(element, item.value) }}</a>
          </div>
        </td>
      </ng-container>
    </tr>
    <ng-container matColumnDef="action">
      <th scope="col" mat-header-cell *matHeaderCellDef class="table-header-content" [hidden]="!showActionTable">
        Thao tác
      </th>
      <td mat-cell *matCellDef="let element; let i = index" [hidden]="!showActionTable">
        <div class="d-flex align-items-center">
          <button *ngIf="value(setTable, 'actions.view')" mat-icon-button (click)="onViewRow(element)" matTooltip="Xem">
            <em class="las la-eye"></em>
          </button>
          <button mat-icon-button size="28" matTooltip="Cập nhật" (click)="onUpdateRow(element)">
            <em class="las la-pen"></em>
          </button>
          <button
            *ngIf="value(setTable, 'actions.delete')"
            mat-icon-button
            size="28"
            matTooltip="Xóa"
            (click)="onDeleteRow(element)"
          >
            <em class="las la-trash-alt"></em>
          </button>
        </div>
      </td>
    </ng-container>
    <tr class="row-nodata" *matNoDataRow>
      <div class="row-nodata-content">
        <em>Không có dữ liệu</em>
      </div>
    </tr>
    <tr mat-header-row *matHeaderRowDef="colsTable; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: colsTable"></tr>
  </table>
</div>

<mat-paginator
  *ngIf="value(setTable, 'paginator')"
  [length]="totalTable"
  [pageSizeOptions]="[10, 20, 50, 100, 500, 1000]"
  showFirstLastButtons
></mat-paginator>
