import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import { SessionKey } from '@cores/constants';
import { AuthModel } from '@cores/models/auth.model';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  login(data: any) {
    return this.http.post(`${environment.auth.issuer}/login`, data);
  }

  requestAccessToken() {
    return this.http.post<any>(`${environment.auth.issuer}/token/refresh`, {
      refreshToken: localStorage.getItem(SessionKey.REFRESH_TOKEN),
    });
  }

  saveToken(auth: AuthModel) {
    localStorage.setItem(SessionKey.TOKEN, auth.access_token);
    localStorage.setItem(SessionKey.REFRESH_TOKEN, auth.refresh_token);
    const tokenExpired = moment().add(auth.expires_in, 'seconds').valueOf();
    const refreshExpired = moment().add(auth.refresh_expires_in, 'seconds').valueOf();
    localStorage.setItem(SessionKey.EXP_TOKEN, tokenExpired?.toString());
    localStorage.setItem(SessionKey.EXP_REFRESH_TOKEN, refreshExpired.toString());
  }

  getToken() {
    return localStorage.getItem(SessionKey.TOKEN)!;
  }

  isAccessTokenExpired() {
    return +localStorage.getItem(SessionKey.EXP_TOKEN)! < new Date().valueOf();
  }

  isRefreshTokenExpired() {
    return +localStorage.getItem(SessionKey.EXP_REFRESH_TOKEN)! < new Date().valueOf();
  }

  private myAccount: any;

  setMyVariable(value: any) {
    this.myAccount = value;
  }

  getMyVariable(): any {
    return this.myAccount;
  }
}
