import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from '@services/toastr.service';
import { saveAs } from 'file-saver';

@Injectable()
export class DownloadService {
  constructor(private client: HttpClient, private toastr: ToastrService) {}
  async download(url: string, name: string, type?: string) {
    this.client
      .get(url, {
        responseType: 'arraybuffer',
        observe: 'response',
      })
      .subscribe({
        next: (response) => {
          const file = new Blob([response.body as BlobPart], { type: type || 'application/octet-stream' });
          saveAs(file, name);
          this.toastr.success(`Tải tệp tin ${name} thành công`);
        },
        error: () => {
          this.toastr.error(`Tải tệp tin ${name} không thành công.`);
        },
      });
  }
}
