import { Injectable, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import get from 'lodash/get';

@Injectable()
export class ModalService {
  constructor(private dialog: MatDialog) {}
  show<T>(component: Type<T>, bindings?: any, conf?: any) {
    const parameters = bindings;
    const instance = this.dialog.open(component, {
      disableClose: true,
      // disableClose: get(conf, 'noclose', true),
      data: parameters,
      width: get(conf, 'width'),
      height: get(conf, 'height'),
      autoFocus: false,
    });
    return instance.afterClosed();
  }
}
