import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SystemMaintainServiceService {

  constructor(private http: HttpClient) {}

  getMaintainStatus(){
    return this.http.get(`${environment.base_url_organization}/organization-chart/check-publish`,{
      responseType: 'text'
    })
  }

  getErrorList(param: any){
    return this.http.get(`${environment.base_url_employee}/employee-mapping/error-page`, { params: param })
  }

  getErrorData(param: any){
    return this.http.get(`${environment.base_url_employee}/employee-mapping/error-page/${param}`)
  }

  fileExportImport(fileName: string): Observable<any> {
    return this.http.get(`${environment.base_url_employee}/employee-mapping/error-page/export`, { responseType: 'arraybuffer' }).pipe(
      map((res) => {
        saveAs(
          new Blob([res], {
            type: 'application/octet-stream',
          }),
          fileName
        );
        return true;
      })
    );
  }

  retry(){
    return this.http.get(`${environment.base_url_employee}/employee-mapping/error-page/retry`);
  }

  restore(){
    return this.http.get(`${environment.base_url_employee}/employee-mapping/restore`);
  }

  updateError(id: string, requestBody: any) {
    return this.http.post(`${environment.base_url_employee}/employee-mapping/error-page/` + id, requestBody);
  }

  getTitleByUnit(unitCode: string) {
    return this.http.get(`${environment.base_url_organization}/titles/organization/find-by-unit/` + unitCode);
  }
  checkProcessManager(fileId: string) {
    return this.http.get(`${environment.base_url_employee}/personnel-record-summary/checkProcessManager?fileId=${fileId}`);
  }
}
