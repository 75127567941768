import {
  Component,
  ViewEncapsulation,
  forwardRef,
  ChangeDetectorRef,
  Input,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MCreditComponent } from '../mc.component';

import * as _ from 'lodash';
import { noop } from 'rxjs';

@Component({
  selector: '[mc-text]',
  templateUrl: './mc-text.component.html',
  styleUrls: ['./mc-text.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,

      useExisting: forwardRef(() => MCreditTextComponent),
      multi: true,
    },
  ],
})
export class MCreditTextComponent extends MCreditComponent<string> implements AfterViewInit {
  @ViewChild(MatInput) control: MatInput;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() title: string;
  @Input() className: string;
  @Input() onTrim: boolean = true;

  @Input() disabled: boolean | string;

  @Input() required: boolean | string;
  @Input() pattern: any;
  @Input() minlength: number | string;
  @Input() maxlength: number | string;
  @Input() readonly: boolean;
  private _trim: boolean;
  @Input() icon: boolean = false;
  @Input() showLabel: boolean = true;
  @Input() iconUrl: string = '';
  @Input() type: string = 'text';
  @Input() moneyType: boolean = false;
  @Input() isDemacialNumber: boolean = false;
  @Input() isYear: boolean = false;
  @Input() isSalary: boolean = false;
  @Input() isAccountNumber: boolean = false;
  @Input() isTaxcode: boolean = false;
  @Output() onKeyPress: EventEmitter<any> = new EventEmitter();
  @Output() onInput: EventEmitter<any> = new EventEmitter();

  override onChange: (_: any) => void = noop;
  override onTouched: () => void = noop;

  get trim(): boolean | string {
    return this._trim;
  }
  set trim(value: boolean | string) {
    this._trim = value != null && value !== false && `${value}` !== 'false';
  }

  constructor(private ref: ChangeDetectorRef) {
    super();
  }

  get errors() {
    return _.get(this.control, 'ngControl.errors');
  }

  ngAfterViewInit() {
    this.ref.detectChanges();
  }

  override setValue(value: any) {
    this.onChange(value);
    this.validateDecimalNumber(value)
    this.validateTaxCode(value);
    this.validateAccountNumber(value);
    this.validateYear(value);
  }

  validateDecimalNumber(value: any){
    if (this.isDemacialNumber) {
      if (!value.match('(^[0-9]{0,2}$)|(^[0-9]{0,2}.[0-9]{0,1}$)') && this.isDemacialNumber) {
        this.control.ngControl.control?.setErrors({ demcimal: true });
        this.control._parentForm?.form?.get(this.name)?.setErrors({ demcimal: true });
      }
    }
  }

  validateAccountNumber(value: any) {
    if (this.isAccountNumber) {
      if (!value.match('(^[A-Za-z0-9]+$)') && this.isAccountNumber) {
        this.control.ngControl.control?.setErrors({ accountNumber: true });
        this.control._parentForm?.form?.get(this.name)?.setErrors({ accountNumber: true });
      } else {
        this.control.ngControl.control?.setErrors(null);
        this.control._parentForm?.form?.get(this.name)?.setErrors(null);
      }
    }
  }

  validateTaxCode(value: any) {
    if (this.isTaxcode) {
      if (!value.match('(^[0-9]{10}$)|(^[0-9]{10}-[0-9]{3}$)') && this.isTaxcode) {
        this.control.ngControl.control?.setErrors({ taxCode: true });
        this.control._parentForm?.form?.get(this.name)?.setErrors({ taxCode: true });
      } else {
        this.control.ngControl.control?.setErrors(null);
        this.control._parentForm?.form?.get(this.name)?.setErrors(null);
      }
    }
  }

  validateYear(value: any) {
    if (this.isYear) {
      if (!value.match('(^[0-9]{0,4}$)') && this.isYear) {
        this.control.ngControl.control?.setErrors({ year: true });
        this.control._parentForm?.form?.get(this.name)?.setErrors({ year: true });
      } else {
        let valueYear = Number.parseInt(value, 10);
        let currentYear = new Date().getFullYear();
        if (valueYear > currentYear) {
          this.control.ngControl.control?.setErrors({ maxYear: true });
          this.control._parentForm?.form?.get(this.name)?.setErrors({ maxYear: true });
        }
      }
    }
  }

  keydown(event: any) {
    if (this.onTrim) {
      this.value = event.target?.value?.trim();
      this.onChange(this.value);
    } else {
      this.onChange(event.target?.value);
    }
  }

  override registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  override registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  blur() {
    this.value = this.normalize(this.value);
    this.control.ngControl.control?.setValue(this.value);
    this.control._parentForm?.form?.get(this.name)?.setValue(this.value);
    if (this.moneyType) {
      let number = parseFloat(this.value);
      if (number == 0) {
        this.control.ngControl.control?.setErrors({ minMoney: true });
        this.control._parentForm?.form?.get(this.name)?.setErrors({ minMoney: true });
      }
    }
    this.onChange(this.value);
  }

  override normalize(value: string) {
    return value?.trim();
  }

  /**
   * chặn nhập chữ ký tự đặc biệt
   *
   * @param event
   * @returns
   */
  onlyNumberKeyPress(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    // chỉ cho nhấn các số từ 0 -> 9 a -> z và A -> Z
    if (
      this.isAccountNumber &&
      !((charCode > 47 && charCode < 58) || (charCode > 64 && charCode < 92) || (charCode > 96 && charCode < 123))
    ) {
      return false;
    }
    if (charCode > 31 && (charCode < 43 || charCode > 57) && (this.isDemacialNumber || this.isSalary)) {
      return false;
    }
    this.onKeyPress.emit(this.value);
    return true;
  }

  onInputUser(event: any) {
    this.onInput.emit(event.target.value);
  }
}
