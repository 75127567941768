<div class="p-ng">
  <div [ngClass]="{ 'p-input-filled': !border, field: true, 'p-fluid': true }">
    <label>{{ showLabel ? label : '' }}<span class="p-error" *ngIf="checkRequired">*</span><br></label>
    <input
      [ngClass]="{ 'error-checked ng-invalid ng-dirty': errors }"
      [placeholder]="placeholder"
      (blur)="onInputBlur()"
      [type]="type"
      pInputText
      [formControl]="control"
      [readonly]="readonly"
    />
    <div class="relative b-errors">
      <ng-container *ngIf="errors">
        <small class="p-error block fadeinup animation-duration-300">
          <span class="block" *ngFor="let error of absControl?.errors | keyvalue">{{ getErrorMessage(error) }}</span>
        </small>
      </ng-container>
    </div>
  </div>
</div>
