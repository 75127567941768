import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { HttpWrapper } from '@cores/http-wrapper';
import { environment } from '@env';

@Injectable({ providedIn: 'root' })
export class OrganizationStructureApi extends HttpWrapper {
  constructor(http: HttpClient) {
    super(http, `${environment.base_url_organization}/organization-structure`);
  }
  getActive() {
    return this.http.get(`${this.baseURL}/active`).toPromise();
  }
}
