import { AfterViewInit, Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LPBaseDialogComponent } from '@cores/lp-base-dialog.component';
import { FileEcm } from '@cores/models/file-ecm.model';
import { Utils } from '@cores/utils';
import { TransferOfPersonnelService } from '@modules/main/modules/organizations/pages/transfer-of-personnel/services/transfer-of-personnel.service';
import { LoadingService, ModalService, SystemMaintainServiceService, ToastrService } from '@services';
import { ErrorList } from '../models/error-list.models';

@Component({
  selector: 'app-update-error',
  templateUrl: './update-error.component.html',
  styleUrls: ['./update-error.component.scss'],
})
export class UpdateErrorComponent extends LPBaseDialogComponent implements AfterViewInit {
  stateData: ErrorList = {
    workingProcessConfig: [],
    title: [],
    status: [],
    position: [],
    units: [],
    chartList: [],
    maxExpiredTimeTypeDropList: [],
    jobDraft: [],
  };
  /* Khởi tạo form */
  form = this.fb.group({
    unitCode: ['M10', Validators.required], //Đơn vị
    formatRotation: [null, Validators.required], //Hình thức
    titleCode: [null, Validators.required], //chức danh
    minExpiredDuration: null, //Thời hạn tối thiểu(tháng)
    minExpired: { value: null, disabled: true }, //Ngày hết hạn tối thiểu
    maxExpiredDuration: null, //Thời hạn tối đa
    maxExpiredTimeType: null, //Đơn vị tháng, năm
    maxExpired: { value: null, disabled: true }, //Ngày hết hạn tối đa
    deadlineDuration: null, //Thời gian thử thách(tháng)
    deadlineDate: { value: null, disabled: true }, //Ngày đến hạn thử thách
    costCenter: [null, Validators.required], //CostCenter
    decisionNumber: null, //Số quyết định
    signedPerson: null, //Người ký
    signedDate: null, //Ngày ký
    files: null, //files
    note: null,
    selfservice: true,
    orgCode: { value: null, disabled: true },
  });
  draftStartDate: Date;
  minimumDeadlineDisplay: boolean = true;
  deadlineDisplay: boolean = true;
  challengeTimesDisplay: boolean = true;
  id = '';

  constructor(
    ref: MatDialogRef<UpdateErrorComponent>,
    @Inject(MAT_DIALOG_DATA) bindings: any,
    toastr: ToastrService,
    private service: TransferOfPersonnelService,
    modal: ModalService,
    private fb: FormBuilder,
    public loadingService: LoadingService,
    private systemMaintainService: SystemMaintainServiceService
  ) {
    super(ref, bindings, toastr, service, modal);
    this.id = bindings.data.id || '';
  }
  ngOnInit(): void {
    this.stateData = this.bindings.stateData;
    this.form.patchValue(this.bindings.res);
    if (this.form.controls['unitCode'].value) {
      const param = { unitCode: this.form.controls['unitCode'].value };
      this.service.getDataTitle(param).subscribe({
        next: (res: any) => {
          this.stateData.jobDraft = res;
        },
      });
    }
  }
  ngAfterViewInit(): void {
    this.changeUnit();
    this.form.controls['formatRotation'].valueChanges.subscribe((value) => {
      this.setDisplayAndRequiedForm(value);
    });
  }

  /* Xét hiển thị form cho ngày tháng */
  setDisplayAndRequiedForm(value: string) {
    let workingProcessConfig: any = null;
    workingProcessConfig = this.stateData.workingProcessConfig?.find((item: any) => item.code === value) || null;
    if (workingProcessConfig) {
      /* Thời hạn tối thiểu */
      this.setDate(workingProcessConfig);
      /* Thời hạn tối đa */
      this.deadlineDisplay = workingProcessConfig.deadlineDisplay ? true : false;
      if (workingProcessConfig.deadlineRequire) {
        this.form.controls['maxExpiredDuration'].setValidators(Validators.required);
        this.form.controls['maxExpired'].setValidators(Validators.required);
        this.form.updateValueAndValidity();
      } else {
        this.form.controls['maxExpiredDuration'].clearAsyncValidators();
        this.form.controls['maxExpired'].clearAsyncValidators();
        this.form.updateValueAndValidity();
      }
      /* Thời gian thử thách */
      this.challengeTimesDisplay = workingProcessConfig.challengeTimesDisplay ? true : false;
      if (workingProcessConfig.challengeTimesRequire) {
        this.form.controls['deadlineDuration'].setValidators(Validators.required);
        this.form.controls['deadlineDate'].setValidators(Validators.required);
        this.form.updateValueAndValidity();
      } else {
        this.form.controls['deadlineDuration'].clearAsyncValidators();
        this.form.controls['deadlineDate'].clearAsyncValidators();
        this.form.updateValueAndValidity();
      }
    } else {
      this.minimumDeadlineDisplay = true;
      this.deadlineDisplay = true;
      this.challengeTimesDisplay = true;
      this.form.controls['minExpiredDuration'].clearAsyncValidators();
      this.form.controls['minExpired'].clearAsyncValidators();
      this.form.controls['maxExpiredDuration'].clearAsyncValidators();
      this.form.controls['maxExpired'].clearAsyncValidators();
      this.form.controls['deadlineDuration'].clearAsyncValidators();
      this.form.controls['deadlineDate'].clearAsyncValidators();
      this.form.updateValueAndValidity();
    }
  }

  setDate(workingProcessConfig: any) {
    this.minimumDeadlineDisplay = workingProcessConfig.minimumDeadlineDisplay ? true : false;
    if (workingProcessConfig.deadlineRequire) {
      this.form.controls['minExpiredDuration'].setValidators(Validators.required);
      this.form.controls['minExpired'].setValidators(Validators.required);
      this.form.updateValueAndValidity();
    } else {
      this.form.controls['minExpiredDuration'].clearAsyncValidators();
      this.form.controls['minExpired'].clearAsyncValidators();
      this.form.updateValueAndValidity();
    }
  }

  /* Tìm costCenter */
  searchCostCenter(titleCode: string, unitCode: string) {
    let costCenter = null;
    if (!costCenter) {
      if (!costCenter) {
        costCenter = this.getCodeCenterByParentCode(unitCode);
      }
    }
    this.form.controls['costCenter'].setValue(costCenter);
  }

  /* Đệ quy tìm cost center nếu vị trí công việc và đơn vị children ko có costCenter */
  getCodeCenterByParentCode(unitCode: string) {
    let unit: any = {};
    let costCenter = null;
    unit = this.stateData.units?.find((item: any) => item.parentCode === unitCode) || null;
    if (unit) {
      if (!costCenter) {
        this.getCodeCenterByParentCode(unit.code);
      }
    }
    return costCenter;
  }

  downloadFile(file: FileEcm) {
    this.service.downloadFile(file).subscribe();
  }

  override save() {
    if (this.loadingService.isLoading) {
      return;
    }
    if (this.form.invalid) {
      return;
    }
    const formData: FormData = new FormData();
    let model = {
      unitCode: this.form.controls['unitCode'].value,
      formatRotation: this.form.controls['formatRotation'].value,
      titleCode: this.form.controls['titleCode'].value,
      minExpiredDuration: this.form.controls['minExpiredDuration'].value,
      minExpired: this.form.controls['minExpired'].value,
      maxExpiredDuration: this.form.controls['maxExpiredDuration'].value,
      maxExpiredTimeType: this.form.controls['maxExpiredTimeType'].value,
      maxExpired: this.form.controls['maxExpired'].value,
      deadlineDuration: this.form.controls['deadlineDuration'].value,
      deadlineDate: this.form.controls['deadlineDate'].value,
      decisionNumber: this.form.controls['decisionNumber'].value,
      signedPerson: this.form.controls['signedPerson'].value,
      signedDate: this.form.controls['signedDate'].value,
      note: this.form.controls['note'].value,
      costCenter: this.form.controls['costCenter'].value,
    };
    formData.append(
      'body',
      new Blob([JSON.stringify(model)], {
        type: 'application/json',
      })
    );
    this.loadingService.start();
    this.systemMaintainService.updateError(this.model.id, model).subscribe({
      next: () => {
        this.toastr.success('Cập nhật thành công !');
        this.loadingService.complete();
        this.ref.close({ status: true });
      },
      error: (e: any) => {
        const message = Utils.popError(e) || this.error;
        this.toastr.error(message);
        this.loadingService.complete();
      },
    });
  }

  selectUnit(event: any) {
    const unitCode = event;
    if (unitCode) {
      this.systemMaintainService.getTitleByUnit(unitCode).subscribe({
        next: (response: any) => {
          this.stateData.jobDraft = response || [];
        },
      });
    } else {
      this.stateData.jobDraft = [];
    }
  }

  selectTitle(event: string) {
    const selectedItem: any = this.stateData.jobDraft?.find((item: any) => item?.code === event);
    this.form.get('costCenter')?.setValue(selectedItem?.costCenter || '');
  }
  costCenter() {
    this.loadingService.start();
    const unitCode = this.form.controls['unitCode'].value;
    const titleCode = this.form.controls['titleCode'].value;
    this.service.getCostCenter(unitCode, titleCode).subscribe({
      next: (res: any) => {
        if (res?.costCenter) {
          this.form.controls['costCenter'].setValue(res?.costCenter);
        }
        this.loadingService.complete();
      },
      error: (e: any) => {
        this.toastr.error(Utils.popError(e));
        this.loadingService.complete();
      },
    });
  }
  changeUnit() {
    this.form.controls['titleCode'].valueChanges.subscribe((value) => {
      if (value) {
        this.costCenter();
      }
    });
    this.form.controls['unitCode'].valueChanges.subscribe((value) => {
      if (value) {
        this.costCenter();
      }
    });
  }
}
