import { AfterContentChecked, ChangeDetectorRef, Component, ElementRef, HostBinding, ViewChild } from '@angular/core';
import { ActivationStart, GuardsCheckStart, NavigationEnd, Router } from '@angular/router';
import { SessionKey } from '@cores/constants';
import { environment } from '@env';
import { RoleAccountApi } from '@modules/main/apis';
import { LoadingService, SeoService, SessionService } from '@services';
import { first } from 'rxjs';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [RoleAccountApi],
})
export class AppComponent implements AfterContentChecked {
  constructor(
    private service: LoadingService,
    private seo: SeoService,
    private sessionService: SessionService,
    private cd: ChangeDetectorRef,
    private router: Router
  ) {
    this.router.events.pipe(first((event: any) => event instanceof GuardsCheckStart)).subscribe((event) => {
      localStorage.setItem(SessionKey.PREVIOUS_URL_BEFORE_LOGIN, event.url);
    });
    this.init().then();
    this.checkStatusRouting();
  }
  @ViewChild('loading') loading: ElementRef;
  @HostBinding('class.loading') appRoot = true;
  isLoaded: boolean = true;

  async init() {
    this.seo.init('Mcredit');

    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    // let firebaseConfig = environment.firebaseConfig;

    // Initialize Firebase
    // if (JSON.stringify(firebaseConfig) !== '{}' && !Object.values(firebaseConfig).every((value) => value == '')) {
    //   const app = initializeApp(firebaseConfig);
    //   const analytics = getAnalytics(app); // NOSONAR
    // }
  }

  checkStatusRouting() {
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationStart && this.router.url !== '/login' && this.router.url !== '/') {
        this.isLoaded = false;
        this.appRoot = false;
        this.sessionService.setSessionData(SessionKey.ROUTER_PENDING, true);
        this.service.isLoading = true;
        this.cd.detectChanges();
      }
      if (event instanceof NavigationEnd) {
        this.sessionService.setSessionData(SessionKey.ROUTER_PENDING, false);
        this.isLoaded = true;
        this.appRoot = true;
        this.service.isLoading = false;
        this.cd.detectChanges();
      }
    });
  }

  ngAfterContentChecked() {
    this.service.listener.subscribe((res: any) => {
      this.isLoaded = res !== 'start';
    });
    this.cd.detectChanges();
  }
}
