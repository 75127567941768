<mat-form-field fxFlex>
  <mat-label>{{ title ? title : placeholder }}</mat-label>
  <input
    type="email"
    [attr.name]="name"
    matInput
    [placeholder]="placeholder"
    [(ngModel)]="value"
    [disabled]="disabled"
    (ngModelChange)="setValue($event)"
    [required]="required"
    (blur)="blur()"
    pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
    email="true"
    autofocus
    maxlength="320"
  />
  <mat-error *ngIf="errors && errors.required"> '{{ placeholder }}' là bắt buộc </mat-error>
  <mat-error *ngIf="errors && (errors.email || errors.pattern)"> '{{ placeholder }}' không đúng định dạng </mat-error>
</mat-form-field>
