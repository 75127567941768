<div class="p-ng input-calender">
  <div [ngClass]="{ 'p-input-filled': !border, field: true, 'p-fluid': true }">
    <label>{{ showLabel ? label : '' }}<span class="p-error" *ngIf="checkRequired">*</span></label>
    <div class="relative">
      <span class="p-input-icon-right">
        <em (click)="openCalendar()" class="cursor-pointer pi pi-calendar"></em>
        <input
          class="calendar-input"
          [ngClass]="{ 'error-checked ng-invalid ng-dirty': errors }"
          (focus)="onInputFocus($event)"
          (blur)="onInputBlur($event)"
          (keydown)="onInputKeyDown($event)"
          (keypress)="onInputKeyPress($event)"
          type="text"
          [placeholder]="placeholder"
          pInputText
          [formControl]="control"
          #inputCalendar
        />
      </span>
      <p-calendar
        class="calendar-hidden"
        [disabledDays]="disabledDays"
        [disabledDates]="disabledDates"
        [maxDate]="maxDate || null"
        [minDate]="minDate || null"
        [showTime]="showTime"
        [hourFormat]="hourFormat"
        [readonlyInput]="readonlyInput"
        [appendTo]="appendTag"
        [baseZIndex]="10000"
        [selectionMode]="selectionMode"
        [view]="view"
        [showOnFocus]="false"
        [(ngModel)]="valueDate"
        [ngModelOptions]="{ standalone: true }"
        (onSelect)="onSelectDate($event)"
        [compareSingerDate]="compareSingerDate"
        [panelStyleClass]="panelStyleClass"
        [timeOnly]="timeOnly"
        #calendar
      ></p-calendar>
    </div>
    <div class="relative b-errors">
      <ng-container *ngIf="errors">
        <small class="p-error block fadeinup animation-duration-300">
          <span class="block" *ngFor="let error of absControl?.errors | keyvalue; let first = first">
            <div *ngIf="first">
              {{ getErrorMessage(error) }}
            </div>
          </span>
        </small>
      </ng-container>
    </div>
  </div>
</div>
