import { Injectable } from '@angular/core';
import { Utils } from '@cores/utils';

@Injectable()
export class StorageService {
  private storage: Storage;
  constructor() {
    this.storage = localStorage;
  }

  resolve(key: string, value?: any): any {
    if (Utils.isNotNull(value)) {
      this.storage.setItem(key, JSON.stringify(value));
    }
    const loc = this.storage.getItem(key);
    return loc ? JSON.parse(loc) : undefined;
  }

  remove(key: string) {
    this.storage.removeItem(key);
  }
}
