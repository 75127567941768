import { HttpErrorResponse } from '@angular/common/http';
import * as _ from 'lodash';
import { FILE_EXTENSION, SessionKey } from './constants';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as CryptoJS from 'crypto-js';

export class Utils {
  static isEmailValid(email: string): boolean {
    const regex = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.+[A-Z]{2,4}/gim; // NOSONAR
    return regex.test(email);
  }

  static isNotNull(obj: any): boolean {
    return !this.isNull(obj);
  }

  static isNull(obj: any): boolean {
    return obj === undefined || obj === null;
  }

  static isFunction(obj: any): boolean {
    return this.isNotNull(obj) && obj instanceof Function;
  }

  static isNotFunction(obj: any): boolean {
    return !this.isFunction(obj);
  }

  static isStringEmpty(obj: string): boolean {
    return this.isNull(obj) || obj === '';
  }

  static isStringNotEmpty(obj: string): boolean {
    return !this.isStringEmpty(obj);
  }

  static isArrayEmpty(obj: any[]): boolean {
    return this.isNull(obj) || obj.length === 0;
  }

  static isArrayNotEmpty(obj: any[]): boolean {
    return !Utils.isArrayEmpty(obj);
  }

  static isHtmlNotEmpty(text: string): boolean {
    return !Utils.isHtmlEmpty(text);
  }

  static isHtmlEmpty(text: string): boolean {
    if (Utils.isNull(text)) {
      return true;
    }
    text = text.replace(/(<([^>]+)>)/gi, ''); // NOSONAR
    text = text.trim();
    return Utils.isStringEmpty(text);
  }

  static number(obj: any, defaultValue?: number) {
    if (Utils.isNull(obj) || Utils.isStringEmpty(obj) || isNaN(obj)) {
      return defaultValue;
    }
    return Number(obj);
  }

  static toSeo(value: string): string {
    if (this.isStringEmpty(value)) {
      return '';
    }
    value = value.toLowerCase();
    value = this.parseToEnglish(value);
    value = value.replace(/[^a-z\d\s\-]/g, ''); // NOSONAR
    value = value.replace(/\s*\-\s*/g, '-'); // NOSONAR
    value = value.replace(/\s+/g, '-'); // NOSONAR
    return value.trim();
  }

  static shorten(text: string, count: number = 300): string {
    if (Utils.isStringNotEmpty(text)) {
      text = text.replace(/(<([^>]+)>)/gi, ''); // NOSONAR
      text = text.replace(/\s+/g, ' ');
      if (text.length > count) {
        text = text.substring(0, count) + '...';
      }
    }
    return text;
  }

  static isMatch(query: string, text: string): boolean {
    const terms = this.parseToEnglish(query)
      .toLowerCase()
      .trim()
      .split(/\s+|\+/g);
    for (const term of terms) {
      const index = text.indexOf(term);
      if (index < 0) {
        return false;
      }
      if (index > 0 && text.charAt(index - 1) !== ' ') {
        return false;
      }
    }
    return true;
  }

  static cleanUpHtml(html: string): string {
    if (Utils.isNull(html)) {
      return '';
    }
    return html.replace(/<\/*span.*?>/gi, '');
  }

  static stripHtmlTags(text: string): string | undefined {
    if (text) {
      return text.replace(/\<.*?\>/gi, ''); // NOSONAR
    }
    return undefined;
  }

  static parseToEnglish(text: string): string {
    if (Utils.isNull(text)) {
      return '';
    }
    let value = text.trim().toLowerCase();
    value = value.replace(/[áàảãạâấầẩẫậăắằẳẵặ]/gi, 'a');
    value = value.replace(/[éèẻẽẹêếềểễệ]/gi, 'e');
    value = value.replace(/[iíìỉĩị]/gi, 'i');
    value = value.replace(/[óòỏõọơớờởỡợôốồổỗộ]/gi, 'o');
    value = value.replace(/[úùủũụưứừửữự]/gi, 'u');
    value = value.replace(/[yýỳỷỹỵ]/gi, 'y');
    value = value.replace(/[đ]/gi, 'd'); // NOSONAR
    return value;
  }

  static parseCode(url: string) {
    if (Utils.isStringEmpty(url)) {
      return ``;
    }
    return url.substring(url.lastIndexOf('-') + 1);
  }

  static list_to_tree(list: Array<any>): Array<any> {
    let map: any = {},
      node,
      roots = [],
      i;
    if (list) {
      for (i = 0; i < list.length; i += 1) {
        map[list[i].code] = i;
        list[i].children = [];
      }
      for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parentCode !== '' && node.parentCode) {
          list[map[node.parentCode]]?.children.push(node);
        } else {
          roots.push(node);
        }
      }
    }
    return roots;
  }

  static popError(response: HttpErrorResponse) {
    if (response.status === 401) {
      return 'Hết phiên đăng nhập, vui lòng thực hiện lại thao tác';
    }
    if (response.status === 400) {
      if (_.head(_.get(response, 'error'))) {
        return _.head(_.get(response, 'error')) || '';
      }
      return _.get(response, 'error.message') || 'Có lỗi xảy ra, vui lòng thử lại sau.';
    }
    if (response.status === 403) {
      return _.get(response, 'error.message') || 'Bạn không có quyền, vui lòng thử lại sau.';
    }
    return 'Có lỗi xảy ra, vui lòng thử lại sau.';
  }

  static demoFormatTable = (data: string, fieldName: any, key: any) => {
    if (fieldName === 'dateQD') {
      return '';
    } else {
      return data[key];
    }
  };

  static getFileExtension(fileName: string) {
    return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
  }

  static buildUnitTree(branch: any) {
    if (this.isArrayNotEmpty(branch)) {
      let company = _.find(branch, (x) => this.isStringEmpty(x.parentCode));
      let datas = _.chain(branch)
        .filter((x) => !_.isEqual(x.code, _.get(company, 'code')))
        .value();
      let trees = _.forEach(datas, (x) => {
        if (_.isEqual(_.get(company, 'code'), _.get(x, 'parentCode'))) {
          _.set(x, 'parentCode', '');
        }
      });
      return this.list_to_tree(trees);
    } else return [];
  }

  static checkImageFile(fileExtension: string) {
    return FILE_EXTENSION.img.includes(fileExtension);
  }

  static checkExcelFile(fileExtension: string) {
    return FILE_EXTENSION.excel.includes(fileExtension);
  }

  static checkFilePdf(fileExtension: string) {
    return FILE_EXTENSION.pdf.includes(fileExtension);
  }

  static setToken(accessToken: string, refreshToken: string, username?: string): any {
    const helper = new JwtHelperService();
    try {
      const jwt = helper.decodeToken(accessToken);
      localStorage.setItem(SessionKey.TOKEN, accessToken);
      if (username) {
        localStorage.setItem(SessionKey.CURRENT_USERNAME, username);
      }
      const count = 13 - jwt.exp.toString().length;
      if (count > 0) {
        jwt.exp = jwt.exp * +`1e${count}`;
      }
      localStorage.setItem(SessionKey.EXP_TOKEN, jwt.exp);
      localStorage.setItem(SessionKey.REFRESH_TOKEN, refreshToken);
      return;
    } catch (e) {
      return null;
    }
  }

  static roundDecimal(number: number, decimalPlaces: number): number {
    const multiplier = 10 ** decimalPlaces;
    const roundedNumber = Math.round(number * multiplier) / multiplier;
    return roundedNumber;
  }

  // Encrypt
  static encrypt(value: string) {
    let ciphertext = CryptoJS.AES.encrypt(value, '1qFdCB9ARs9weh2x').toString();
    return ciphertext;
  }

  // Decrypt
  static decrypt(ciphertext: string) {
    let bytes = CryptoJS.AES.decrypt(ciphertext, '1qFdCB9ARs9weh2x');
    let originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  }
}
