<mat-form-field fxFlex>
  <mat-label *ngIf="title">{{ title }}</mat-label>
  <mat-select
    [attr.name]="name"
    [placeholder]="placeholder"
    [disabled]="disabled"
    required
    [(ngModel)]="value"
    [required]="required"
    (ngModelChange)="change($event)"
    [multiple]="multiple"
    [compareWith]="objectComparisonFunction"
    autofocus
  >
    <mat-option *ngIf="search">
      <ngx-mat-select-search
        ngModel
        (ngModelChangeDebounced)="filter($event)"
        [searching]="searching"
        [placeholderLabel]="label_search"
        [noEntriesFoundLabel]="label_empty"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngIf="label_none | isStringNotEmpty">
      {{ label_none }}
    </mat-option>
    <mat-option
      *ngFor="let element of elements; trackBy: indentify"
      [disabled]="element.isDisabled"
      [value]="element"
      (click)="onSelected(element)"
      >{{ element[bindName] }}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="control && control.errorState"> '{{ placeholder ? placeholder : title }}' là bắt buộc </mat-error>
</mat-form-field>
