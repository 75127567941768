import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { HttpWrapper } from '@cores/http-wrapper';
import { environment } from '@env';
import { Observable } from 'rxjs';
import { removeParamNullOrUndefined } from '@cores/functions';
import { PositionDraftSearch } from '../models/position-draft-list';

@Injectable()
export class JobDraftApi extends HttpWrapper {
  constructor(http: HttpClient) {
    super(http, `${environment.base_url_organization}/job-draft`);
  }

  getData(params: PositionDraftSearch): Observable<any> {
    return this.http.get(`${this.baseURL}`, { params: removeParamNullOrUndefined(params) });
  }

  getById(id: any): Observable<any> {
    return this.http.get(`${this.baseURL}/${id}`);
  }

  changeStatusList(params: Array<number>): Observable<any> {
    return this.http.put(`${this.baseURL}/change-job-status `, params, { responseType: 'text' });
  }
}
