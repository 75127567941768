<ng-container class="content-message-delete">
  <div mat-dialog-title class="mb-12">
    <h3>{{ title }}</h3>
    <mat-icon mat-dialog-close class="icon-close">close</mat-icon>
  </div>
  <mat-dialog-content>
    <div fxFlex="100" fxFlex.lt-md="100" fxLayoutAlign="start center" [innerHTML]="content"></div>
  </mat-dialog-content>
  <hr />
  <mat-dialog-actions class="text-center mt-16" fxFlex="100">
    <span class="flex-space"></span>
    <button type="button" mat-raised-button mat-dialog-close class="clor" (click)="cancel()">
      <span *ngIf="!buttonConfirmClose">Đóng</span>
      <span *ngIf="buttonConfirmClose">{{ buttonConfirmClose }}</span>
    </button>
    <button type="submit" mat-raised-button color="primary" (click)="accept()">
      <span *ngIf="!buttonConfirmName">Đồng ý</span>
      <span *ngIf="buttonConfirmName">{{ buttonConfirmName }}</span>
    </button>
  </mat-dialog-actions>
</ng-container>
