import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpWrapper } from '@cores/http-wrapper';
import { environment } from '@env';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DashboardService extends HttpWrapper {
  constructor(http: HttpClient) {
    super(http, `${environment.base_url_employee}/dash-board-employee`);
  }

  getData(): Observable<any> {
    return this.http.get(this.baseURL);
  }

  getAvatar(): Observable<any> {
    return this.http.get(`${this.baseURL}/avatar`);
  }

  getVancavy(): Observable<any> {
    return this.http.get(`${this.baseURL}/vacancy-employee`);
  }

  getContractExpiration(): Observable<any> {
    return this.http.get(`${this.baseURL}/contract-expiration`);
  }

  getContractAssessment(): Observable<any> {
    return this.http.get(`${this.baseURL}/contract-assessment`);
  }

  getEmployeeInfo(): Observable<any> {
    return this.http.get(`${this.baseURL}/self-service`);
  }

  getMessage(): Observable<any> {
    return this.http.get(`${this.baseURL}/message`);
  }

  getManagerBirthDate(): Observable<any> {
    return this.http.get(`${this.baseURL}/manager/birth-date`);
  }

  getEmployeeBirthDate(): Observable<any> {
    return this.http.get(`${this.baseURL}/birth-date`);
  }

  getDeclare(): Observable<any> {
    return this.http.get(`${this.baseURL}/declare`);
  }

  getBanner(): Observable<any> {
    return this.http.get(`${this.baseURL}/banner`);
  }

  getPresentContract(): Observable<any> {
    return this.http.get(`${this.baseURL}/present-contract`);
  }

  getContact(): Observable<any> {
    return this.http.get(`${this.baseURL}/contact`);
  }

  getContactHRBP(): Observable<any> {
    return this.http.get(`${this.baseURL}/contact-hrbp`);
  }

  getContactSalary(): Observable<any> {
    return this.http.get(`${this.baseURL}/contact-salary`);
  }

  getContactIns(): Observable<any> {
    return this.http.get(`${this.baseURL}/contact-ins`);
  }

  getTotalSelfService(): Observable<any> {
    return this.http.get(`${this.baseURL}/total-self-service`);
  }

  getTotalResign(): Observable<any> {
    return this.http.get(`${this.baseURL}/total-resign`);
  }

  getTotalContract() {
    return this.http.get(`${this.baseURL}/total-contract`);
  }

  getPiePresent(): Observable<any> {
    return this.http.get(`${this.baseURL}/pie-present`);
  }

  getPieOnboarding(): Observable<any> {
    return this.http.get(`${this.baseURL}/pie-onboarding`);
  }

  getPieResign(): Observable<any> {
    return this.http.get(`${this.baseURL}/pie-resign`);
  }

  getListBirthdayMember(): Observable<any> {
    return this.http.get(`${this.baseURL}/show-birth`);
  }

  getContractActive(today: any): Observable<any> {
    const param = {
      atDate: today,
    };
    return this.http.get(`${this.baseURL}/contract-process-active`, {
      params: param,
    });
  }
  getProfileList(): Observable<any> {
    return this.http.post(`${this.baseURL}/check-personnel-record`, {});
  }
}

@Injectable({
  providedIn: 'root',
})
export class TimesheetService extends HttpWrapper {
  constructor(http: HttpClient) {
    super(http, `${environment.base_url_timesheet}`);
  }

  getData(code: any): Observable<any> {
    return this.http.post(`${this.baseURL}/annual-leave`, code);
  }

  getAbsenceApproval(code: any): Observable<any> {
    return this.http.get(`${this.baseURL}/absenceApproval/all?employeeCode=${code}&includeDetail=false`);
  }
}
