import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum ValidateType {
  Checking,
  Valid,
  Invalid,
}

@Injectable({
  providedIn: 'root',
})
export class FormService {
  public objFormValidate = [
    {
      key: 'action',
      value: true,
    },
    {
      key: 'promulgate',
      value: true,
    },
    {
      key: 'initiative',
      value: true,
    },
  ];
  private validateFromStream = new Subject();
  currentFrom = this.validateFromStream.asObservable();

  checkForm() {
    this.objFormValidate.forEach((item) => {
      item.value = false;
    });
    this.validateFromStream.next(ValidateType.Checking);
  }

  updateValidate(key: string, value: boolean) {
    for (const element of this.objFormValidate) {
      if (element.key === key) {
        element.value = value;
        break;
      }
    }
    if (this.objFormValidate.filter((item) => item.value).length === this.objFormValidate.length) {
      this.validateFromStream.next(ValidateType.Valid);
    } else {
      this.validateFromStream.next(ValidateType.Invalid);
    }
  }
}
