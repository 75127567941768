import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '@cores/utils';

@Pipe({
  name: 'isStringEmpty',
  pure: false,
})
export class IsStringEmptyPipe implements PipeTransform {
  transform(value: string) {
    return Utils.isStringEmpty(value);
  }
}

@Pipe({
  name: 'isStringNotEmpty',
  pure: false,
})
export class IsStringNotEmptyPipe implements PipeTransform {
  transform(value: string) {
    return Utils.isStringNotEmpty(value);
  }
}

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit = 25, completeWords = false, ellipsis = '...') {
    if (Utils.isStringEmpty(value)) return '';
    if (value.length < limit) return `${value.substring(0, limit)}`;
    if (completeWords) {
      limit = value.substring(0, limit).lastIndexOf(' ');
    }
    return `${value.substring(0, limit)}${ellipsis}`;
  }
}
