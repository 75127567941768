import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { HttpWrapper } from '@cores/http-wrapper';
import { environment } from '@env';
@Injectable({ providedIn: 'root' })
export class OrgChartApi extends HttpWrapper {
  constructor(http: HttpClient) {
    super(http, `${environment.base_url_organization}/organization-chart`);
  }
  getDroplist(param: any) {
    return this.http.get(`${environment.base_url_employee}/onboarding/config-admin`, { params: param });
  }
}
