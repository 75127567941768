import { MatPaginatorIntl } from '@angular/material/paginator';

export function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = 'Số lượng bản ghi trên trang';
  customPaginatorIntl.firstPageLabel = 'Trang đầu tiên';
  customPaginatorIntl.lastPageLabel = 'Trang cuối cùng';
  customPaginatorIntl.nextPageLabel = 'Trang sau';
  customPaginatorIntl.previousPageLabel = 'Trang trước';

  return customPaginatorIntl;
}
