/* eslint-disable @angular-eslint/no-output-on-prefix */
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { CustomPaginator } from './custom-paginator';

export interface ColumnsTable {
  key: string;
  title: string;
  value: string;
  type?: 'date' | 'number' | 'checkbox' | 'link';
}

export interface ActionTable {
  update?: boolean;
  view?: boolean;
  delete?: boolean;
  changeStatus?: boolean;
}

export interface SettingTable {
  columnsTable: Array<ColumnsTable>;
  index: boolean;
  formatColumns?: Function;
  exportExcel?: boolean;
  create?: boolean;
  actions?: ActionTable;
  paginator?: boolean;
}

@Component({
  selector: 'app-base-table',
  templateUrl: './base-table.component.html',
  styleUrls: ['./base-table.component.scss'],
  providers: [{ provide: MatPaginatorIntl, useValue: CustomPaginator() }],
})
export class BaseTableComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginatorTable: MatPaginator;
  @Input() dataTable: MatTableDataSource<any>;
  @Input() totalTable: number = 0;
  @Input() setTable: SettingTable;
  @Output() onCreateRow = new EventEmitter<any>();
  @Output() onExportExcelTable = new EventEmitter<any>();
  @Output() onViewElement = new EventEmitter<any>();
  @Output() onUpdateElement = new EventEmitter<any>();
  @Output() onDeleteElement = new EventEmitter<any>();
  @Output() changePaginator = new EventEmitter<any>();
  @Output() fileScan = new EventEmitter();
  @Input() showActionTable: boolean = true;
  @Input() showActionOutTable: boolean = true;
  columnsTables: Array<any> = [];
  colsTable: Array<string> = [];

  ngOnInit() {
    this.columnsTables = _.get(this.setTable, 'columnsTable') || [];
    if (_.get(this.setTable, 'index')) {
      this.colsTable.push('index');
    }
    if (_.get(this.setTable, 'actions')) {
      this.colsTable.push('action');
    }
    _.map(this.columnsTables, (i) => this.colsTable.push(i.key));
  }

  value(model: any, key: string) {
    return _.get(model, key);
  }

  onExportExcel() {
    this.onExportExcelTable.emit();
  }

  onCreate() {
    this.onCreateRow.emit();
  }

  onViewRow(element: any) {
    this.onViewElement.emit(element);
  }
  onUpdateRow(element: any) {
    this.onUpdateElement.emit(element);
  }
  onDeleteRow(element: any) {
    this.onDeleteElement.emit(element);
  }

  formatContentHTML(element: any, _index: number, type: ColumnsTable): string {
    let column = _.get(type, 'key');
    let key = _.get(type, 'value');
    if (this.setTable.formatColumns) {
      return this.setTable.formatColumns(element, column, key);
    } else {
      return _.get(element, key) || '';
    }
  }

  ngAfterViewInit() {
    this.paginatorTable?.page.subscribe((page: PageEvent) => {
      this.changePaginator.emit({ pageSize: _.get(page, 'pageSize'), pageIndex: _.get(page, 'pageIndex') });
    });
  }

  downloadFile(event: any) {
    this.fileScan.emit(event);
  }
}
