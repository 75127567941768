import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { Utils } from '@cores/utils';
import * as _ from 'lodash';
import { Dropdown } from 'primeng/dropdown';
import { MultiSelect } from 'primeng/multiselect';

@Component({
  selector: 'mc-input-select',
  templateUrl: './mc-input-select.component.html',
  styleUrls: ['./mc-input-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => McInputSelectComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => McInputSelectComponent),
      multi: true,
    },
  ],
})
export class McInputSelectComponent implements ControlValueAccessor, Validator, AfterViewInit {
  @Input() placeholder: string = '';
  @Input() label: string;
  @Input() className: string;
  @Input() search: boolean = false;
  @Input() disabled: boolean;
  @Input() showLabel: boolean = true;
  @Input() optionValue: string;
  @Input() optionLabel: string;
  @Input() optionDisabled: string = 'disabled';
  @Input() multiple: boolean = false;
  @Input() options: Array<any> = [];
  @Input() readonly: boolean = false;
  @Input() border: boolean = false;
  @Input() scrollHeight: string = '200px';
  @Input() styleClass: string;
  @Input() filter: boolean = false;
  @Input() showClear: boolean = true;
  @Input() emptyMessage: string = 'Không có dữ liệu';
  @Input() emptyFilterMessage: string = 'Không có kết quả nào được tìm thấy';
  @Input() selectionLimit: any = null; // gia tri la number hoac null
  @Input() virtualScroll: boolean = false;
  @Input() autoFocus: boolean = false;
  @Input() appendTag: any = 'body';
  @Input() pristine: boolean = false;
  @Input() idTag: string = '';
  @Input() reRenderDropDown: boolean = false;
  @Input() required: boolean = false;
  @Input() autofocus: boolean = true;
  @Input() autofocusFilter: boolean = true;
  @Input() maxSelectedIndex: number = 0;
  @Input() hashLabel: string = '';
  @Input() name: string = '';

  @Output() itemSelected = new EventEmitter();

  @ViewChild(Dropdown) dropdown: Dropdown;
  @ViewChild(MultiSelect) multiselect: MultiSelect;
  @ViewChild('myDropDown') myDropDown: Dropdown;

  absControl: AbstractControl;
  control = new FormControl(null);
  isShowDropdown: boolean = false;

  onChange: (_: any) => void;
  onTouched: () => void;

  constructor(private el: ElementRef<HTMLElement>) {}

  get errors() {
    if (this.pristine) {
      return (
        (this.el.nativeElement.closest('.ng-submitted') ||
          this.absControl?.touched ||
          this.absControl?.dirty ||
          (this.pristine && this.absControl?.pristine)) &&
        this.absControl?.errors
      );
    }
    return (
      (this.el.nativeElement.closest('.ng-submitted') || this.absControl?.touched || this.absControl?.dirty) &&
      this.absControl?.errors
    );
  }

  get checkRequired() {
    return this.absControl?.hasValidator(Validators.required) || this.required;
  }

  ngAfterViewInit(): void {
    if (this.autoFocus && !this.multiple) {
      this.dropdown.applyFocus();
    }
    if (this.autoFocus && this.multiple) {
      this.multiselect.containerViewChild.nativeElement.click();
      this.multiselect.hide();
    }
  }

  onChangeValue() {
    this.isShowDropdown = false;
    this.onChange(this.control.value);
    this.itemSelected.emit(this.control.value);
  }

  onClear() {
    this.control.setValue(null);
    this.isShowDropdown = false;
    this.onChange(this.control.value);
  }

  writeValue(value: any): void {
    value = _.isUndefined(value) ? null : value;
    if (value !== this.control.value) {
      this.control.setValue(value, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable({ emitEvent: false });
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.absControl = control;
    let value = control.value;
    if (this.multiple && this.maxSelectedIndex > 0 && _.isArray(value) && value.length > this.maxSelectedIndex) {
      return {
        maxSelectedIndex: true,
        maxItem: this.maxSelectedIndex,
      };
    }
    return null;
  }

  onDropdownShow(event: any) {}

  onHide() {
    this.isShowDropdown = false;
    this.myDropDown?.resetFilter();
    this.onTouched();
  }

  onFilter() {}

  checkStringNotEmpty(string: string) {
    return Utils.isStringNotEmpty(string);
  }
}
