import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { convertFileToFileEcmDto } from '@cores/functions';
import { FileEcm } from '@cores/models/file-ecm.model';
import { Utils } from '@cores/utils';
import { environment } from '@env';
import { DownloadService, LoadingService, ToastrService } from '@services';
import { FileUpload } from 'primeng/fileupload';

const units = {
  kb: 1024,
  mb: 1024 * 1024,
  gb: 1024 * 1024 * 1024,
};

@Component({
  selector: 'mc-fileUpload',
  templateUrl: './mc-file-upload.component.html',
  styleUrls: ['./mc-file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => McFileUploadComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => McFileUploadComponent),
      multi: true,
    },
  ],
})
export class McFileUploadComponent implements ControlValueAccessor, Validator, OnChanges {
  constructor(
    private el: ElementRef<HTMLElement>,
    private toastr: ToastrService,
    private loadingService: LoadingService,
    private download: DownloadService
  ) {}
  absControl: AbstractControl;
  files: FileEcm[] = [];
  typesFile: string;
  note: any;

  @ViewChild(FileUpload, { static: true }) fileUpload: FileUpload;
  @Input() label: string;
  @Input() showLabel: boolean = true;
  @Input() required: boolean | string;
  @Input() disabled: boolean = false;
  @Input() multiple: boolean = false;
  @Input() accept: string = '.xlsx,.xls';
  @Input() name: string;
  @Input() mode: string = 'basic';
  @Input() maxFileSize: string;
  @Input() maxTotalFileSize: string;
  @Input() maxFile: number;
  @Input() downLoadFile: string;
  @Input() hiddenDelete: boolean = false;
  @Input() typeS3: boolean = false;
  @Input() chooseLabel: string = 'Chọn file tải lên';
  @Input() chooseIcon: string = 'las la-file-download';
  @Output() deleteFiles = new EventEmitter();
  @Output() uploadFiles = new EventEmitter();
  onChange: (_: any) => void;
  onTouched: () => void;

  ngOnChanges(changes: SimpleChanges) {
    this.typesFile = this.accept?.replace(/\./g, '').toUpperCase();
    if (this.maxTotalFileSize && this.typesFile) {
      this.note = `<span class="required"></span>File tải lên không quá <strong> ${this.maxTotalFileSize}</strong
      >, có định dạng <strong> ${this.typesFile}</strong
      >.`;
    } else if (this.maxTotalFileSize) {
      this.note = `<span class="required"></span>File tải lên không quá <strong> ${this.maxTotalFileSize}</strong
      >.`;
    } else if (this.typesFile) {
      this.note = `<span class="required"></span>File tải có định dạng <strong> ${this.typesFile}</strong
      >.`;
    }
  }

  writeValue(value: any): void {
    if (Array.isArray(value)) {
      this.files = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.absControl = control;
    if (this.files.length > 0) {
      if (this.accept && this.files.find((file) => !this.checkFileAccept(file))) {
        return { acceptFile: true };
      }
      const maxFileSize = this.getMaxFileSize();
      if (maxFileSize && this.files.find((file) => file.fileSize > maxFileSize)) {
        return { maxFileSize: true };
      }
      if (
        this.getTotalSize() &&
        this.files.reduce((previousValue, currentValue) => previousValue + currentValue.fileSize, 0) >
          this.getTotalSize()!
      ) {
        return { maxTotalSize: true };
      }
      if (Number.isFinite(this.maxFile) && this.files.length > this.maxFile) {
        return { maxFile: true };
      }
    }
    return null;
  }

  getTotalSize(): number | null {
    const unit = this.maxTotalFileSize?.replace(/\d/g, '').toLowerCase();
    let unitValue = 0;
    if (unit) {
      switch (unit) {
        case 'kb':
          unitValue = units.kb;
          break;
        case 'mb':
          unitValue = units.mb;
          break;
        case 'gb':
          unitValue = units.gb;
          break;
        default:
          break;
      }
      const count = +this.maxTotalFileSize?.replace(/\D+/g, '');
      return count * unitValue;
    }
    return null;
  }

  getMaxFileSize(): number | null {
    const unit = this.maxFileSize?.replace(/\d/g, '').toLowerCase();
    let unitValue = 0;
    if (unit) {
      switch (unit) {
        case 'kb':
          unitValue = units.kb;
          break;
        case 'mb':
          unitValue = units.mb;
          break;
        case 'gb':
          unitValue = units.gb;
          break;
        default:
          break;
      }
      const count = +this.maxFileSize?.replace(/\D+/g, '');
      return count * unitValue;
    }
    return null;
  }

  checkFileAccept(file: FileEcm) {
    const type = file.fileName?.substring(file.fileName.lastIndexOf('.')).toLowerCase();
    return this.accept.toLowerCase().includes(type);
  }

  get checkRequired() {
    return this.absControl?.hasValidator(Validators.required);
  }

  get errors() {
    return (
      (this.el.nativeElement.closest('.ng-submitted') || this.absControl?.touched || this.absControl?.dirty) &&
      this.absControl?.errors
    );
  }

  getErrorMessage(error: any) {
    switch (error.key) {
      case 'required':
        return `'${this.label}' là trường bắt buộc.`;
      case 'acceptFile':
        return `File tải lên không đúng định dạng.`;
      case 'maxTotalSize':
        return `Tổng dung lượng File không được vượt quá ${this.maxTotalFileSize}.`;
      case 'maxFileSize':
        return `File tải lên tối đa không quá ${this.maxFileSize}.`;
      case 'maxFile':
        return `Số File tối đa không quá ${this.maxFile} File.`;
      default:
        return null;
    }
  }

  clearFile() {
    this.fileUpload.clear();
  }

  uploadHandler(event: any) {
    this.chooseLabel = 'Chọn file tải lên';
    for (let eventFile of event.currentFiles) {
      const fileSelected: File = eventFile.currentFiles;
      if (
        !this.files.find(
          (file: FileEcm) =>
            file.fileSize === fileSelected.size &&
            file.lastModified === fileSelected.lastModified &&
            file.minType === fileSelected.type
        )
      ) {
        convertFileToFileEcmDto(fileSelected).subscribe((file) => {
          this.files.push(file);
          this.disabled = this.files.length >= this.maxFile;
          this.onChange(this.files);
        });
      }
    }
    this.fileUpload.clear();
  }

  onFileSelected(event: any, fileInput: any) {
    const files = [...event.target.files];
    let invalidAccept = false;
    fileInput.value = null;
    this.uploadFiles.emit(files);
    for (let file of files) {
      const fileSelected: File = file;
      if (
        !this.files.find(
          (file: FileEcm) =>
            file.fileSize === fileSelected.size &&
            file.lastModified === fileSelected.lastModified &&
            file.minType === fileSelected.type
        )
      ) {
        convertFileToFileEcmDto(fileSelected).subscribe((file) => {
          if (!this.checkFileAccept(file) && !invalidAccept) {
            this.toastr.error('File tải lên không đúng định dạng');
            invalidAccept = true;
          } else {
            this.files.push(file);
            this.disabled = this.files.length >= this.maxFile;
            this.onChange(this.files);
          }
        });
      }
    }
  }

  deleteFile(index: number) {
    this.deleteFiles.emit(this.files[index]);
    this.files.splice(index, 1);
    this.disabled = this.files.length >= this.maxFile;
    if (this.onChange) {
      this.onChange(this.files);
    }
  }

  getFileName(file: FileEcm) {
    if (file.prop2) {
      return Utils.shorten(file.prop2, 100);
    }
    return Utils.shorten(file.fileName, 100);
  }

  downloadFile(file: FileEcm) {
    if (this.typeS3) {
      this.download.download(`${environment.base_url}/s3/aws/download?fileId=${file.ecmId}`, file.fileName);
    } else {
      this.download.download(
        `${environment.base_url}/e-file-cabinet/ecm-document/download/${file.fileId}/PROFILE_INFO?employeeCode=${this.downLoadFile}`,
        file.fileName
      );
    }
  }
}
