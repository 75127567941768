import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Injectable()
export class ToastrService {
  constructor(private snackbar: MatSnackBar) {}

  show(message: string, color: string, horizontal: string = 'right', vertical: string = 'top') {
    const config = {
      panelClass: `mat-${color}`,
      duration: 5000,
      horizontalPosition: <MatSnackBarHorizontalPosition>horizontal,
      verticalPosition: <MatSnackBarVerticalPosition>vertical,
    };
    this.snackbar.open(message, undefined, config);
  }

  success(message: string, horizontal: string = 'right', vertical: string = 'top') {
    const config = {
      panelClass: 'success',
      duration: 5000,
      horizontalPosition: <MatSnackBarHorizontalPosition>horizontal,
      verticalPosition: <MatSnackBarVerticalPosition>vertical,
    };
    this.snackbar.open(message, undefined, config);
  }

  error(message: string, horizontal: string = 'right', vertical: string = 'top') {
    const config = {
      panelClass: 'danger',
      duration: 5000,
      horizontalPosition: <MatSnackBarHorizontalPosition>horizontal,
      verticalPosition: <MatSnackBarVerticalPosition>vertical,
    };
    this.snackbar.open(message, undefined, config);
  }

  info(message: string, horizontal: string = 'right', vertical: string = 'top') {
    const config = {
      panelClass: 'secondary',
      duration: 5000,
      horizontalPosition: <MatSnackBarHorizontalPosition>horizontal,
      verticalPosition: <MatSnackBarVerticalPosition>vertical,
    };
    this.snackbar.open(message, undefined, config);
  }

  warning(message: string, horizontal: string = 'right', vertical: string = 'top') {
    const config = {
      panelClass: 'warn',
      duration: 5000,
      horizontalPosition: <MatSnackBarHorizontalPosition>horizontal,
      verticalPosition: <MatSnackBarVerticalPosition>vertical,
    };
    this.snackbar.open(message, undefined, config);
  }
}
