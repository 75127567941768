<div class="p-ng mc-fileUpload">
  <div [ngClass]="{ field: true, 'p-fluid': true }">
    <label>{{ showLabel ? label : '' }}<span class="p-error" *ngIf="checkRequired">*</span></label>
    <div>
      <button
        type="button"
        mat-icon-button
        class="w-300px"
        color="secondary"
        [disabled]="disabled"
        (click)="fileInput.click()"
      >
        <em class="las la-file-upload"></em>
        Chọn file tải lên
      </button>

      <input
        hidden
        [disabled]="disabled"
        [multiple]="multiple"
        [accept]="accept"
        (change)="onFileSelected($event, fileInput)"
        #fileInput
        type="file"
      />
    </div>
    <div [innerHTML]="note"></div>
    <div class="file-selected">
      <ul>
        <li *ngFor="let file of files; let i = index" class="item-file">
          <span *ngIf="!downLoadFile"
            >{{ getFileName(file) }}({{ file.fileSize / 1024 | number: '1.0-0' }}kb)<em
              (click)="deleteFile(i)"
              class="las la-trash-alt"
            ></em
          ></span>
          <span *ngIf="downLoadFile">
            <a *ngIf="file.fileId || file.ecmId; else noFileId" (click)="downloadFile(file)">
              {{ getFileName(file) }}({{ file.fileSize / 1024 | number: '1.0-0' }}kb)<em
                *ngIf="!hiddenDelete"
                (click)="deleteFile(i)"
                class="las la-trash-alt"
              ></em>
            </a>
            <ng-template #noFileId>
              <span>
                {{ getFileName(file) }}({{ file.fileSize / 1024 | number: '1.0-0' }}kb)<em
                  (click)="deleteFile(i)"
                  class="las la-trash-alt"
                ></em>
              </span>
            </ng-template>
          </span>
        </li>
      </ul>
    </div>
    <div class="relative b-errors">
      <ng-container *ngIf="errors">
        <small class="p-error block fadeinup animation-duration-300">
          <span class="block" *ngFor="let error of absControl?.errors | keyvalue">{{ getErrorMessage(error) }}</span>
        </small>
      </ng-container>
    </div>
  </div>
</div>
