<div class="p-ng">
  <div [ngClass]="{ 'p-input-filled': !border, field: true, 'p-fluid': true }">
    <label>{{ showLabel ? label : '' }}<span class="p-error" *ngIf="checkRequired">*</span></label>
    <p-treeSelect
      [options]="nodes"
      [formControl]="control"
      [appendTo]="appendTag"
      [baseZIndex]="10000"
      [scrollHeight]="scrollHeight"
      [placeholder]="placeholder"
      [disabled]="disabled"
      [selectionMode]="selectionMode"
      [emptyMessage]="emptyMessage"
      [display]="display"
      [filter]="filter"
      [showClear]="true"
      [propagateSelectionUp]="propagateSelectionUp"
      [propagateSelectionDown]="propagateSelectionDown"
      (onNodeSelect)="onChangeValue()"
      (onNodeUnselect)="onChangeValue()"
      (onHide)="onTouched()"
      (onClear)="onChangeValue()"
      (onFilter)="onFilterInput($event)"
      [panelStyleClass]="idTag"
      (onShow)="onDropdownShow()"
      id="{{ idTag }}"
    ></p-treeSelect>
    <div class="relative b-errors">
      <small *ngIf="errors && (absControl?.errors)!['required']" class="p-error block fadeinup animation-duration-300">
        <span class="block">'{{ label }}' là trường bắt buộc.</span>
      </small>
    </div>
  </div>
</div>
