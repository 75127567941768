<mat-form-field
  fxFlex
  [class.mat-form-field-with-icon]="icon"
  [class.mat-form-field-can-float]="true"
  [ngClass]="className"
>
  <mat-label>{{ showLabel ? (title ? title : placeholder) : '' }}</mat-label>
  <input
    type="{{ type }}"
    [attr.name]="name"
    matInput
    [placeholder]="placeholder"
    [(ngModel)]="value"
    [minlength]="minlength"
    [maxlength]="maxlength"
    [disabled]="disabled"
    (ngModelChange)="setValue($event)"
    (keydown)="keydown($event)"
    [required]="required"
    [readonly]="readonly"
    [ngModelOptions]="{ updateOn: 'blur' }"
    (blur)="blur()"
    [pattern]="pattern"
    autofocus
    focusOnError
    (keypress)="onlyNumberKeyPress($event)"
    (input)="onInputUser($event)"
  />
  <img *ngIf="icon" src="{{ iconUrl }}" class="icon" alt="" />
  <mat-error *ngIf="errors && errors.required"> '{{ title ? title : placeholder }}' là bắt buộc </mat-error>
  <mat-error *ngIf="errors && errors.minlength">
    '{{ title ? title : placeholder }}' không được ngắn hơn {{ minlength }} ký tự
  </mat-error>
  <mat-error *ngIf="errors && errors.maxlength">
    '{{ title ? title : placeholder }}' không được vượt quá {{ maxlength }} ký tự
  </mat-error>
  <mat-error *ngIf="errors && errors.pattern"> '{{ title ? title : placeholder }}' không đúng định dạng </mat-error>
  <mat-error *ngIf="errors && errors.minMoney && moneyType">
    '{{ title ? title : placeholder }}' phải lớn hơn 0.
  </mat-error>

  <mat-error *ngIf="errors && errors.demcimal && isDemacialNumber"
    >'{{ title ? title : placeholder }}' tối đa chỉ 1 chữ số thập phân.</mat-error
  >

  <mat-error *ngIf="errors && errors.year && isYear"
    >'{{ title ? title : placeholder }}' không đúng định dạng năm.</mat-error
  >
  <mat-error *ngIf="errors && errors.maxYear && isYear"
    >'{{ title ? title : placeholder }}' không được lớn hơn năm hiện tại.</mat-error
  >

  <mat-error *ngIf="errors && (errors.taxCode || errors.accountNumber) && (isTaxcode || isAccountNumber)">
    '{{ title ? title : placeholder }}' không đúng định dạng
  </mat-error>
</mat-form-field>
