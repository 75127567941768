import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageType } from '@cores/constants';
import { ModalService, ToastrService } from '@services';
import * as _ from 'lodash';

@Component({
  selector: 'app-show-message',
  templateUrl: './show-message.component.html',
  styleUrls: ['./show-message.component.scss'],
})
export class ShowMessageComponent {
  title: string;
  data: any;
  beforeConfirmation: boolean = false;
  conditionsChange: boolean = false;
  contentBefore: any;
  code: any;
  content: any;
  type: MessageType;
  constructor(
    private ref: MatDialogRef<ShowMessageComponent>,
    public toastr: ToastrService,
    public modal: ModalService,
    @Inject(MAT_DIALOG_DATA) bindings: any
  ) {
    this.title = _.get(bindings, 'data.title');
    this.data = _.get(bindings, 'data.data');
    this.content = _.get(bindings, 'data.content');
    this.type = _.get(bindings, 'data.type');
  }

  accept() {
    this.ref.close(true);
  }
  cancel() {
    this.ref.close(false);
  }
}
