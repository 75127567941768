import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum ValidateType {
  Checking,
  Valid,
  Invalid,
}

@Injectable({
  providedIn: 'root',
})
export class OnboardingValidateFormService {
  public objFormValidate = [
    {
      key: 'employee',
      value: true,
    },
    {
      key: 'info',
      value: true,
    },
    {
      key: 'certificate',
      value: true,
    },
    {
      key: 'degree',
      value: true,
    },
    {
      key: 'relative',
      value: true,
    },
    {
      key: 'workExperence',
      value: true,
    },
    {
      key: 'employment',
      value: true,
    },
  ];
  private validateFromStream = new Subject();
  currentFrom = this.validateFromStream.asObservable();

  checkForm(noEmployment?: string) {
    if (noEmployment) {
      this.objFormValidate.slice(0, 6).forEach((item) => {
        item.value = false;
      });
    } else {
      this.objFormValidate.forEach((item) => {
        item.value = false;
      });
    }
    this.validateFromStream.next(ValidateType.Checking);
  }

  updateValidate(key: string, value: boolean) {
    for (const element of this.objFormValidate) {
      if (element.key === key) {
        element.value = value;
        break;
      }
    }
    if (this.objFormValidate.filter((item) => item.value).length === this.objFormValidate.length) {
      this.validateFromStream.next(ValidateType.Valid);
    } else {
      this.validateFromStream.next(ValidateType.Invalid);
    }
  }
}
