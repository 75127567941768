import { Component, forwardRef, Input, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MCreditComponent } from '../mc.component';
import * as _ from 'lodash';
import { ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: '[mc-textarea]',
  templateUrl: './mc-textarea.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MCreditTextareaComponent),
      multi: true,
    },
  ],
})
export class MCreditTextareaComponent extends MCreditComponent<string> implements AfterViewInit {
  @ViewChild(MatInput) control: MatInput;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() rows: number;
  @Input() maxRows: number;
  @Input() minRows: number;
  @Input() resize: string;
  @Input() border: string;
  @Input() height: string = '20px';
  @Input() required: boolean;
  @Input() trim: boolean;
  @Input() minlength: number;
  @Input() maxlength: number;
  @Input() readonly: boolean;
  @Input() maxlines: number;
  @Input() title: string;
  @Input() isBlockEnter: boolean = false;

  @Input() matFormFieldClass: string;

  constructor(private ref: ChangeDetectorRef) {
    super();
  }

  ngAfterViewInit() {
    this.ref.detectChanges();
  }

  get errors() {
    return _.get(this.control, 'ngControl.errors');
  }

  blur() {
    this.value = this.normalize(this.value);
  }

  override normalize(value: string) {
    return this.trim !== null && this.trim !== undefined ? (value || '').trim() : value;
  }

  onKeyPress(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode === ENTER && this.isBlockEnter) {
      return false;
    }
    return true;
  }
}
