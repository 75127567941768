<div class="header-component">
  <div class="logo-container">
    <a href="#" class="logo" (click)="goToDashBoard()">
      <img src="assets/images/logo.png" alt="" />
    </a>
  </div>
  <div class="header-container">
    <div class="header-container">
      <div class="v-separator"></div>
      <div class="user">
        <span class="name">{{ fullName }}</span>
        <em class="las la-angle-down"></em>
        <div class="drop-down">
          <a class="item" (click)="logout()">Đăng xuất</a>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="bg-neutral">
  <div class="d-flex">
    <div class="fx-flex-50">
      <img src="./../../../../assets/images/system_fixed.png" alt="" />
      <div class="action-text pb-8">Thao tác đã được tạm ngưng</div>
      <div class="detail-text pb-4">Hệ thống đang trong quá trình chuyển đổi mô hình tổ chức</div>
      <div class="detail-text pb-8">Bạn hãy vui lòng quay lại sau!</div>

      <div>
        <button
          *ngIf="isCheckBtn"
          class="mr-8"
          mat-flat-button
          color="primary"
          (click)="viewErrorList()"
          matTooltip="Xem danh sách lỗi"
        >
          Xem danh sách lỗi
        </button>
      </div>
    </div>
  </div>
</div>
