import { Injectable } from '@angular/core';
import { StorageService } from '@services/storage.service';
import { Utils } from '@cores/utils';

const KEY_DRAWER = 'DRAWER_STATE';

@Injectable()
export class DrawerService {
  constructor(private storage: StorageService) {
    this._shown = storage.resolve(KEY_DRAWER);
    if (Utils.isNull(this._shown)) {
      this._shown = true;
    }
  }

  private _shown: boolean;

  get shown() {
    return this._shown;
  }

  toggle() {
    this._shown = this.storage.resolve(KEY_DRAWER, !this._shown);
  }
}
