import { ControlValueAccessor } from '@angular/forms';

export class MCreditComponent<T> implements ControlValueAccessor {
  value: T;
  protected onChange: (value: T) => void;
  protected onTouched: () => void;
  setValue(value: T) {
    this.onChange(value);
  }

  writeValue(value: T): void {
    this.value = value;
  }

  registerOnChange(fn: (value: T) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  normalize(value: T) {
    return value;
  }
}
