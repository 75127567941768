<div class="postion-relative">
  <mat-form-field fxFlex>
    <mat-label>{{ showLabel ? (title2 ? title2 : title) : '' }}</mat-label>
    <mat-select
      [attr.name]="name"
      [placeholder]="placeholder"
      [disabled]="disabled"
      [required]="required"
      (ngModelChange)="change($event)"
      [multiple]="multiple"
      [(ngModel)]="value"
      autofocus
      focusOnError
      (selectionChange)="onSelected()"
    >
      <mat-option *ngIf="search">
        <ngx-mat-select-search
          ngModel
          (ngModelChange)="filter($event)"
          [placeholderLabel]="textSearch"
          [noEntriesFoundLabel]="textEmpty"
        ></ngx-mat-select-search>
      </mat-option>

      <mat-option *ngIf="textNone | isStringNotEmpty">
        {{ textNone }}
      </mat-option>

      <mat-option
        [class]="color"
        *ngFor="let element of elements; trackBy: indentify"
        [disabled]="element.isDisabled"
        [value]="element[bindValue]"
      >
        {{ multipleContent ? element[bindPrefix] + '-' + element[bindName] : element[bindName] }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="prev-absolute">
    <p class="prev-data">
      {{ prevValue }}
    </p>
  </div>
</div>
