import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Utils } from './utils';
import * as _ from 'lodash';
import { environment } from '@env';
import { FileEcm } from './models/file-ecm.model';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpWrapper {
  constructor(public http: HttpClient, @Inject(String) public baseURL: string) {}

  fetch(page: number, quantity: number, params: any = {}) {
    return this.http
      .get(this.baseURL + `/${page}/${quantity}`, {
        params: params,
      })
      .toPromise();
  }

  single(code: string) {
    return this.http.post(this.baseURL + `/${code}`, {}).toPromise();
  }

  selectize() {
    return this.http.get(this.baseURL + `/selectize`).toPromise();
  }

  save(data: any) {
    return this.http.post(this.baseURL + `/save`, data).toPromise();
  }

  udpate(data: any) {
    return this.http.post(this.baseURL + `/update`, data).toPromise();
  }

  udpateData(data: any) {
    return this.http.post(this.baseURL, data).toPromise();
  }

  create(href: string, data: any) {
    let url = Utils.isStringNotEmpty(href) ? `/${href}` : '';
    return this.http.post(this.baseURL + `${url}`, data).toPromise();
  }

  edit(href: string, data: any, param: any = {}) {
    let url = Utils.isStringNotEmpty(href) ? `/${href}` : '';
    return this.http.put(this.baseURL + `${url}`, data, { params: param }).toPromise();
  }

  changeStatus(href: string, data: any, param: any = {}) {
    let url = Utils.isStringNotEmpty(href) ? `/${href}` : '';
    return this.http.post(this.baseURL + `${url}`, data, { params: param }).toPromise();
  }

  changeStatusPut(href: string, data: any, param: any = {}) {
    let url = Utils.isStringNotEmpty(href) ? `/${href}` : '';
    return this.http.put(this.baseURL + `${url}`, data, { params: param }).toPromise();
  }

  deleteData(code: string) {
    return this.http.delete(this.baseURL + `/${code}`).toPromise();
  }

  deleteParam(code: string, data: any) {
    return this.http.post(this.baseURL + `/delete/${code}`, data).toPromise();
  }

  deleteValue(code: string) {
    let url = Utils.isStringNotEmpty(code) ? `/${code}` : '';
    return this.http.delete(this.baseURL + `${url}`, {}).toPromise();
  }

  delete(code: string) {
    return this.http.post(this.baseURL + `/delete/${code}`, {}).toPromise();
  }

  published(code: string) {
    return this.http.post(this.baseURL + `/published/${code}`, {}).toPromise();
  }

  unpublished(code: string) {
    return this.http.post(this.baseURL + `/unpublished/${code}`, {}).toPromise();
  }

  upload(filter: any = {}, body: any = {}) {
    const headers = {
      ContentType: 'multipart/form-data',
      params: filter,
    };
    return this.http.post(this.baseURL + `/import`, body, headers).toPromise();
  }

  get(href: string, params: any = {}) {
    let url = Utils.isStringNotEmpty(href) ? `/${href}` : '';
    return this.http
      .get(this.baseURL + `${url}`, {
        params: params,
      })
      .toPromise();
  }

  getResponseTypeText(href: string, params: any = {}) {
    let url = Utils.isStringNotEmpty(href) ? `/${href}` : '';
    return this.http
      .get(this.baseURL + `${url}`, {
        responseType: 'text',
        params: params,
      })
      .toPromise();
  }

  getApiParams(params: any) {
    return this.http.get(this.baseURL + `/${params}`).toPromise();
  }

  post(href: string, data: any, params: any = {}) {
    let url = Utils.isStringNotEmpty(href) ? `/${href}` : '';
    return this.http
      .post(this.baseURL + `${url}`, data, {
        params: params,
      })
      .toPromise();
  }

  put(href: string, data: any, params: any = {}) {
    let url = Utils.isStringNotEmpty(href) ? `/${href}` : '';
    return this.http
      .put(this.baseURL + `${url}`, data, {
        params: params,
      })
      .toPromise();
  }

  export(href: string, options: any = {}) {
    let url = Utils.isStringNotEmpty(href) ? `/${href}` : '/export';
    return this.http.get(this.baseURL + `${url}`, options).toPromise();
  }

  postUrl(url: string, data: any) {
    return this.http.post(this.baseURL + url, data);
  }

  fetchData(href: string, page: number, quantity: number, params: any = {}) {
    let url = Utils.isStringNotEmpty(href) ? `/${href}` : '';
    _.set(params, 'page', page);
    _.set(params, 'size', quantity);
    return this.http.get(this.baseURL + `${url}`, { params: params }).toPromise();
  }
  dataSearch(href: string, page: number, quantity: number, params: any = {}) {
    let url = Utils.isStringNotEmpty(href) ? `/${href}` : '';
    _.set(params, 'page', page);
    _.set(params, 'size', quantity);
    return this.http.post(this.baseURL + `${url}`, params ).toPromise();
  }

  fetchListRegister(href: string, page: number, quantity: number, employeeCode: any, params: any = {}) {
    let url = Utils.isStringNotEmpty(href) ? `/${href}` : '';
    _.set(params, 'page', page);
    _.set(params, 'quantity', quantity);
    _.set(params, 'employeeCode', employeeCode);
    return this.http.get(this.baseURL + `${url}`, { params: params }).toPromise();
  }

  downloadFile(file: FileEcm): Observable<any> {
    return this.http
      .get(`${environment.base_url}/e-file-cabinet/ecm-document/download/${file.ecmId}`, {
        responseType: 'arraybuffer',
      })
      .pipe(
        map((res) => {
          saveAs(
            new Blob([res], {
              type: 'application/octet-stream',
            }),
            file.fileName
          );
          return true;
        })
      );
  }
  downloadFileMC(file: FileEcm, type: any, code: any): Observable<any> {
    return this.http
      .get(
        `${environment.base_url}/e-file-cabinet/ecm-document/download/${file.ecmId}${type}?employeeCode=${code}
      `,
        {
          responseType: 'arraybuffer',
        }
      )
      .pipe(
        map((res) => {
          saveAs(
            new Blob([res], {
              type: 'application/octet-stream',
            }),
            file.fileName
          );
          return true;
        })
      );
  }

  fetchDataTimesheet(href: string, params: any = {}) {
    let url = Utils.isStringNotEmpty(href) ? `/${href}` : '';
    return this.http.get(this.baseURL + `${url}`, { params: params }).toPromise();
  }

  fetchDataRegister(href: string, params: any = {}) {
    let url = Utils.isStringNotEmpty(href) ? `/${href}` : '';
    return this.http.get(this.baseURL + `${url}`, { params: params }).toPromise();
  }

  deleteWithParam(url: string, params: any) {
    return this.http
      .delete(this.baseURL + '/' + url, {
        params: params,
      })
      .toPromise();
  }

  postTypeText(href: string, data: any, params: any = {}) {
    let url = Utils.isStringNotEmpty(href) ? `/${href}` : '';
    return this.http
      .post(this.baseURL + `${url}`, data, {
        params: params,
        responseType: 'text',
      })
      .toPromise();
  }

  postExportFile(href: string, data: any, params: any = {}) {
    let url = Utils.isStringNotEmpty(href) ? `/${href}` : '';
    return this.http
      .post(this.baseURL + `${url}`, data, {
        params: params,
        responseType: 'arraybuffer',
      })
      .toPromise();
  }
}
