import {
  Component,
  forwardRef,
  ChangeDetectorRef,
  Input,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
  OnChanges,
  ElementRef,
} from '@angular/core';

import { NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { MCreditComponent } from '../mc.component';
import * as _ from 'lodash';
import { MatSelect } from '@angular/material/select';
import Fuse from 'fuse.js';

@Component({
  selector: '[mc-select-single]',
  templateUrl: './mc-select-single.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MCreditSelectSingleComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MCreditSelectSingleComponent),
      multi: true,
    },
  ],
})
export class MCreditSelectSingleComponent
  extends MCreditComponent<Array<any>>
  implements AfterViewInit, OnChanges, Validator
{
  @ViewChild(MatSelect) control: MatSelect;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() title: string;
  @Input() title2: string;
  @Input() color: string;

  @Input() textSearch: string;
  @Input() textEmpty: string;
  @Input() textNone: string;
  @Input() search: boolean = false;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() bindValue: string = 'code';
  @Input() bindName: string = 'name';
  @Input() bindPrefix: string = 'code';
  @Input() multiple: boolean = false;
  @Input() subData: boolean = false;
  @Input() multipleContent: boolean = false;
  @Input() showLabel: boolean = true;
  @Input() selectedMax: number = 0;
  @Output() itemSelected = new EventEmitter();

  private _models: Array<any>;
  @Input()
  get models(): Array<any> {
    return this._models;
  }
  set models(value: Array<any>) {
    this._models = value;
    this.fuse = new Fuse(this.models, {
      keys: ['name'],
    });
  }

  elements: Array<any>;

  private fuse: Fuse<any>;

  constructor(private ref: ChangeDetectorRef, private el: ElementRef) {
    super();
  }

  get errors() {
    return _.get(this.control, 'ngControl.errors');
  }

  ngAfterViewInit() {
    this.elements = this.models;
    this.fuse = new Fuse(this.models, {
      keys: ['name'],
    });
    this.filter();
    this.ref.detectChanges();
  }

  ngOnChanges() {
    this.elements = this.models;
  }

  filter($event: any = undefined) {
    if (_.isEmpty($event)) {
      this.elements = this.models;
      return;
    }
    let results = this.fuse.search($event);
    this.elements = results;
  }

  change($event: any) {
    this.setValue($event);
  }

  public indentify(index: any, _item: any) {
    return index;
  }

  onSelected() {
    if (!this.validate()) {
      this.itemSelected.emit(this.value);
    }
  }

  validate(): ValidationErrors | null {
    if (this.control) {
      const _value = this.control.value;
      if (_value && this.selectedMax > 0 && _value.length > this.selectedMax) {
        this.control?.ngControl?.control?.setErrors({ maxItem: true });
        return { maxItem: true };
      }
    }
    return null;
  }
}
