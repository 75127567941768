import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { StorageService } from '@services';

@Injectable()
export class TrueResolve implements Resolve<Object> {
  constructor(private router: Router, private storage: StorageService) {}
  resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    return true;
  }
}

@Injectable()
export class FalseResolve implements Resolve<Object> {
  constructor(private router: Router, private storage: StorageService) {}
  resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    return false;
  }
}
