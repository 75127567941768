<mat-card-content>
  <div class="filters mb-16 ml-16">
    <div class="filter-left">
      <button mat-flat-button color="secondary" matTooltip="Xuất excel" class="mr-10" (click)="exportImport()">
        <em class="las la-file-download"></em>Xuất dữ liệu
      </button>
    </div>
    <div class="filter-right">
      <button
        type="button"
        mat-flat-button
        color="primary"
        class="mr-10"
        matTooltip="Khôi phục MHTC cũ"
        (click)="restore()"
        [disabled]="!this.checkButton"
      >
        Khôi phục MHTC cũ
      </button>
      <button
        type="button"
        mat-flat-button
        color="primary"
        class="mr-10"
        matTooltip="Chạy lại DS lỗi"
        (click)="retry()"
        [disabled]="!this.checkButton"
      >
        Chạy lại DS lỗi
      </button>
    </div>
  </div>
  <div class="filters filters-advance">
    <div class="filter-left" fxFlex></div>
  </div>
  <div class="table-container ml-16 mr-16">
    <table aria-describedby="" mat-table [dataSource]="models" class="mt-8">
      <ng-container matColumnDef="id" sticky>
        <th scope="col" mat-header-cell *matHeaderCellDef class="table-header-content">STT</th>
        <td mat-cell *matCellDef="let element; let i = index" class="text-center">
          <span>{{ page > 0 ? page * quantity + i + 1 : i + 1 }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th scope="col" mat-header-cell *matHeaderCellDef class="table-header-content">Thao tác</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            size="28"
            matTooltip="Cập nhật"
            (click)="updateData(element)"
            [disabled]="!this.checkButton"
          >
            <em class="las la-pen"></em>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="employeeCode">
        <th scope="col" mat-header-cell *matHeaderCellDef class="table-header-content">Mã NV</th>
        <td mat-cell *matCellDef="let element">
          <span>{{ element?.employeeCode }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="errorMessage">
        <th scope="col" mat-header-cell *matHeaderCellDef class="table-header-content">Mô tả lỗi</th>
        <td mat-cell *matCellDef="let element">
          <span>{{ element?.errorMessage }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="startDate">
        <th scope="col" mat-header-cell *matHeaderCellDef class="table-header-content">Ngày giờ chạy</th>
        <td mat-cell *matCellDef="let element">
          <span>{{ element?.startDate | date: 'yyyy/MM/dd H:mm' }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="errorDate">
        <th scope="col" mat-header-cell *matHeaderCellDef class="table-header-content">Ngày giờ phát sinh lỗi</th>
        <td mat-cell *matCellDef="let element">
          <span>{{ element?.errorDate | date: 'yyyy/MM/dd H:mm' }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="updatedDate">
        <th scope="col" mat-header-cell *matHeaderCellDef class="table-header-content">Ngày giờ cập nhật</th>
        <td mat-cell *matCellDef="let element">
          <span>{{ element?.updatedDate | date: 'yyyy/MM/dd H:mm' }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="updatedBy">
        <th scope="col" mat-header-cell *matHeaderCellDef class="table-header-content">Người cập nhật</th>
        <td mat-cell *matCellDef="let element">
          <span>{{ element?.updatedBy }}</span>
        </td>
      </ng-container>
      <tr class="row-nodata" *matNoDataRow>
        <div class="row-nodata-content">
          <em>Không có dữ liệu</em>
        </div>
      </tr>
      <tr mat-header-row *matHeaderRowDef="cols; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: cols"></tr>
    </table>
  </div>
  <mat-paginator
    class="ml-16 mr-16"
    [length]="total"
    [pageSizeOptions]="[10, 20, 50, 100, 500, 1000]"
    showFirstLastButtons
    #paginator
  ></mat-paginator>
</mat-card-content>
