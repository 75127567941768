import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class LoadingService {
  public listener = new EventEmitter<string>();
  isLoading = false;
  start() {
    this.isLoading = true;
    this.listener.emit('start');
  }

  complete() {
    this.isLoading = false;
    this.listener.emit('complete');
  }
}
