import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { HttpWrapper } from '@cores/http-wrapper';
import { environment } from '@env';
import { Observable } from 'rxjs';
import { removeParamNullOrUndefined } from '@cores/functions';
import { PositionSearch } from '../models/position-list';

@Injectable()
export class JobApi extends HttpWrapper {
  constructor(http: HttpClient) {
    super(http, `${environment.base_url_organization}/job`);
  }

  getData(): Observable<any> {
    return this.http.get(`${this.baseURL}`);
  }

  search(params: PositionSearch): Observable<any> {
    return this.http.get(`${this.baseURL}/search`, { params: removeParamNullOrUndefined(params) });
  }

  changeStatusList(params: Array<number>): Observable<any> {
    return this.http.put(`${this.baseURL}/change-job-status `, params, { responseType: 'text' });
  }
}
