import { Component, forwardRef, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import * as _ from 'lodash';

export interface CheckboxItem {
  label?: string;
  value: any;
  disabled?: boolean;
  readonly?: boolean;
}

@Component({
  selector: 'mc-input-checkbox',
  templateUrl: './mc-input-checkbox.component.html',
  styleUrls: ['./mc-input-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => McInputCheckboxComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => McInputCheckboxComponent),
      multi: true,
    },
  ],
})
export class McInputCheckboxComponent implements ControlValueAccessor, Validator {
  @Input() placeholder: string;
  @Input() label: string;
  @Input() disabled: boolean = false;
  @Input() showLabel: boolean = true;
  @Input() option: CheckboxItem;
  @Input() options: CheckboxItem[];
  @Input() readonly: boolean = false;
  @Input() border: boolean = false;
  @Input() styleClass: string;
  @Input() colClass: string;
  @Output() changeValue = new EventEmitter();

  absControl: AbstractControl;
  value: any;

  onChange: (_: any) => void;
  onTouched: () => void;

  constructor(private el: ElementRef<HTMLElement>) {}

  get errors() {
    return (
      (this.el.nativeElement.closest('.ng-submitted') || this.absControl?.touched || this.absControl?.dirty) &&
      this.absControl?.errors
    );
  }

  get checkRequired() {
    return this.absControl?.hasValidator(Validators.required);
  }

  onChangeValue() {
    this.onChange(this.value);
    this.changeValue.emit(this.value);
    this.onTouched();
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.absControl = control;
    return null;
  }

  getDisabled(option: CheckboxItem): boolean {
    return this.disabled || option.disabled || false;
  }

  getReadonly(option: CheckboxItem) {
    return this.readonly || option.readonly || false;
  }

  isBoolean(option: CheckboxItem): boolean {
    return _.isBoolean(option.value);
  }
}
