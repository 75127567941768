import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { HttpWrapper } from '@cores/http-wrapper';
import { environment } from '@env';
import { Observable } from 'rxjs';
import { removeParamNullOrUndefined } from '@cores/functions';
import { WorkPlaceSearch } from '../models';

@Injectable()
export class WorkPlaceApi extends HttpWrapper {
  constructor(http: HttpClient) {
    super(http, `${environment.base_url_master_data}/work-location`);
  }
  getData(): Observable<any> {
    return this.http.get(`${this.baseURL} `);
  }
  search(params: WorkPlaceSearch): Observable<any> {
    return this.http.get(this.baseURL, { params: removeParamNullOrUndefined(params) });
  }
  updateData(data: any[]): Observable<any> {
    return this.http.put(this.baseURL, data);
  }
  changeStatusList(params: Array<number>, endDate: Date): Observable<any> {
    return this.http.put(`${this.baseURL}/transform?endDate=${endDate}`, params, {
      responseType: 'text',
    });
  }
}
