import { Routes } from '@angular/router';
import { ConfirmedDecreeComponent, LoginComponent, SystemMantainenaceComponent } from './components';
import { TokenGuard } from './guards/token.guard';
import { ConfirmedDecreeGuard } from './guards/confirmed-decree.guard';
import { ErrorListComponent } from './components/error-list/error-list.component';

export const routes: Routes = [
  // {
  //   path: 'login',
  //   component: LoginComponent,
  // },
  // {
  //   path: 'loginConfirmedDecree',
  //   component: ConfirmedDecreeComponent,
  // },
  {
    path: 'maintained',
    component: SystemMantainenaceComponent,
  },
  {
    path: 'error-list',
    component: ErrorListComponent,
  },
  {
    path: '',
    canActivate: [TokenGuard],
    loadChildren: () => import('@modules/main').then((x) => x.MainModule),
  },
];
