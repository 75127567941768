<mat-form-field fxFlex>
  <mat-label>{{ title }}</mat-label>
  <mat-select
    [attr.name]="name"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [required]="required"
    (ngModelChange)="change($event)"
    [(ngModel)]="value"
    multiple
    autofocus
  >
    <mat-option
      [class]="color"
      *ngFor="let element of elements; trackBy: indentify"
      [disabled]="element.isDisabled"
      [value]="element[bindValue]"
      (click)="onSelected(element)"
    >
      {{ multipleContent ? element[bindPrefix] + '-' + element[bindName] : element[bindName] }}
    </mat-option>
  </mat-select>

  <mat-error *ngIf="control && control.errorState"> '{{ placeholder ? placeholder : title }}' là bắt buộc </mat-error>
</mat-form-field>
