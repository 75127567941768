import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  unitName: string = '';
  getData() {
    const TITLE = localStorage.getItem('titleName');
    if (TITLE) {
      this.unitName = TITLE;
    }
    return this.unitName;
  }

  setData(title: string) {
    localStorage.setItem('titleName', title);
    this.unitName = title;
  }
}
