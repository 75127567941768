import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { DataTable } from './models/data-table.model';
import { FileEcm } from './models/file-ecm.model';
import { ConfigTable } from '@modules/main/modules/salary/calculation-salary-period/models/config-table.model';

export function cleanData(data: any) {
  Object.keys(data).forEach((key) => {
    if (_.isString(data[key])) {
      data[key] = _.trim(data[key]);
    } else if (_.isNull(data[key]) || _.isUndefined(data[key])) {
      delete data[key];
    } else if (_.isArray(data[key])) {
      const array = data[key];
      for (let index = 0; index < array?.length; index++) {
        if (_.isString(array[index])) {
          array[index] = _.trim(array[index]);
        } else if (_.isObject(array[index])) {
          cleanData(array[index]);
        }
      }
    } else if (_.isObject(data[key]) && !(data[key] instanceof File)) {
      cleanData(data[key]);
    }
  });
  return data;
}

export function cleanDataForm(form: FormGroup) {
  const data = form.getRawValue();
  cleanData(data);
  form.patchValue(data, { emitEvent: false });
  return data;
}

export function mapDataTable(data: any, params: any): DataTable {
  return {
    content: data?.data?.data?.content || [],
    currentPage: params?.page || 0,
    size: params?.size || 10,
    totalElements: data?.data?.data?.totalElements || 0,
    totalPages: data?.data?.data?.totalPages || 0,
    numberOfElements: data?.data?.data?.numberOfElements || 0,
    first: (params?.size || 0) * (params?.page || 0),
  };
}

export function removeParamNullOrUndefined(params: any) {
  const newParams: any = {};
  _.forEach(params, (value, key) => {
    if (
      _.isNumber(value) ||
      (!_.isNull(value) && !_.isUndefined(value) && !_.isEmpty(value)) ||
      typeof value === 'boolean'
    ) {
      newParams[key] = value;
    }
  });
  return newParams;
}

// lấy fromDate, toDate trong date range, index = 0 lấy fromDate, index = 1 lấy toDate
export function getFromOrToDateRange(arrDate: string[], index: number): string | null {
  return Array.isArray(arrDate) && arrDate[index] ? arrDate[index] : null;
}

export function convertFileToFileEcmDto(file: File): Observable<FileEcm> {
  return new Observable<FileEcm>((observe) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const result: string = <string>reader.result;
      observe.next({
        ecmId: null,
        fileContent: result?.substring(result.lastIndexOf(',') + 1),
        fileName: file.name,
        prop1: file.name,
        prop2: file.name,
        fileSize: file.size,
        fileExtension: file.name.substring(file.name.lastIndexOf('.')).toLowerCase(),
        minType: file.type,
        file: file,
        lastModified: file.lastModified,
      });
      observe.unsubscribe();
    };
  });
}

export function configHeaderTableSalary(data: any[], listRowHeader: ConfigTable[][]) {
  /**
   * Tìm bản ghi có level cao nhất và showOff = 1
   */
  const listRowLast = _.filter(data, (item: any) => item.header === 0 && item.level >= 0 && item.showOff === 1);
  const levelMax = _.last(_.orderBy(listRowLast, (item) => item.level))?.level;
  /**
   * Map dữ liệu về đúng model khởi tạo
   */
  const configTables: ConfigTable[] = _.map(data, (item) => {
    return {
      name: item.factorName,
      id: item.id,
      parentId: item.parentId,
      queue: item.queue,
      code: item.factorCode,
      codeNew: item.factorCode,
      level: levelMax,
      showData: item.header === 0,
      stickyLeft: false,
      stickyRight: false,
      type: item.format || 'TEXT',
      colSpan: 1,
      rowSpan: 1,
      width: !item.header ? item.width || 100 : null,
      visible: item.showOff === 1,
      hasParent: true,
      hasChildren: false,
    };
  });

  /**
   * Mapping lại config, vì có trường hợp config cấp trên không
   * hiển thị trên màn hình, nên pải gán lại parentId
   */
  mapHeaderSalary(configTables);

  /**
   * Chỉ lấy tập các config hiển thị trên màn hình và map lại level
   */
  const cols = _.orderBy(
    configTables?.filter((item) => item.visible),
    ['queueLength', 'queue']
  );

  /**
   * Lấy tập các row level, tư đó biết được bảng có mấy cấp header(rows.length)
   * rồi xử lý lấy các config theo từng row header từ và tính rowSpan
   *
   */
  getRowsHeaderByLevel(cols, listRowHeader);
}

export function mapHeaderSalary(data: ConfigTable[], item?: ConfigTable, parentId?: number): any {
  if (!item) {
    data.forEach((o) => {
      if (o.visible && o.showData) {
        mapHeaderSalary(data, o, o.parentId);
      }
    });
  } else {
    const itemParent = Number.isInteger(parentId) ? data?.find((i: any) => i.id === parentId) : undefined;
    item.hasParent = !!itemParent;
    item.queueLength = item.queue?.length;
    setParentHeaderSalary(data, item, itemParent);
  }
}

function getRowsHeaderByLevel(cols: any[], listRowHeader: ConfigTable[][]) {
  /**
   * Lấy tập các row level, tư đó biết được bảng có mấy cấp header(rows.length)
   * rồi xử lý lấy các config theo từng row header từ và tính rowSpan
   *
   */
  const rows = _.uniq(cols?.map((item) => item.level)).sort();
  rows.forEach((value, i) => {
    listRowHeader[i] = [];
    if (i === 0) {
      listRowHeader[0] = _.cloneDeep(cols).filter((item) => !item.hasParent);
    } else if (i === rows.length - 1) {
      const list = [..._.cloneDeep(listRowHeader.flat()), ..._.cloneDeep(cols)].filter((item) => item.showData);
      listRowHeader[i] = _.uniqBy(list, (item) => item.code);
    } else {
      listRowHeader[i - 1]?.forEach((item) => {
        const list = _.cloneDeep(cols).filter(
          (o) => o.parentId != undefined && item.id != undefined && o.parentId === item.id
        );
        listRowHeader[i] = [...listRowHeader[i], ...list];
      });
    }
    listRowHeader[i]?.forEach((item) => {
      if (i !== rows.length - 1) {
        item.codeNew = `${item.code}-${i + 1}`;
        item.rowSpan = setRowSpan(item, rows, i);
      } else {
        item.codeNew = `${item.code}`;
      }
    });
  });
  /**
   * Tính colspan từng config
   * Bắt đầu tính từ hàng header gần cuối
   *
   */
  for (let i = rows.length - 2; i >= 0; i--) {
    listRowHeader[i]?.forEach((item) => {
      const colspan = _.reduce(
        listRowHeader[i + 1]?.filter((o) => o.parentId != undefined && item.id != undefined && o.parentId === item.id),
        (sum, o) => {
          return sum + o.colSpan;
        },
        0
      );
      item.colSpan = colspan === 0 ? 1 : colspan;
    });
  }
  listRowHeader[rows.length - 1] = _.orderBy(
    listRowHeader[rows.length - 1],
    ['queueLength', 'queue']
  );
}

function setRowSpan(item: any, rows: any[], i: number) {
  return item.hasChildren ? 1 : rows.length - i;
}

function setParentHeaderSalary(data: ConfigTable[], item: ConfigTable, itemParent?: ConfigTable) {
  if (itemParent) {
    itemParent.queue = item.queue;
    itemParent.queueLength = item.queueLength;
    /**
     * Gán lại giá trị queue cho cha
     */
    itemParent.queue = item.queue;

    /**
     * nếu header cha hiển thị(visible = true)
     *   - gán lại parentId, vì có thể header cha trước đó không được hiển thị(visible = false),
     *   nên khi tìm được header cha hiện thị cần gán lại parentId
     */
    if (itemParent.visible) {
      itemParent.hasChildren = true;
      item.parentId = itemParent.id;
      if (itemParent.level > item.level - 1) {
        itemParent.level = item.level - 1;
      }
      mapHeaderSalary(data, itemParent, itemParent.parentId);
    } else {
      mapHeaderSalary(data, item, itemParent.parentId);
    }
  }
}
