<div class="p-ng">
  <div [ngClass]="{ 'p-input-filled': !border, field: true, 'p-fluid': true }">
    <label>{{ showLabel ? label : '' }}<span class="p-error" *ngIf="checkRequired">*</span></label>
    <ng-container *ngIf="option">
      <div class="field-radiobutton">
        <p-checkbox
          [(ngModel)]="value"
          (onChange)="onChangeValue()"
          [value]="option.value"
          [label]="option.label!"
          [disabled]="getDisabled(option)"
          [readonly]="getReadonly(option)"
          [binary]="isBoolean(option)"
        ></p-checkbox>
      </div>
    </ng-container>
    <ng-container *ngIf="options">
      <div class="grid grid-nogutter">
        <div *ngFor="let option of options" class="field-radiobutton" [class]="colClass">
          <p-checkbox
            [value]="option.value"
            [label]="option.label!"
            [disabled]="getDisabled(option)"
            [readonly]="getReadonly(option)"
            [(ngModel)]="value"
            (onChange)="onChangeValue()"
            [binary]="isBoolean(option)"
          ></p-checkbox>
        </div>
      </div>
    </ng-container>
    <div class="relative b-errors">
      <small *ngIf="errors && (absControl?.errors)!['required']" class="p-error block fadeinup animation-duration-300">
        <span class="block">'{{ label }}' là trường bắt buộc.</span>
      </small>
    </div>
  </div>
</div>
