// export const environment = {
//   production: true,
//   SEO: 'MCredit',
//   auth: {
//     issuer: 'https://aws-prod-int-api.mcredit.com.vn/authentication-service',
//     realm: 'internal',
//     client: 'hcm-web',
//     realmManagement: 'realm-management',
//   },
//   base_url: 'https://aws-prod-int-api.mcredit.com.vn/internalchannel/hcm',
//   graphql_url: 'https://hris-apis.brostech.xyz/graphql',
//   s3_url: 'prod-internal-channel-hcm-s3-stand',
//   firebaseConfig: {
//     apiKey: 'AIzaSyBGR_gjm6bXC0puyxUZQ1Ulgjr6spWNc1Q', // NOSONAR
//     authDomain: 'onehr-59a5c.firebaseapp.com',
//     projectId: 'onehr-59a5c',
//     storageBucket: 'onehr-59a5c.appspot.com',
//     messagingSenderId: '630228928015',
//     appId: '1:630228928015:web:92bd2d3a44b620bea8857a',
//     measurementId: 'G-VHYNKL4Q6D',
//   },
// };

export const environment = {
  // uat: true,
  production: true,
  SEO: 'MCredit',
  auth: {
    issuer: 'https://dev-keycloak21.lptech.vn',
    realm: 'mc-hcm',
    client: 'mc-hcm-web',
    realmManagement: 'realm-management',
  },
  base_url: 'https://dev-hcm-mc-api.lptech.vn/hcm',
  base_url_employee: 'https://dev-hcm-mc-api.lptech.vn/hcm/hris-employee',
  base_url_tax: 'https://dev-hcm-mc-api.lptech.vn/hcm/hris-tax',
  base_url_kpi: 'https://dev-hcm-mc-api.lptech.vn/hcm/hris-kpi',
  base_url_insurance: 'https://dev-hcm-mc-api.lptech.vn/hcm/hris-insurance',
  base_url_salary: 'https://dev-hcm-mc-api.lptech.vn/hcm/hris-salary',
  base_url_administration: 'https://dev-hcm-mc-api.lptech.vn/hcm/hris-administration',
  base_url_organization: 'https://dev-hcm-mc-api.lptech.vn/hcm/hris-organization',
  base_url_timesheet: 'https://dev-hcm-mc-api.lptech.vn/hcm/hris-timesheet',
  base_url_report: 'https://dev-hcm-mc-api.lptech.vn/hcm/hris-report',
  base_url_master_data: 'https://dev-hcm-mc-api.lptech.vn/hcm/hris-master-data',
  graphql_url: 'https://hris-apis.brostech.xyz/graphql',
  s3_url: 'uat-internal-channel-hcm-s3-stand',
  firebaseConfig: {
    apiKey: 'AIzaSyBGR_gjm6bXC0puyxUZQ1Ulgjr6spWNc1Q', // NOSONAR
    authDomain: 'onehr-59a5c.firebaseapp.com',
    projectId: 'onehr-59a5c',
    storageBucket: 'onehr-59a5c.appspot.com',
    messagingSenderId: '630228928015',
    appId: '1:630228928015:web:92bd2d3a44b620bea8857a',
    measurementId: 'G-VHYNKL4Q6D',
  },
};
