import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Utils } from '@cores/utils';
import { LoadingService, ModalService, SystemMaintainServiceService, ToastrService } from '@services';
import { UpdateErrorComponent } from './update-error/update-error.component';
import { TransferOfPersonnelService } from '@modules/main/modules/organizations/pages/transfer-of-personnel/services/transfer-of-personnel.service';
import { ScreenType } from '@cores/constants';
import { ErrorList } from './models/error-list.models';

@Component({
  selector: 'app-error-list',
  templateUrl: './error-list.component.html',
  styleUrls: ['./error-list.component.scss'],
})
export class ErrorListComponent implements OnInit {
  @ViewChild('paginator') paginator: MatPaginator;
  models: MatTableDataSource<any>;
  page: number = 0;
  quantity: number = 10;
  total: number;
  checkButton: boolean;
  cols = ['id', 'action', 'employeeCode', 'errorMessage', 'startDate', 'errorDate', 'updatedDate', 'updatedBy'];
  dialogComponent = UpdateErrorComponent;

  constructor(
    private systemMaintainService: SystemMaintainServiceService,
    private transferOfPersonnelService: TransferOfPersonnelService,
    private http: HttpClient,
    private loadingService: LoadingService,
    private toastr: ToastrService,
    private modal: ModalService
  ) {}
  stateData: ErrorList = {
    workingProcessConfig: [],
    title: [],
    status: [],
    position: [],
    units: [],
    chartList: [],
    maxExpiredTimeTypeDropList: [],
    jobDraft: [],
  };
  ngOnInit(): void {
    this.getErrorList();
  }

  ngAfterViewInit() {
    this.paginator.page.subscribe((page: PageEvent) => {
      this.page = page.pageIndex;
      this.quantity = page.pageSize;
      this.getErrorList();
    });
  }

  getErrorList() {
    this.loadingService.start();
    const param = {
      page: this.page,
      size: this.quantity,
    };
    this.systemMaintainService.getErrorList(param).subscribe({
      next: (res: any) => {
        this.models = res?.models;
        this.total = res?.count;
        this.checkButton = res?.checkButton;
        this.loadingService.complete();
      },
      error: (e: any) => {
        this.toastr.error(Utils.popError(e));
        this.loadingService.complete();
      },
    });
  }

  exportImport() {
    const fileName = 'Xuất dữ liệu danh sách lỗi.xlsx';
    this.loadingService.start();
    this.systemMaintainService.fileExportImport(fileName).subscribe({
      next: (res) => {
        this.toastr.success(`${fileName} thành công`);
        this.loadingService.complete();
      },
      error: (e: any) => {
        const message = Utils.popError(e);
        this.toastr.error(message);
        this.loadingService.complete();
      },
    });
  }

  restore() {
    this.loadingService.start();
    this.systemMaintainService.restore().subscribe({
      next: (res) => {
        this.checkProcess(res);
      },
      error: (e) => {
        this.toastr.error(Utils.popError(e));
        this.loadingService.complete();
      },
    });
  }
  checkProcess(params: any) {
    const fileId = params;
    this.loadingService.start();
    this.systemMaintainService.checkProcessManager(`${fileId}`).subscribe({
      next: (response: any) => {
        switch (response.status) {
          case 'PENDING':
            setTimeout(() => {
              this.checkProcess(params);
            }, 30000);
            break;
          case 'SUCCESS':
            this.toastr.success('Thao tác thành công');
            this.loadingService.complete();
            break;
          case 'FAIL':
            this.toastr.error(response.msgError);
            this.loadingService.complete();
            break;
          default:
            break;
        }
      },
      error: (e) => {
        this.toastr.error(Utils.popError(e));
      },
    });
  }

  retry() {
    this.loadingService.start();
    this.systemMaintainService.retry().subscribe({
      next: (res) => {
        this.toastr.success('Thao tác thành công');
        this.loadingService.complete();
        window.location.reload();
      },
      error: (e) => {
        this.toastr.error(Utils.popError(e));
        this.loadingService.complete();
      },
    });
  }

  mapStateData(data: any) {
    return {
      chartList: data?.chartList || [],
      jobDraft: data?.titleDTOS || [],
      workingProcessConfig: data?.workingProcessConfigDTOS || [],
      maxExpiredTimeTypeDropList: data?.maxExpiredTimeTypeDropList || [],
    };
  }
  /* Cập nhật danh sách lỗi */
  updateData(model: any) {
    this.loadingService.start();
    this.transferOfPersonnelService.getListError(model.id).subscribe({
      next: (res) => {
        this.loadingService.complete();
        this.stateData = this.mapStateData(res);
        const instance = this.modal.show(
          this.dialogComponent,
          {
            data: model,
            res: res,
            stateData: this.stateData,
            title: 'Cập nhật danh sách lỗi',
            message: {
              success: 'Cập nhật danh sách lỗi thành công',
              error: 'Cập nhật danh sách lỗi không thành công',
            },
            column: model.id,
            screenType: ScreenType.Update,
          },
          {
            width: '85%',
          }
        );
        instance.subscribe((result: any) => {
          if (result) {
            this.getErrorList();
          }
        });
      },
      error: (e) => {
        const message = Utils.popError(e);
        this.toastr.error(message);
        this.loadingService.complete();
      },
    });
  }
}
