import { KeycloakService } from 'keycloak-angular';
import { environment } from '@env';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { IModuleTranslationOptions, ModuleTranslateLoader } from '@larscom/ngx-translate-module-loader';

export function initializer(keycloak: KeycloakService): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        await keycloak.init({
          config: {
            url: _.get(environment, 'keycloak.issuer'),
            realm: _.get(environment, 'keycloak.realm'),
            clientId: _.get(environment, 'keycloak.client'),
          },
          loadUserProfileAtStartUp: true,
          initOptions: {
            onLoad: 'login-required',
            checkLoginIframe: false,
          },
          bearerPrefix: 'Bearer',
        });
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function ModuleHttpLoaderFactory(http: HttpClient) {
  const baseTranslateUrl = '/assets/i18n';
  const options: IModuleTranslationOptions = {
    modules: [
      { baseTranslateUrl },
      { baseTranslateUrl, moduleName: 'remuneration-evolution', namespace: 'remuneration-evolution' },
      { baseTranslateUrl, moduleName: 'bonus-base-evolution', namespace: 'bonus-base-evolution' },
      { baseTranslateUrl, moduleName: 'allowances-evolution', namespace: 'allowances-evolution' },
      { baseTranslateUrl, moduleName: 'salary-period', namespace: 'salary-period' },
      { baseTranslateUrl, moduleName: 'bonus-base', namespace: 'bonus-base' },
      { baseTranslateUrl, moduleName: 'assign-off-schedule', namespace: 'assign-off-schedule' },
      { baseTranslateUrl, moduleName: 'deductions', namespace: 'deductions' },
      { baseTranslateUrl, moduleName: 'remuneration', namespace: 'remuneration' },
      { baseTranslateUrl, moduleName: 'add-candicate', namespace: 'add-candicate' },
      { baseTranslateUrl, moduleName: 'register-off', namespace: 'register-off' },
      { baseTranslateUrl, moduleName: 'candidate-list', namespace: 'candidate-list' },
      { baseTranslateUrl, moduleName: 'information-browsing-list', namespace: 'information-browsing-list' },
      { baseTranslateUrl, moduleName: 'add-insurance-adjustment', namespace: 'add-insurance-adjustment' },
    ],
  };
  return new ModuleTranslateLoader(http, options);
}
