import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SelfServiceService {
  statusNext = new BehaviorSubject(0);
  status$ = this.statusNext.asObservable();

  nextStatus(value: number) {
    this.statusNext.next(value);
  }
}
