import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { HttpWrapper } from '@cores/http-wrapper';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class RoleAccountApi extends HttpWrapper {
  constructor(http: HttpClient) {
    super(http, `${environment.base_url_administration}/user-profile/me`);
  }

  getInfo() {
    return this.http.get(this.baseURL);
  }
}
