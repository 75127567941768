<div class="p-ng">
  <div [ngClass]="{ 'p-input-filled': !border, field: true, 'p-fluid': true }">
    <label>{{ showLabel ? label : '' }}<span class="p-error" *ngIf="checkRequired">*</span></label>
    <div>
      <p-inputNumber
        [ngClass]="{ 'error-checked ng-invalid ng-dirty': errors }"
        [formControl]="control"
        [useGrouping]="useGrouping"
        [mode]="mode"
        [format]="format"
        [prefix]="prefix"
        [suffix]="suffix"
        [currency]="currency"
        [currencyDisplay]="currencyDisplay"
        [minFractionDigits]="minFractionDigits"
        [maxFractionDigits]="maxFractionDigits"
        [styleClass]="styleClass"
        [inputStyleClass]="inputStyleClass"
        [maxlength]="maxlength"
        [readonly]="readonly"
        [placeholder]="placeholder"
        (onBlur)="eventBlur($event)"
        (onFocus)="eventFocus($event)"
        (keypress)="onlyNumberKeyPress($event)"
        (onInput)="onInputChange($event)"
        [locale]="locale"
        [ariaRequired]="checkAriaRequired()"
      ></p-inputNumber>
    </div>
    <div class="relative b-errors">
      <ng-container *ngIf="errors">
        <small class="p-error block fadeinup animation-duration-300">
          <span class="block" *ngFor="let error of absControl?.errors | keyvalue">{{ getErrorMessage(error) }}</span>
        </small>
      </ng-container>
    </div>
  </div>
</div>
