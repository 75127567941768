<app-loading
  class="cdk-overlay-backdrop cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing"
  *ngIf="!isLoaded"
></app-loading>

<div mat-dialog-title class="mb-12">
  <h2>Tìm kiếm nhân viên</h2>
  <mat-icon mat-dialog-close class="icon-close">close</mat-icon>
</div>
<mat-dialog-content class="mat-typography">
  <div class="filters-input" [formGroup]="filterForm">
    <mc-input-text
      fxFlex="25"
      formControlName="employeeCodeLike"
      label="Mã nhân viên"
      placeholder="Nhập mã nhân viên"
      class="col"
      [border]="true"
    ></mc-input-text>
    <mc-input-text
      formControlName="fullNameLike"
      fxFlex="25"
      label="Họ và tên"
      placeholder="Nhập họ và tên"
      class="col"
      [border]="true"
    ></mc-input-text>
    <mc-input-select
      fxFlex="25"
      class="col"
      [options]="listTitles"
      formControlName="titleCodes"
      optionLabel="name"
      optionValue="code"
      placeholder="Chọn chức danh"
      label="Chức danh"
      [filter]="true"
      [multiple]="true"
      [border]="true"
      #titleCodes
      [appendTag]="titleCodes"
    ></mc-input-select>
    <mc-input-tree-select
      fxFlex="25"
      class="col"
      [options]="branchesActive"
      formControlName="unitCodes"
      optionLabel="vietnameseName"
      optionValue="code"
      placeholder="Chọn đơn vị"
      label="Đơn vị"
      selectionMode="checkbox"
      [filter]="true"
      [border]="true"
      keyNode="code"
      [checkPosition]="true"
    >
    </mc-input-tree-select>
  </div>
  <div class="filters-input" [formGroup]="filterForm">
    <mc-input-select
      fxFlex="25"
      class="col"
      [options]="presentStatusDD"
      formControlName="presentStatusCode"
      optionLabel="name"
      optionValue="code"
      placeholder="Chọn trạng thái làm việc"
      label="Trạng thái làm việc"
      [filter]="true"
      [border]="true"
      #presentStatusCode
      [appendTag]="presentStatusCode"
    ></mc-input-select>
    <mc-input-select
      fxFlex="25"
      class="col"
      [options]="workStatusDD"
      formControlName="workStatusCodes"
      optionLabel="name"
      optionValue="code"
      placeholder="Chọn trạng thái làm việc nhóm 1"
      label="Trạng thái làm việc nhóm 1"
      [filter]="true"
      [multiple]="true"
      [border]="true"
      #workStatusCodes
      [appendTag]="workStatusCodes"
    ></mc-input-select>
    <mc-input-select
      fxFlex="25"
      class="col"
      [options]="outsourceStatusDD"
      formControlName="outsourceStatusCodes"
      optionLabel="name"
      optionValue="code"
      placeholder="Chọn trạng thái làm việc nhóm 2"
      label="Trạng thái làm việc nhóm 2"
      [filter]="true"
      [multiple]="true"
      [border]="true"
      #outsourceStatusCodes
      [appendTag]="outsourceStatusCodes"
    ></mc-input-select>
    <div fxFlex="25"></div>
  </div>
  <div class="filters mb-16">
    <div class="filter-left">
      <button mat-flat-button color="primary" style="margin-right: 8px" (click)="search()">
        <mat-icon>search</mat-icon>Tìm kiếm
      </button>
      <button mat-icon-button matTooltip="Làm mới" size="36" color="secondary" (click)="reload()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </div>

  <!-- table -->
  <form #f="ngForm" (ngSubmit)="addEmployee()">
    <div fxLayout="row" fxLayoutAlign="flex-start" class="table-container">
      <table aria-describedby="" mat-table [dataSource]="dataTable" class="mt-8">
        <!-- Chọn nhân viên -->
        <ng-container matColumnDef="checkbox" *ngIf="multiple">
          <th scope="col" mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? toggleAllRows() : null"
              [indeterminate]="isIndeterminate"
              [checked]="isChecked"
            ></mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row" class="text-center">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row.isChecked) : null; rowSelect(row)"
              [checked]="row.isChecked"
            >
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="checkbox" *ngIf="!multiple">
          <th scope="col" mat-header-cell *matHeaderCellDef>
            <mat-radio-group [ngModel]="radioEmployee" name="selectedEmployee"> </mat-radio-group>
          </th>
          <td mat-cell *matCellDef="let row" class="text-center">
            <mat-radio-button [value]="row" (change)="changeEmployee(row)"> </mat-radio-button>
          </td>
        </ng-container>
        <ng-container matColumnDef="id">
          <th scope="col" mat-header-cell *matHeaderCellDef class="table-header-content">STT</th>
          <td mat-cell *matCellDef="let element; let i = index" class="text-center">
            <span>{{ pageIndex > 0 ? pageIndex * pageSize + i + 1 : i + 1 }}</span>
          </td>
        </ng-container>
        <!-- Mã nhân sự -->
        <ng-container matColumnDef="code">
          <th scope="col" mat-header-cell *matHeaderCellDef class="table-header-content">Mã NV</th>
          <td mat-cell *matCellDef="let element">
            <span>{{ value(element, 'code') }}</span>
          </td>
        </ng-container>
        <!-- Mã nhân sự cũ -->
        <ng-container matColumnDef="oldCode">
          <th scope="col" mat-header-cell *matHeaderCellDef class="table-header-content">Mã cũ</th>
          <td mat-cell *matCellDef="let element">
            <span>{{ value(element, 'oldCode') }}</span>
          </td>
        </ng-container>
        <!-- Họ -->
        <ng-container matColumnDef="lastName">
          <th scope="col" mat-header-cell *matHeaderCellDef class="table-header-content">Họ</th>
          <td mat-cell *matCellDef="let element">
            <span>{{ value(element, 'lastName') }}</span>
          </td>
        </ng-container>
        <!-- Tên -->
        <ng-container matColumnDef="firstName">
          <th scope="col" mat-header-cell *matHeaderCellDef class="table-header-content">Tên</th>
          <td mat-cell *matCellDef="let element">
            <span>{{ value(element, 'firstName') }}</span>
          </td>
        </ng-container>
        <!-- Đơn vị cấp -->
        <ng-container *ngFor="let item of treeLevels; let i = index" matColumnDef="{{ item.code }}">
          <th scope="col" mat-header-cell *matHeaderCellDef class="table-header-content">Đơn vị cấp {{ i + 1 }}</th>
          <td mat-cell *matCellDef="let element">{{ getUnit(element, item.value) }}</td>
        </ng-container>
        <!-- Chức danh -->
        <ng-container matColumnDef="titleName">
          <th scope="col" mat-header-cell *matHeaderCellDef class="table-header-content">Chức danh</th>
          <td mat-cell *matCellDef="let element">
            <span>{{ value(element, 'titleName') }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="presentStatusName">
          <th scope="col" mat-header-cell *matHeaderCellDef class="table-header-content">Trạng thái làm việc</th>
          <td mat-cell *matCellDef="let element">
            <span>{{ value(element, 'presentStatusName') }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="workStatusName">
          <th scope="col" mat-header-cell *matHeaderCellDef class="table-header-content">Trạng thái nhóm 1</th>
          <td mat-cell *matCellDef="let element">
            <span>{{ value(element, 'workStatusName') }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="outsourceStatusName">
          <th scope="col" mat-header-cell *matHeaderCellDef class="table-header-content">Trạng thái nhóm 2</th>
          <td mat-cell *matCellDef="let element">
            <span>{{ value(element, 'outsourceStatusName') }}</span>
          </td>
        </ng-container>

        <!-- Ngày vào -->
        <ng-container matColumnDef="startDate">
          <th scope="col" mat-header-cell *matHeaderCellDef class="table-header-content">Ngày vào</th>
          <td mat-cell *matCellDef="let element">
            <span>{{ value(element, 'startDate') | date: 'dd/MM/yyyy' }}</span>
          </td>
        </ng-container>
        <tr class="row-nodata" *matNoDataRow>
          <div class="row-nodata-content">
            <em>Không có dữ liệu</em>
          </div>
        </tr>
        <tr mat-header-row *matHeaderRowDef="cols; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: cols"></tr>
      </table>
    </div>
  </form>

  <!-- end table -->

  <mat-paginator
    [length]="pageTotal"
    [showFirstLastButtons]="true"
    (page)="changePage($event)"
    [pageSizeOptions]="[10, 20, 50, 100, 500, 1000]"
  ></mat-paginator>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end center" class="mt-12">
  <button mat-flat-button mat-dialog-close class="w-120px" (click)="closePopup()">Đóng</button>
  <button mat-flat-button color="primary" class="w-120px" (click)="addEmployee()">Chọn</button>
</mat-dialog-actions>
