import { Component, forwardRef, ChangeDetectorRef, Input, ViewChild, AfterViewInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MCreditComponent } from '../mc.component';
import * as _ from 'lodash';
import { formatNumber } from '@angular/common';

@Component({
  selector: '[mc-number]',
  templateUrl: './mc-number.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MCreditNumberComponent),
      multi: true,
    },
  ],
})
export class MCreditNumberComponent extends MCreditComponent<any> implements AfterViewInit {
  @ViewChild(MatInput) control: MatInput;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() negative: boolean = true;
  @Input() separator: number = 0;
  @Input() min: number;
  @Input() max: number;
  @Input() unit: string;
  @Input() className: string;
  @Input() type: string = 'number';
  @Input() readonly: boolean = false;
  @Input() useGrouping: boolean = false;
  @Input() minFractionDigits: number | null;
  @Input() maxFractionDigits: number | null;
  @Input() minIntegerDigits: number = 0;
  @Input() maxlength: number;
  @Input() title: string;

  constructor(private ref: ChangeDetectorRef) {
    super();
  }

  override setValue(value: any) {
    if (this.onChange) {
      this.onChange(isNaN(Number(value)) ? 0 : Number(value));
    }
  }
  setValueInput(event: any) {
    let value = event.target.value;
    if (this.maxlength && value.length >= this.maxlength) {
      value = value.substr(0, this.maxlength);
    }

    value = value.replace(/\D+/g, '');

    if (this.min && parseFloat(value) < this.min) {
      this.control.ngControl.control?.setErrors({ min: true });
      this.control._parentForm?.form?.get(this.name)?.setErrors({ min: true });
    }
    if (this.max && parseFloat(value) > this.max) {
      this.control.ngControl.control?.setErrors({ max: true });
      this.control._parentForm?.form?.get(this.name)?.setErrors({ max: true });
    }

    value = this.useGrouping
      ? formatNumber(
          value,
          'en',
          `${this.minIntegerDigits}.${this.minFractionDigits || 0}-${this.maxFractionDigits || 0}`
        )
      : value;

    event.target.value = value;
    this.value = value;
  }

  get pattern() {
    return `separator.${Math.max(this.separator, 0)}`;
  }

  get errors() {
    return _.get(this.control, 'ngControl.errors');
  }

  ngAfterViewInit() {
    this.ref.detectChanges();
  }

  blur() {
    this.value = this.normalize(this.value);
  }
}
