import {
  Component,
  forwardRef,
  ChangeDetectorRef,
  Input,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MCreditComponent } from '../mc.component';

import * as _ from 'lodash';
import { Utils } from '@cores/utils';

@Component({
  selector: '[mc-email]',
  templateUrl: './mc-email.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MCreditEmailComponent),
      multi: true,
    },
  ],
})
export class MCreditEmailComponent extends MCreditComponent<string> implements AfterViewInit {
  @ViewChild(MatInput) control: MatInput;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() trim: boolean;
  @Input() title: string;
  @Input() isCheckPattern: boolean = false;

  constructor(private ref: ChangeDetectorRef) {
    super();
  }

  get errors() {
    return _.get(this.control, 'ngControl.errors');
  }

  ngAfterViewInit() {
    this.ref.detectChanges();
  }

  blur() {
    this.value = this.normalize(this.value).toLocaleLowerCase();
  }

  override normalize(value: string) {
    return this.trim !== null && this.trim !== undefined ? (value || '').trim() : value || '';
  }

  override setValue(value: string) {
    value = value.trim();
    this.onChange(value);
    if (Utils.isStringEmpty(value) && this.required) {
      this.control.ngControl.control?.setErrors({ required: true });
      this.control._parentForm?.form?.get(this.name)?.setErrors({ required: true });
    } else if (
      !Utils.isStringEmpty(value) &&
      this.isCheckPattern &&
      !value.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
    ) {
      this.control.ngControl.control?.setErrors({ pattern: true });
      this.control._parentForm?.form?.get(this.name)?.setErrors({ pattern: true });
    }
    if (!Utils.isStringEmpty(value) && value.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)) {
      this.control.ngControl.control?.setErrors(null);
      this.control._parentForm?.form?.get(this.name)?.setErrors(null);
    }
  }
}
