import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { HttpWrapper } from '@cores/http-wrapper';
import { environment } from '@env';

@Injectable()
export class DistrictApi extends HttpWrapper {
  constructor(http: HttpClient) {
    super(http, `${environment.base_url_master_data}/common-category/districts`);
  }
  getByParent(code: string) {
    return this.http.get(`${this.baseURL}/${code}`).toPromise();
  }
}
