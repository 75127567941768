import {
  Component,
  forwardRef,
  ChangeDetectorRef,
  Input,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MCreditComponent } from '../mc.component';

import moment from 'moment';
import _ from 'lodash';

const DISPLAY_FORMAT: string = 'DD/MM/YYYY';
const DATE_VALUE: string = 'YYYY-MM-DD';

@Component({
  selector: '[mc-date]',
  templateUrl: './mc-date.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MCreditDateComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MCreditDateComponent),
      multi: true,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: DISPLAY_FORMAT,
        },
        display: {
          dateInput: DISPLAY_FORMAT,
          monthYearLabel: 'MMMM Y',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM Y',
        },
      },
    },
  ],
})
export class MCreditDateComponent extends MCreditComponent<any> implements AfterViewInit, Validator {
  @ViewChild(MatInput) control: MatInput;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() required: boolean | string;
  @Input() readonly: boolean;
  @Input() title: string;
  @Input() minDate?: Date;
  @Input() maxDate?: Date;
  @Input() dateFormat: string = 'YYYY-MM-DD';
  @Input() showLabel: boolean = true;
  @Input() isShowIconDate: boolean = true;
  @Input() isBirthDay: boolean | string; // Cho phép trường nhập là ngày sinh để required Ngày sinh
  @Input() messageErrorDay: string; // Tự custom message thông báo tên field lỗi
  @Input() fieldSame: string = '';
  @Input() sameValue: any;
  @Output() onblur = new EventEmitter<any>(); // thuộc tính phát ra value khi sự kiện chuột rời khỏi date
  @Input() listHoliday: any = [];
  @Input() isSunday = false;

  private currentValue: any;
  private inputHasValue: boolean;
  @ViewChild('date') elInput: any;

  constructor(private ref: ChangeDetectorRef) {
    super();
  }

  override setValue(value: any) {
    this.currentValue = value;
    this.onChange(value !== null && value.isValid() ? value.format(DATE_VALUE) : null); // NOSONAR
  }
  get errors() {
    return _.get(this.control, 'ngControl.errors');
  }

  override writeValue(value: string): void {
    value = value || '';
    this.value = (/(\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/).exec(value) ? new Date(value) : undefined;
    if (this.onChange) {
      this.onChange(this.value !== undefined ? moment(value).format(DATE_VALUE) : null);
    }
  }

  validate(control: AbstractControl) {
    const val = _.isString(control.value) ? new Date(control.value) : control.value?.toDate();

    if (_.isDate(val)) {
      if (this.minDate && moment(val).isBefore(moment(this.minDate).startOf('day'))) {
        return { matDatepickerMin: true };
      }

      this.validateBirthday(val);

      if (this.maxDate && moment(this.maxDate).endOf('day').isBefore(moment(val))) {
        return { matDatepickerMax: true };
      }

      this.checkHoliday(val);

      if (val?.getDay() === 0 && this.isSunday) {
        this.control?.ngControl?.control?.setErrors({ errSunday: true });
        return { errSunday: true };
      }

      this.checkSameDay(val);
    }

    if (!this.control?.ngControl?.control?.getError('required')) {
      this.control?.ngControl?.control?.setErrors(null);
    }

    return null;
  }

  validateBirthday(val: any) {
    if (this.maxDate && this.isBirthDay && moment(this.maxDate).endOf('day').isBefore(moment(val))) {
      this.control?.ngControl?.control?.setErrors({ maxBirthDayMin: true });
      return { maxBirthDayMin: true };
    }
    return null;
  }

  checkHoliday(val: any) {
    if (
      (val?.getDay() === 0 && this.listHoliday.length > 0) ||
      (val?.getDay() === 6 && this.listHoliday.length > 0) ||
      (this.listHoliday.includes(moment(val).format('YYYY-MM-DD')) && this.listHoliday && this.listHoliday.length > 0)
    ) {
      this.control?.ngControl?.control?.setErrors({ datePickerHoliday: true });
      return { datePickerHoliday: true };
    }
    return null;
  }

  checkSameDay(val: any) {
    const currentVal = _.isDate(moment(this.currentValue))
      ? moment(this.value, DISPLAY_FORMAT).format(DISPLAY_FORMAT)
      : undefined;
    if (this.sameValue === currentVal && this.sameValue && currentVal) {
      this.control?.ngControl.control?.setErrors({ sameDay: true });
      return { sameDay: true };
    }
    return null;
  }

  onBlur(event: any) {
    //  NOSONAR
    const inValid =
      event.target.value.length > 0 && !event.target.value.match(/((0[1-9]|[12]\d|3[01])[/](0[1-9]|1[0-2])[/]\d{4})/); // NOSONAR
    if (!this.control?.ngControl?.errors) {
      this.control?.ngControl?.control?.setErrors(inValid ? { pattern: true } : null);
      this.control?._parentForm?.form?.get(this.name)?.setErrors(inValid ? { pattern: true } : null);
    }
  }

  ngAfterViewInit() {
    this.ref.detectChanges();
  }

  get errorMatDatePicker() {
    return this.control?.ngControl?.control?.hasError('matDatepickerParse');
  }
  /**
   * chặn nhập chữ ký tự đặc biệt
   *
   * @param event
   * @returns
   */
  onlyNumberKeyPress(event: any) {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode > 31 && (charCode < 47 || charCode > 57)) {
      return false;
    }
    this.inputHasValue = true;
    return true;
  }

  handleCalculateyear(): any {
    if (this.control.ngControl.control?.hasError('maxBirthDayMin')) {
      // @ts-ignore
      return new Date().getFullYear() - this.maxDate?.getFullYear();
    }
  }

  timehandling(): any {
    let label = '';
    if (this.control?.ngControl?.hasError('maxBirthDayMin')) {
      if (this.messageErrorDay) {
        label = this.messageErrorDay;
      } else {
        label = this.title ? this.title : this.placeholder;
      }
    } else {
      if (this.title) {
        label = this.title;
      } else {
        label = this.placeholder ? this.placeholder : this.messageErrorDay;
      }
    }
    return label;
  }

  handleChangeDate(event: any) {
    this.onblur.emit(event.target.value?.format('YYYY-MM-DD') || null);
  }

  myFilter = (value?: Date | null) => {
    const day = moment(value).day();
    if (this.listHoliday && this.listHoliday.length > 0) {
      const dateString = moment(value).format('YYYY-MM-DD');
      const isHolday = this.listHoliday.includes(dateString);
      return day !== 0 && day !== 6 && !isHolday;
    }
    if (this.isSunday) {
      return day !== 0;
    }
    return true;
  };
}
