<mat-form-field fxFlex [attr.class]="matFormFieldClass">
  <mat-label>{{ title }}</mat-label>
  <textarea
    cdkTextareaAutosize
    [attr.name]="name"
    [minlength]="minlength"
    [attr.maxlength]="maxlength"
    [attr.maxlines]="maxlines"
    [attr.minRows]="minRows"
    [attr.maxRows]="maxRows"
    matInput
    [placeholder]="placeholder"
    [(ngModel)]="value"
    [disabled]="disabled"
    [readonly]="readonly"
    (ngModelChange)="setValue($event)"
    [rows]="rows"
    [style.resize]="resize"
    [style.border]="border"
    [style.height]="height"
    [required]="required"
    (blur)="blur()"
    (keypress)="onKeyPress($event)"
    autofocus
  ></textarea>
  <mat-error *ngIf="errors && errors.required"> '{{ title ? title : placeholder }}' là bắt buộc </mat-error>
  <mat-error *ngIf="errors && errors.minlength">
    '{{ placeholder }}' không được ngắn hơn {{ minlength }} ký tự
  </mat-error>

  <mat-error *ngIf="errors && errors.maxlength">
    '{{ placeholder }}' không được ngắn hơn {{ maxlength }} ký tự
  </mat-error>
  <mat-error *ngIf="errors && errors.maxRows">
    Số dòng '{{ placeholder }}' không được lớn hơn {{ maxRows }} ký tự
  </mat-error>
</mat-form-field>
