import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MATERIAL_MODULES, MATERIAL_PROVIDERS } from './material.module';
import { COMPONENTS } from './components';
import { PIPES } from './pipes';
import { DIALOGS } from './dialogs';
import { NgxSummernoteModule } from '@plugins/ngx-summernote';
import { MatTreeSelectInputModule } from 'mat-tree-select-input';
import { RESOLVERS } from './resolvers';
import { NgxMaskModule } from 'ngx-mask';
import { PRIMENG_MODULES } from './primeng.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSummernoteModule.forChild(),
    MatTreeSelectInputModule,
    ...MATERIAL_MODULES,
    ...PRIMENG_MODULES,
    NgxMaskModule.forChild(),
    NgxMatSelectSearchModule,
  ],
  exports: [CommonModule, ...MATERIAL_MODULES, ...COMPONENTS, ...PIPES, ...DIALOGS, ...PRIMENG_MODULES],
  providers: [...MATERIAL_PROVIDERS, ...RESOLVERS],
  declarations: [...COMPONENTS, ...PIPES, ...DIALOGS],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
